import { useState } from "react";
import Translate from "../../../utils/Translate";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect } from "react";
import Preloader from "../../Preloader";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DownloadIcon from "@mui/icons-material/Download";
import download from "downloadjs";
import Api, { useApi } from "../../../utils/Api";
import { formatDate } from "../../../utils/dateTimeFormat";
import GroupEmailDialog from "../../emails/GroupEmailDialog";
import { getOrderConfirmationEmailDialogProperties } from "../courseEmailsHelper";
import { useSnackbar } from "notistack";

export default function ChangeOrderConfirmationDetails({ orderId, startDate, loadCourse }) {
  useEffect(() => {
    window.analyticsEvent("eport_xtras", "eport_order_confirmation");
  }, []);
  const api = useApi();

  const [order, setOrder] = useState("");

  const [paymentAgreements, setPaymentAgreements] = useState([]);

  const [supplierRefName, setSupplierRefName] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [actorRefName, setActorRefName] = useState("");
  const [actorRefId, setActorRefId] = useState("");
  const [actorRefEmail, setActorRefEmail] = useState("");
  const [alterOrderNo, setAlterOrderNo] = useState("");
  const [customerOrderNo, setCustomerOrderNo] = useState("");
  const [paymentAgreementDescription, setPaymentAgreementDescription] =
    useState("");
  const [paymentAgreement, setPaymentAgreement] = useState(null);
  const [orderLineOriginalPcsPrice, setOrderLineOriginalPcsPrice] =
    useState("");
  const [orderLineDiscountedPcsPrice, setOrderLineDiscountedPcsPrice] =
    useState("");
  const [orderLineDiscount, setOrderLineDiscount] = useState("");
  const [orderLineIsFixedTotalPrice, setOrderLineIsFixedTotalPrice] =
    useState(false);
  const [orderLinePrice, setOrderLinePrice] = useState("");
  const [orderLineQty, setOrderLineQty] = useState("");
  const [extraNotification, setExtraNotification] = useState("");
  const [showSendEmailDialog, setShowSendEmailDialog] = useState(false);

  const [
    showSaveDiscountOnCustomerDialog,
    setShowSaveDiscountOnCustomerDialog,
  ] = useState(false);
  const [isFixedPriceDiscount, setIsFixedPriceDiscount] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(async () => {
    setIsLoading(true);
    let [orderData, paymentAgreementsData] = await Promise.all([
      loadOrder(orderId),
      loadPaymentAgreements(),
    ]);
    setOrderData(orderData);
    setPaymentAgreementData(
      paymentAgreementsData,
      orderData.paymentAgreementDescription,
      orderData.paymentAgreement
    );
    setIsLoading(false);
  }, []);

  async function loadOrder(orderId) {
    return api.fetch(`${process.env.REACT_APP_MAIN_URL}orders/${orderId}`);
  }

  function setOrderData(data) {
    if (!data.isSuccessful) {
      return;
    }

    setSupplierRefName(data.supplierRefName);
    setOrderNo(data.orderNo);
    setActorRefName(data.actorRefName);
    setActorRefId(data.actorRefId);
    setActorRefEmail(data.actorRefEmail);
    setAlterOrderNo(data.alterOrderNo);
    setCustomerOrderNo(data.customerOrderNo);
    setPaymentAgreementDescription(data.paymentAgreementDescription);
    setPaymentAgreement(data.paymentAgreement);

    if (data.orderLines.length > 0) {
      let orderLine = data.orderLines[0];
      setOrderLineOriginalPcsPrice(orderLine.originalPcsPrice);
      setOrderLineDiscountedPcsPrice(orderLine.discountedPcsPrice);
      if (orderLine.discount !== null) {
        setOrderLineDiscount(orderLine.discount * 100);
      }
      setOrderLineIsFixedTotalPrice(orderLine.isFixedTotalPrice);
      setOrderLinePrice(orderLine.price);
      setOrderLineQty(orderLine.qty);
    }
    setExtraNotification(data.extraNotification);

    setOrder(data);
  }

  async function loadPaymentAgreements() {
    return await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}orders/paymentagreements`
    );
  }

  function setPaymentAgreementData(
    data,
    orderConfirmationsPaymentDescription,
    orderConfirmationsPaymentAgreementId
  ) {
    if (!data.isSuccessful) {
      return;
    }

    let paymentAgreements = data.paymentAgreements.map((d) => ({
      ePortCodeId: d.ePortCodeId,
      description: d.description,
    }));

    var index = paymentAgreements.findIndex(
      (x) => x.ePortCodeId === orderConfirmationsPaymentAgreementId
    );
    if (index === -1) {
      paymentAgreements.push({
        ePortCodeId: orderConfirmationsPaymentAgreementId,
        description: orderConfirmationsPaymentDescription,
      });
    }

    setPaymentAgreements(paymentAgreements);
  }

  function close() {
    window.history.back();
  }

  function handlePcsPriceChange(event) {
    const newPcsPrice = event.target.value;
    const newPrice = orderLineQty * newPcsPrice;
    const newDiscount =
      orderLineOriginalPcsPrice !== 0
        ? Number((1 - newPcsPrice / orderLineOriginalPcsPrice).toFixed(19))
        : 0;

    setOrderLineDiscountedPcsPrice(newPcsPrice);
    setOrderLineDiscount(newDiscount * 100);
    setOrderLinePrice(newPrice);
  }

  function handleDiscountChange(event) {
    const newDiscount = event.target.value;
    if (newDiscount < 0 || newDiscount > 100) {
      return;
    }

    const newPcsPrice = Number(
      (orderLineOriginalPcsPrice * (1 - newDiscount / 100)).toFixed(6)
    );
    const newPrice = newPcsPrice * orderLineQty;
    setOrderLineDiscount(newDiscount);
    setOrderLineDiscountedPcsPrice(newPcsPrice);
    setOrderLinePrice(newPrice);
  }

  function handleTotalPriceChange(event) {
    const newPrice = event.target.value;
    setOrderLinePrice(newPrice);

    if (orderLineIsFixedTotalPrice) {
      const newPcsPrice = Number((newPrice / orderLineQty).toFixed(6));
      setOrderLineDiscountedPcsPrice(newPcsPrice);
    }
  }

  async function resetOriginalPrice() {
    setIsLoading(true);
    const request = {
      partId: order.orderLines[0].partId,
      actorId: order.actorId,
      qty: orderLineQty,
    };
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}warehouse/parts/get-price`,
      request,
      "POST"
    );
    if (response.isSuccessful) {
      setOrderLineOriginalPcsPrice(response.originalPrice);
      setOrderLineDiscountedPcsPrice(response.price);
      setOrderLineDiscount(response.discount * 100);
      setOrderLinePrice(response.price * orderLineQty);

      const orderLine = order.orderLines[0];
      orderLine.originalPcsPrice = response.originalPrice;
      orderLine.discountedPcsPrice = response.price;
      orderLine.discount = response.discount;
      orderLine.price = response.price * orderLineQty;
      setOrder({ ...order, orderLines: [orderLine] });
    }
    setIsLoading(false);
  }

  async function handleIsFixedPriceChange() {
    let newIsFixedTotalPrice = !orderLineIsFixedTotalPrice;
    setOrderLineIsFixedTotalPrice(newIsFixedTotalPrice);

    if (newIsFixedTotalPrice) {
      setOrderLineDiscount(0);
      const newPcsPrice = Number((orderLinePrice / orderLineQty).toFixed(6));
      setOrderLineDiscountedPcsPrice(newPcsPrice);
    } else {
      await resetOriginalPrice();
    }
  }

  async function saveFixedPriceDialog() {
    let o = order;
    let orderLine = o.orderLines[0];

    const hasNewDiscountedPcsPrice =
      orderLineDiscountedPcsPrice !== orderLine.discountedPcsPrice;

    // Here we check if the discount has been changed directly. If it
    // has changed because of changing the discountedPcsPrice we don't care.
    const hasChangedDiscount =
      !hasNewDiscountedPcsPrice &&
      !!orderLineDiscount &&
      orderLine.discount !== orderLineDiscount / 100;

    if (!orderLineIsFixedTotalPrice && hasNewDiscountedPcsPrice) {
      setShowSaveDiscountOnCustomerDialog(true);
      setIsFixedPriceDiscount(true);
    } else if (!orderLineIsFixedTotalPrice && hasChangedDiscount) {
      setShowSaveDiscountOnCustomerDialog(true);
      setIsFixedPriceDiscount(false);
    } else {
      setIsLoading(true);
      await postOrder(false, false);
      setIsLoading(false);
    }
  }

  async function handleDontSaveDiscount() {
    setShowSaveDiscountOnCustomerDialog(false);

    setIsLoading(true);
    await postOrder(false, false);
    setIsLoading(false);
  }

  async function handleSaveDiscount() {
    setIsLoading(true);

    if (isFixedPriceDiscount) {
      await postOrder(false, true);
    } else {
      await postOrder(true, false);
    }

    setShowSaveDiscountOnCustomerDialog(false);
    setIsLoading(false);
  }

  async function postOrder(saveFixedPercent, saveFixedPrice) {
    let o = order;
    let orderLine = o.orderLines[0];

    orderLine.discountedPcsPrice = orderLineDiscountedPcsPrice;
    orderLine.price = orderLinePrice;
    orderLine.isFixedTotalPrice = orderLineIsFixedTotalPrice;
    orderLine.discount = orderLineDiscount
      ? Number((orderLineDiscount / 100).toFixed(19))
      : 0;

    orderLine.saveFixedPercent = saveFixedPercent;
    orderLine.saveFixedPrice = saveFixedPrice;

    o.orderLines = [orderLine];

    o.paymentAgreement = paymentAgreement;
    o.alterOrderNo = alterOrderNo;
    o.customerOrderNo = customerOrderNo;
    o.paymentAgreement = paymentAgreement;
    o.extraNotification = extraNotification;
    const request = { order };
    let res = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}orders`,
      request,
      "POST"
    );

    // We reload data regardless of success. While it would be better only on success,
    // there is a version property on the order to avoid concurrent updates of the same
    // order. Since the possible changes in this dialog are so minor, it's easier to
    // just make them again than risking problems due to multiple users working on the
    // same data concurrently.
    let orderData = await loadOrder(orderId);
    setOrderData(orderData);
  }

  async function downloadPDF() {
    setIsLoading(true);
    const blob = await Api.fetchBlob(
      `${process.env.REACT_APP_MAIN_URL}orders/confirmation/pdf/${orderId}`,
      false,
      "GET"
    );
    const filename = `${Translate.get("OrderConfirmation").replace(" ", "_")}_${
      order.orderLines[0].part.description
    }_${formatDate(startDate)}.pdf`;
    download(blob, filename, blob.type);
    setIsLoading(false);
  };

  const showEmailsSuccessMessage = (message) => {
    const infoMessage = enqueueSnackbar(message, {
      variant: "success",
      autoHideDuration: 6000,
      onClick: () => closeSnackbar(infoMessage),
    });
  }

  return (
    <>
      {isLoading && <Preloader />}
      {showSendEmailDialog && (
        <GroupEmailDialog {...getOrderConfirmationEmailDialogProperties(
          api,
          order.actorId,
          order.actorName,
          orderId,
          {
            actorId: actorRefId,
            actorName: actorRefName,
            actorEmail: actorRefEmail,
            profilePictureUrl: order.actorRefProfilePictureUrl
          },
          async () => {
            await loadCourse();
            setShowSendEmailDialog(false);
            window.history.back(); // bygger på att dialogrutan har lagt på #dialog på slutet
          },
          async (updated) => {
            if(updated) {
              await loadCourse();
            }
            setShowSendEmailDialog(false);
          },
          () => showEmailsSuccessMessage(Translate.get("OrderConfirmationSent"))
        )}/>
      )}

      {showSaveDiscountOnCustomerDialog && (
        <Dialog open={true}>
          <DialogTitle>
            {isFixedPriceDiscount
              ? Translate.get("SaveFixedPriceDisountOnCustomer")
              : Translate.get("SavePercentDiscountOnCustomer")}
          </DialogTitle>
          <DialogContent>{isLoading && <Preloader />}</DialogContent>
          <DialogActions>
            <Button onClick={handleDontSaveDiscount}>
              {Translate.get("No")}
            </Button>
            <Button onClick={handleSaveDiscount}>{Translate.get("Yes")}</Button>
          </DialogActions>
        </Dialog>
      )}

      <Box sx={{ m: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h4 style={{ marginLeft: "0.5em" }}>
              {Translate.get("OrderInformation")}
            </h4>
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled
              id="outlined-required"
              label={Translate.get("OurReference")}
              value={supplierRefName}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled
              id="outlined-required"
              label={Translate.get("EPortsOrderNo")}
              value={orderNo}
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              disabled
              id="outlined-required"
              label={Translate.get("YourContact")}
              sx={{ mt: 2 }}
              value={actorRefName}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-required"
              label={Translate.get("OurOrderNo")}
              sx={{ mt: 2 }}
              value={alterOrderNo || ""}
              onChange={(e) => setAlterOrderNo(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-required"
              label={Translate.get("OrderNumberReference")}
              sx={{ mt: 2 }}
              value={customerOrderNo}
              onChange={(e) => setCustomerOrderNo(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ mt: 2 }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                {Translate.get("PaymentTerm") || ""}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={paymentAgreement || ""}
                label={Translate.get("PaymentTerm")}
                onChange={(e) => setPaymentAgreement(e.target.value)}
                fullWidth
                displayEmpty
              >
                {paymentAgreements.map((pa) => (
                  <MenuItem value={pa.ePortCodeId} key={pa.ePortCodeId}>
                    {pa.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <h4 style={{ marginLeft: "0.5em" }}>{Translate.get("Price")}</h4>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={Translate.get("OriginalPricePerParticipant")}
              value={orderLineOriginalPcsPrice}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={Translate.get("NumberOfCourseParticipants")}
              value={orderLineQty}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-required"
              label={Translate.get("PricePerParticipant")}
              value={orderLineDiscountedPcsPrice}
              onChange={handlePcsPriceChange}
              fullWidth
              disabled={orderLineIsFixedTotalPrice}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-required"
              label={Translate.get("Discount") + " (%)"}
              type="number"
              value={orderLineOriginalPcsPrice !== 0 ? orderLineDiscount : "-"} // Can't have discount when original price is 0
              onChange={handleDiscountChange}
              fullWidth
              disabled={
                orderLineIsFixedTotalPrice || orderLineOriginalPcsPrice === 0
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              sx={{ mt: 3, ml: 2 }}
              control={
                <Checkbox
                  checked={orderLineIsFixedTotalPrice}
                  onChange={handleIsFixedPriceChange}
                />
              }
              label={Translate.get("FixedPriceBooking")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id="outlined-required"
              label={Translate.get("TotalAmount")}
              sx={{ mt: 2 }}
              value={
                typeof orderLinePrice === "number"
                  ? orderLinePrice.toFixed(2)
                  : orderLinePrice
              }
              onChange={handleTotalPriceChange}
              fullWidth
              disabled={!orderLineIsFixedTotalPrice}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="outlined-required"
              label={Translate.get("Notes")}
              sx={{ mt: 2 }}
              value={extraNotification}
              onChange={(e) => setExtraNotification(e.target.value)}
              fullWidth
              multiline
              rows={4}
              inputProps={{ maxLength: 1048 }}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" sx={{ mt: 4 }}>
          <Button
            onClick={downloadPDF}
            startIcon={<DownloadIcon />}
            variant="outlined"
            disabled={
              order.alterOrderNo !== alterOrderNo ||
              order.customerOrderNo !== customerOrderNo ||
              order.paymentAgreement !== paymentAgreement ||
              order.orderLines[0].discountedPcsPrice !==
                orderLineDiscountedPcsPrice ||
              order.orderLines[0].discount !== orderLineDiscount / 100 ||
              order.orderLines[0].isFixedTotalPrice !==
                orderLineIsFixedTotalPrice ||
              order.orderLines[0].price !== orderLinePrice ||
              order.extraNotification !== extraNotification
            }
          >
            PDF
          </Button>
        </Grid>

        <Grid container justifyContent="flex-end" sx={{ mt: 4 }}>
          <Button onClick={close} autoFocus>
            {Translate.get("Close")}
          </Button>
          <Button
            sx={{ ml: 1 }}
            variant="contained"
            onClick={saveFixedPriceDialog}
            disabled={
              orderLineDiscountedPcsPrice === "" ||
              orderLineDiscount === "" ||
              orderLinePrice === ""
            }
          >
            {Translate.get("Save")}
          </Button>
          <Button
            sx={{ ml: 1 }}
            variant="outlined"
            onClick={() => setShowSendEmailDialog(true)}
            disabled={
              order.alterOrderNo !== alterOrderNo ||
              order.customerOrderNo !== customerOrderNo ||
              order.paymentAgreement !== paymentAgreement ||
              order.orderLines[0].discountedPcsPrice !==
                orderLineDiscountedPcsPrice ||
              order.orderLines[0].discount !== orderLineDiscount / 100 ||
              order.orderLines[0].isFixedTotalPrice !==
                orderLineIsFixedTotalPrice ||
              order.orderLines[0].price !== orderLinePrice ||
              order.extraNotification !== extraNotification
            }
          >
            {Translate.get("Send")}
          </Button>
        </Grid>
      </Box>
    </>
  );
}
