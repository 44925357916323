import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Translate from "../../utils/Translate";
import { useEffect, useState } from "react";
import AutocompleteSearchField from "../AutocompleteSearchField";
import { isCoursePartType, partType } from "../../utils/part";
import ButtonWithSpinner from "../ButtonWithSpinner";
import StUnitField from "./StUnitField";
import {
  canUpdateOrderLine,
  canUpdateOrderLineNotQty,
  canUpdateOrderLineQty,
} from "../../utils/order";

// We use IDs like "dummyId1" or crypto.RandomUUID() here in frontend for new orderLines
function isDummyOrderLineId(orderLineId) {
  return typeof orderLineId !== "number";
}

export default function OrderLineDialog({
  orderLine,
  onSave,
  onCancel,
  partSearch,
  companyActorId,
  getPrice,
  getStUnits,
  currency,
}) {
  if (orderLine.partId && !orderLine.part) {
    throw new Error("Missing part");
  }

  const [stUnits, setStUnits] = useState([]);
  const [isExistingOrderLine] = useState(
    !isDummyOrderLineId(orderLine.orderLineId)
  );
  const [existingDiscount, setExistingDiscount] = useState(
    orderLine.discount ?? 0
  );
  const [existingDiscountedPcsPrice, setExistingDiscountedPcsPrice] = useState(
    orderLine.discountedPcsPrice
  );
  const [updatedOrderLine, setUpdatedOrderLine] = useState({
    ...orderLine,
    qty: orderLine.qty ?? 1,
    discount: orderLine.discount ?? 0,
    isFixedTotalPrice: orderLine.isFixedTotalPrice ?? false,
    saveFixedPrice: orderLine.saveFixedPrice ?? false,
    saveFixedPercent: orderLine.saveFixedPercent ?? false,
  });

  useEffect(() => {
    if (orderLine && orderLine.partId > 0) {
      getStUnits(orderLine.partId).then((response) =>
        setStUnits(
          response.isSuccessful ? response.stUnits.sort(sortStUnits) : []
        )
      );
    }
  }, [orderLine, getStUnits]);

  function isValid() {
    return (
      updatedOrderLine.partId &&
      updatedOrderLine.part &&
      updatedOrderLine.qty > 0 &&
      updatedOrderLine.discountedPcsPrice >= 0 &&
      // updatedOrderLine.discount >= 0 && we actually support negative discounts
      updatedOrderLine.discount <= 1 &&
      updatedOrderLine.price >= 0
      // updatedOrderLine.vat
    );
  }

  function sortStUnits(a, b) {
    if (!a.blocked && b.blocked) {
      return -1;
    } else if (a.blocked && !b.blocked) {
      return 1;
    }

    if (a.isUnlimitedQty && !b.isUnlimitedQty) {
      return -1;
    } else if (!a.isUnlimitedQty && b.isUnlimitedQty) {
      return 1;
    }

    return b.qty - a.qty;
  }

  async function handlePartSelected(selectedPart) {
    const qty = updatedOrderLine.qty;
    const [partResponse, stUnitsResponse] = await Promise.all([
      getPrice(selectedPart.partId, qty, companyActorId),
      getStUnits(selectedPart.partId),
    ]);
    if (partResponse.isSuccessful) {
      setUpdatedOrderLine((prev) => ({
        ...prev,
        partId: selectedPart.partId,
        part: selectedPart,
        discountedPcsPrice: partResponse.price,
        discount: partResponse.discount,
        originalPcsPrice: partResponse.originalPrice,
        price: partResponse.price * qty,
        vat: partResponse.vatPercent,
      }));
      setExistingDiscount(partResponse.discount);
      setExistingDiscountedPcsPrice(partResponse.price);
    }
    setStUnits(
      stUnitsResponse.isSuccessful
        ? stUnitsResponse.stUnits.sort(sortStUnits)
        : []
    );
  }

  function handleQtyChange(newValue) {
    const newPrice = newValue * updatedOrderLine.discountedPcsPrice;
    setUpdatedOrderLine((prev) => ({
      ...prev,
      qty: Number(newValue),
      price: newPrice,
    }));
  }

  function handlePcsPriceChange(newValue) {
    const newPrice = updatedOrderLine.qty * newValue;
    const newDiscount =
      updatedOrderLine.originalPcsPrice !== 0
        ? Number((1 - newValue / updatedOrderLine.originalPcsPrice).toFixed(19))
        : 0;

    setUpdatedOrderLine((prev) => ({
      ...prev,
      discountedPcsPrice: Number(newValue),
      price: newPrice,
      discount: newDiscount,
    }));

    if (newDiscount === 0) {
      // Reset checkboxes since there is no longer any discount
      setUpdatedOrderLine((prev) => ({
        ...prev,
        saveFixedPrice: false,
        saveFixedPercent: false,
      }));
    }
  }

  function handleDiscountChange(newValue) {
    newValue = Math.min(newValue, 1);
    const newPcsPrice = Number(
      (updatedOrderLine.originalPcsPrice * (1 - newValue)).toFixed(6)
    );
    const newPrice = newPcsPrice * updatedOrderLine.qty;

    setUpdatedOrderLine((prev) => ({
      ...prev,
      discount: Number(newValue),
      discountedPcsPrice: newPcsPrice,
      price: newPrice,
    }));

    if (newValue == 0) {
      // Reset checkboxes since there is no longer any discount
      setUpdatedOrderLine((prev) => ({
        ...prev,
        saveFixedPrice: false,
        saveFixedPercent: false,
      }));
    }
  }

  function handleTotalPriceChange(newValue) {
    if (!updatedOrderLine.isFixedTotalPrice) {
      throw new Error();
    }

    const newPcsPrice = Number((newValue / updatedOrderLine.qty).toFixed(6));
    setUpdatedOrderLine((prev) => ({
      ...prev,
      discountedPcsPrice: newPcsPrice,
      price: Number(newValue),
    }));

    if (newPcsPrice === updatedOrderLine.originalPcsPrice) {
      // Reset checkboxes since there is no longer any discount
      setUpdatedOrderLine((prev) => ({
        ...prev,
        saveFixedPrice: false,
        saveFixedPercent: false,
      }));
    }
  }

  async function resetOrderLinePrices() {
    if (isExistingOrderLine) {
      setUpdatedOrderLine((prev) => ({
        ...prev,
        discountedPcsPrice: orderLine.discountedPcsPrice,
        discount: orderLine.discount,
        price: orderLine.price,
      }));
    } else {
      const response = await getPrice(
        updatedOrderLine.partId,
        updatedOrderLine.qty,
        companyActorId
      );
      if (response.isSuccessful) {
        setUpdatedOrderLine((prev) => ({
          ...prev,
          discountedPcsPrice: response.price,
          discount: response.discount,
          price: response.price * prev.qty,
        }));
        setExistingDiscount(response.discount);
        setExistingDiscountedPcsPrice(response.price);
      }
    }
  }

  async function handleIsFixedPriceChange(newValue) {
    if (!updatedOrderLine.partId) {
      return;
    }

    if (newValue) {
      const newPcsPrice = Number(
        (updatedOrderLine.price / updatedOrderLine.qty).toFixed(6)
      );
      setUpdatedOrderLine((prev) => ({
        ...prev,
        isFixedTotalPrice: newValue,
        discount: 0,
        discountedPcsPrice: newPcsPrice,
        saveFixedPrice: false,
        saveFixedPercent: false,
      }));
    } else {
      await resetOrderLinePrices();
      setUpdatedOrderLine((prev) => ({
        ...prev,
        isFixedTotalPrice: newValue,
      }));
    }
  }

  function isQtyUpdateAllowed() {
    // Other partTypes are managed elsewhere. For example, the orderLine.qty for a courseBooking
    // depends on the booked number of students, and for a plasticCardPrintJob it depends on the
    // number of cards that were sent in. So it doesn't make sense to change the qty here.
    const currentPartType = updatedOrderLine.part?.partType;
    return (
      currentPartType === partType.book ||
      currentPartType === partType.resellableELearningLicense ||
      currentPartType === partType.resellableExtraFinalExam ||
      currentPartType === partType.storeGoods
    );
  }

  function hasChanges() {
    return (
      (orderLine &&
        updatedOrderLine &&
        orderLine.partId !== updatedOrderLine.partId) ||
      orderLine.price !== updatedOrderLine.price ||
      orderLine.discountedPcsPrice !== updatedOrderLine.discountedPcsPrice ||
      (orderLine.discount ?? 0) !== updatedOrderLine.discount ||
      (orderLine.qty ?? 1) !== updatedOrderLine.qty ||
      (orderLine.isFixedTotalPrice ?? false) !==
        updatedOrderLine.isFixedTotalPrice
    );
  }

  return (
    <Dialog open={true} className="mediumCourseDialog">
      <DialogTitle>{Translate.get("OrderLine")}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item marginTop={2}>
            <h4>{Translate.get("PartLabel")}</h4>
          </Grid>
          <Grid item>
            <AutocompleteSearchField
              disabled={isExistingOrderLine}
              label={Translate.get("PartLabel")}
              value={updatedOrderLine.part ?? ""}
              groupBy={(option) => Translate.getPartTypePlural(option.partType)}
              onValueChange={(selectedPart) => handlePartSelected(selectedPart)}
              getOptionLabel={(option) => option?.description ?? ""}
              renderOption={(option) => (
                <Grid container>
                  <Grid item xs={3}>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        color: "text.secondary",
                      }}
                    >
                      {option.partNo ?? ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Box>{option.description}</Box>
                  </Grid>
                </Grid>
              )}
              keyPropName="partId"
              disableAddAsNew={true}
              requireSelection={true}
              search={async (searchText) => {
                const results = await partSearch(searchText);
                // Sorting needed for groupBy of search results
                return results.sort((a, b) => {
                  const partTypeSort = Translate.getPartTypePlural(
                    a.partType
                  ).localeCompare(Translate.getPartTypePlural(b.partType));
                  if (partTypeSort != 0) {
                    return partTypeSort;
                  } else if (a.partNo && b.partNo) {
                    return !isNaN(a.partNo) && !isNaN(b.partNo)
                      ? Number(a.partNo) - Number(b.partNo)
                      : a.partNo.localeCompare(b.partNo);
                  } else if (a.partNo) {
                    return 1;
                  } else if (b.partNo) {
                    return -1;
                  } else {
                    return a.description.localeCompare(b.description);
                  }
                });
              }}
              textFieldProps={{
                required: true,
                error: false,
                helperText: "",
              }}
            />
          </Grid>
          {updatedOrderLine.partId && (
            <Grid item>
              <TextField
                fullWidth
                required
                disabled={
                  !updatedOrderLine.partId ||
                  !canUpdateOrderLineQty(updatedOrderLine) ||
                  !isQtyUpdateAllowed()
                }
                label={Translate.get("Quantity")}
                value={isNaN(updatedOrderLine.qty) ? "" : updatedOrderLine.qty}
                onChange={(event) => handleQtyChange(event.target.value)}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  type: "number",
                  min: 1,
                }}
              />
            </Grid>
          )}

          {updatedOrderLine.partId && (
            <Grid
              container
              item
              spacing={2}
              marginTop={2}
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item>
                <h4>{Translate.get("Price")}</h4>
              </Grid>
              <Grid item>
                <FormControlLabel
                  sx={{ marginBottom: "-10px" }}
                  control={
                    <Checkbox
                      disabled={
                        !updatedOrderLine.partId ||
                        !canUpdateOrderLineNotQty(updatedOrderLine)
                      }
                      checked={updatedOrderLine.isFixedTotalPrice}
                      onChange={(event) =>
                        handleIsFixedPriceChange(event.target.checked)
                      }
                    />
                  }
                  label={Translate.get("FixedPrice")}
                />
              </Grid>
            </Grid>
          )}

          {updatedOrderLine.partId && !updatedOrderLine.isFixedTotalPrice && (
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  disabled={
                    !updatedOrderLine.partId ||
                    !canUpdateOrderLineNotQty(updatedOrderLine)
                  }
                  label={Translate.get("PricePerUnit")}
                  value={updatedOrderLine.discountedPcsPrice ?? ""}
                  onChange={(event) => handlePcsPriceChange(event.target.value)}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    type: "number",
                    min: 0,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currency}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required={updatedOrderLine.originalPcsPrice !== 0}
                  disabled={
                    !updatedOrderLine.partId ||
                    !canUpdateOrderLineNotQty(updatedOrderLine) ||
                    updatedOrderLine.originalPcsPrice === 0
                  }
                  label={Translate.get("Discount")}
                  value={
                    updatedOrderLine.originalPcsPrice === 0
                      ? "-" // Can't have discount when original price is 0
                      : updatedOrderLine.discount
                      ? updatedOrderLine.discount * 100
                      : 0
                  }
                  onChange={(event) =>
                    handleDiscountChange(
                      isNaN(event.target.value) ? 0 : event.target.value / 100
                    )
                  }
                  inputProps={
                    updatedOrderLine.originalPcsPrice === 0
                      ? {}
                      : {
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          type: "number",
                          max: 100,
                        }
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          )}

          {updatedOrderLine.partId && (
            <Grid item>
              <TextField
                fullWidth
                required={updatedOrderLine.isFixedTotalPrice}
                disabled={
                  !updatedOrderLine.isFixedTotalPrice ||
                  !updatedOrderLine.partId ||
                  !canUpdateOrderLineNotQty(updatedOrderLine)
                }
                label={Translate.get("TotalAmount")}
                value={updatedOrderLine.price ?? ""}
                onChange={(event) =>
                  handleTotalPriceChange(
                    isNaN(event.target.value) ? 0 : event.target.value
                  )
                }
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  type: "number",
                  min: 0,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}

          {updatedOrderLine.partId &&
            ((updatedOrderLine.discount !== 0 &&
              existingDiscount !== updatedOrderLine.discount) ||
              (updatedOrderLine.originalPcsPrice === 0 &&
                existingDiscountedPcsPrice !==
                  updatedOrderLine.discountedPcsPrice)) && (
              <Grid item container direction="column">
                <Grid item>
                  <FormControlLabel
                    sx={{ marginBottom: "-10px" }}
                    control={
                      <Checkbox
                        disabled={
                          !updatedOrderLine.discountedPcsPrice ||
                          !canUpdateOrderLineNotQty(updatedOrderLine)
                        }
                        checked={updatedOrderLine.saveFixedPrice}
                        onChange={(event) =>
                          setUpdatedOrderLine((prev) => ({
                            ...prev,
                            saveFixedPrice: event.target.checked,
                            saveFixedPercent: false,
                          }))
                        }
                      />
                    }
                    label={Translate.get("SaveDiscountFixedPrice")}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    sx={{ marginBottom: "-10px" }}
                    control={
                      <Checkbox
                        disabled={
                          !updatedOrderLine.discount ||
                          !canUpdateOrderLineNotQty(updatedOrderLine) ||
                          updatedOrderLine.originalPcsPrice === 0
                        }
                        checked={updatedOrderLine.saveFixedPercent}
                        onChange={(event) =>
                          setUpdatedOrderLine((prev) => ({
                            ...prev,
                            saveFixedPercent: event.target.checked,
                            saveFixedPrice: false,
                          }))
                        }
                      />
                    }
                    label={Translate.get("SaveDiscountFixedPercent")}
                  />
                </Grid>
              </Grid>
            )}

          {updatedOrderLine.partId && (
            <Grid container item spacing={2} marginTop={2} direction="column">
              <Grid item>
                <h4>{Translate.get("StorageInfoLabel")}</h4>
              </Grid>

              {stUnits && stUnits.length > 0 && (
                <Grid item container spacing={1}>
                  {stUnits.map((s) => (
                    <Grid item key={s.stUnitId}>
                      <StUnitField {...s} />
                    </Grid>
                  ))}
                </Grid>
              )}
              {(!stUnits || stUnits.length === 0) && <Grid item>-</Grid>}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCancel}>
          {Translate.get("Cancel")}
        </Button>
        <ButtonWithSpinner
          variant="contained"
          onClick={async () => await onSave(updatedOrderLine)}
          disabled={!isValid() || !canUpdateOrderLine(updatedOrderLine)}
        >
          {Translate.get("Ok")}
        </ButtonWithSpinner>
      </DialogActions>
    </Dialog>
  );
}
