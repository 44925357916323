import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import PersonIcon from "@mui/icons-material/Person";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import InfoIcon from "@mui/icons-material/Info";
import EmailIcon from "@mui/icons-material/Email";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import SchoolIcon from "@mui/icons-material/School";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LanguageIcon from "@mui/icons-material/Language";
import LinearProgress from "@mui/material/LinearProgress";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import EditIcon from "@mui/icons-material/Edit";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import Translate from "../../utils/Translate";
import LinkMenu from "../LinkMenu";
import DotMenu from "../DotMenu";
import RemoveCourseParticipant from "./dialogs/RemoveCourseParticipant";
import DetailedResults from "./dialogs/DetailedResults";
import Language from "./dialogs/Language";
import { TableAvatar } from "../../utils/dataGrid";
import QuizIcon from "@mui/icons-material/Quiz";
import Api, { useApi } from "../../utils/Api";
import PersonCrud from "../actors/crud/PersonCrud";
import { hasSomeAccess, accessKeys, hasAccess } from "../../utils/userAccess";
import { useSnackbar } from "notistack";
import YesOrNoDialog from "../YesOrNoDialog";
import { useNavigate, useLocation } from "react-router-dom";
import SendSms from "./dialogs/SendSms";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import GroupEmailDialog from "../emails/GroupEmailDialog";

import {
  findSentCommunication,
  DocumentType,
  MediaType,
  CommunicationRole,
} from "./CourseCommunication";
import Preloader from "../Preloader";
import { tryFormatDate } from "../../utils/dateTimeFormat";
import DuplicateSelfRegActorMerge from "./DuplicateSelfRegActorMerge";
import SmsIcon from "@mui/icons-material/Sms";
import ExtraFinalExamAndSendFlow from "./dialogs/ExtraFinalExamAndSendFlow";
import ManualAuthentication from "./ManualAuthentication";
import { Authenticated } from "./Authentication";
import StudentLicenseIconbox from "./StudentLicenseIconbox";
import {
  getCourseInvitaitonEmailDialogPropertiesForStudents,
  getSendExtraExamEmailDialogPropertiesForStudents,
  getSendPasswordEmailDialogPropertiesForStudents
} from "./courseEmailsHelper";
import { sanitizeFileName } from "../../utils/filename";

/*Editera personuppgifter
Skicka lösenord
Se detaljerat resultat
Ladda ner diplom (endast UK & FR i dagsläget)
Öppna/stäng slutprov
Ändra språk
Ta bort kursdeltagare (endast om lösenord ej startat). Minskar bokningen med en person. */

async function postAddOrUpdateStudent(api, student) {
  if (!student.courseBookingId || !student.actorId) {
    throw new Error("Missing data for student");
  }

  const response = await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}courses/participants`,
    student,
    "POST"
  );

  return !!response;
}

async function getStudentActiveCourseParticipantId(api, actorId, courseId) {
  const response = await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}courses/${courseId}/actor/${actorId}/has-active-bookings/`,
    false,
    "GET"
  );

  return response && response.length > 0 ? response[0] : null;
}

async function postMoveStudent(
  api,
  courseBookingId,
  courseParticipantIdToMove,
  courseParticipantIdToReplace,
  eLearningLicenseId,
  isPrivateBooking,
  enqueueSnackbar,
  closeSnackbar
) {
  if (isPrivateBooking) {
    throw new Error("Don't move students for private bookings");
  }

  const dto = {
    courseBookingId,
    courseParticipantIdToMove,
    eLearningLicenseId,
    courseParticipantIdToReplace:
      courseParticipantIdToReplace > 0 ? courseParticipantIdToReplace : null,
  };

  const response = await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}courses/participants/move/`,
    dto,
    "POST"
  );

  if (!response || !response.isSuccessful) {
    const errorMessage = enqueueSnackbar(
      Translate.get(response.errorMessageTranslationKey ?? "SomethingFailed"),
      {
        variant: "error",
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      }
    );
    return false;
  }

  return true;
}

async function postUndoAddExtraFinalExam(
  eLearningLicenseId,
  newFinalExamNumber,
  api,
  loadCourse,
  toggleLoading
) {
  const dto = {
    eLearningLicenseId,
    newFinalExamNumber,
  };
  toggleLoading(true);
  const response = await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}licenses/elearning/exam/undo-extra/`,
    dto,
    "POST"
  );
  toggleLoading(false);
  if (!!response && response.isSuccessful) {
    loadCourse();
  }
}

const getDotMenu = (
  api,
  student,
  props,
  locked,
  finalExam,
  progress,
  toggleLoading,
  loadCourse,
  resetSelected,
  enqueueSnackbar,
  closeSnackbar,
  handleSaveStudent,
  closePersonCrudAndReload,
  displayEmailDialog,
  hideEmailDialog,
  showEmailsSuccessMessage
) => {
  let disabledLock = false;
  let disabledDelete = false;
  if (finalExam !== null) {
    locked = true;
    disabledLock = true;
  }

  if (progress) {
    disabledDelete = true;
  }

  let isELearningCourse = eLearningCourse(props.eLearningType);
  const hasFailedFinalExam =
    !!student.finalExamEndDate && !student.finalExamDoneOk;
  const hasExtraFinalExam = student.currentFinalExamNumber > 1;
  const hasNewExtraFinalExam = hasExtraFinalExam && !student.finalExamStartDate;

  // Increasing this number is supported, but we decided to cap it here for now
  const hasMaxNumberOfExtraExams = student.currentFinalExamNumber >= 2;

  const menudata = [
    {
      icon: <EditIcon />,
      text: Translate.get("EditPersonalInfo"),
      route: false,
      disabled: student.isDummyLicense,
      component: (
        <PersonCrud
          actorId={student.actorId}
          actorRole="student"
          parentActorId={props.companyActorId}
          isMatchingActorsDisabled={progress > 0 || props.isPrivateBooking}
          onActorSaved={async (actor) =>
            await handleSaveStudent(
              student.actorId,
              actor.actorId,
              props.id,
              student.courseParticipantId,
              student.eLearningLicenseId,
              student.actorOrDummyId
            )
          }
          onCancel={closePersonCrudAndReload}
          // We will close it from this component
          onClose={() => {}}
        />
      ),
      hide: false,
    },
    {
      icon: <VpnKeyIcon />,
      text: Translate.get("SendPassword"),
      route: false,
      dialogHeader: Translate.get("SendPassword"),
      onClick: () => {
        displayEmailDialog(
          getSendPasswordEmailDialogPropertiesForStudents(
            api,
            props.companyActorId,
            props.companyName,
            [student],
            props.eLearningType,
            async () => {
              await loadCourse();
              hideEmailDialog();
            },
            async (actorUpdated) => {
              if(actorUpdated) {
                await loadCourse();
              }
              hideEmailDialog();
            },
            () => showEmailsSuccessMessage(Translate.get("PasswordSent"))
          )
        );
      }, 
      hide: !isELearningCourse,
      customClass: "mediumCourseDialog",
      disabled: student.isDummyLicense,
    },

    {
      icon: <AddAlertIcon />,
      text: Translate.get("SendCourseInvitation"),
      dialogHeader: Translate.get("SendCourseInvitation"),
      route: false,
      onClick: () => 
        displayEmailDialog(
          getCourseInvitaitonEmailDialogPropertiesForStudents(
          api,
          props.companyActorId,
          props.companyName,
          props.courseId,
          props.id, //.courseBookingId,
          props.courseFromDate,
          [student], 
          async () => {
            await loadCourse();
            hideEmailDialog();
          },
          async (actorUpdated) => {
            if(actorUpdated) {
              await loadCourse();
            }
            hideEmailDialog();
          },
          () => showEmailsSuccessMessage(Translate.get("CourseInvitationSent"))
        )
      ),
      hide: !hasAccess(accessKeys.advancedPackage),
      customClass: "mediumCourseDialog",
      disabled: !!student.dummyType || student.isDummyLicense,
    },

    {
      icon: <SmsIcon />,
      text: Translate.get("SendSms2"),
      route: false,
      dialogHeader: Translate.get("SendSms2"),
      component: hasAccess(accessKeys.advancedPackage) ? (
        <SendSms
          {...{
            selectedStudents: [student],
            courseId: props.courseId,
            companyActorId: props.companyActorId,
            companyName: props.companyName,
            reloadCourse: loadCourse
          }}
        />
      ) : null,
      hide: !hasAccess(accessKeys.advancedPackage),
      disabled: !!student.dummyType || student.isDummyLicense,
      customClass: "mediumCourseDialog",
    },
    {
      icon: <QuizIcon />,
      text: Translate.get("DetailedResult"),
      route: false,
      component: (
        <DetailedResults eLearningLicenseId={student.eLearningLicenseId} />
      ),
      hide: !isELearningCourse,
      disabled: !!student.dummyType || student.isDummyLicense,
    },
    {
      icon: <SchoolIcon />,
      text: Translate.get("DownloadCertificate"),
      disabled:
        progress !== 100 || !!student.dummyType || student.isDummyLicense,
      route: false,
      onClick: () =>
        getPocPdf(
          student.eLearningLicenseId,
          student.actorName,
          toggleLoading,
          enqueueSnackbar,
          closeSnackbar
        ),
      hide: !isELearningCourse,
    },
    {
      icon: <NoteAddIcon />,
      text: Translate.get("AddExtraFinalExamLabel"),
      route: false,
      dialogHeader: Translate.get("AddExtraFinalExamLabel"),
      component: (
        <ExtraFinalExamAndSendFlow
          eLearningLicenseId={student.eLearningLicenseId}
          newFinalExamNumber={student.currentFinalExamNumber + 1}
          loadCourse={loadCourse}
          emailDialogProperties={getSendExtraExamEmailDialogPropertiesForStudents(
            api,
            props.companyActorId,
            props.companyName,
            [student],
            async () => {
              await loadCourse();
              hideEmailDialog();
            },
            async (actorUpdated) => {
              if(actorUpdated) {
                await loadCourse();
              }
              hideEmailDialog();
              
            },
            () => showEmailsSuccessMessage(Translate.get("ExtraFinalExamInfoSent"))
          )}
        />
      ),
      hide:
        !isELearningCourse ||
        !student.finalExamEndDate ||
        student.finalExamDoneOk ||
        hasMaxNumberOfExtraExams,
      disabled: !hasFailedFinalExam || hasMaxNumberOfExtraExams,
    },
    {
      icon: <NoteAddIcon />,
      text: Translate.get("UndoAddExtraFinalExamLabel"),
      route: false,
      onClick: () =>
        postUndoAddExtraFinalExam(
          student.eLearningLicenseId,
          student.currentFinalExamNumber - 1,
          api,
          loadCourse,
          toggleLoading
        ),
      hide: !isELearningCourse || !hasExtraFinalExam,
      disabled: !hasNewExtraFinalExam,
    },
    {
      icon: <EmailIcon />,
      text: Translate.get("SendExtraExamEmailLabel"),
      route: false,
      dialogHeader: Translate.get("SendExtraExamEmailLabel"),
      onClick: () => {
        displayEmailDialog(
          getSendExtraExamEmailDialogPropertiesForStudents(
            api,
            props.companyActorId,
            props.companyName,
            [student],
            async () => {
              await loadCourse();
              hideEmailDialog();
            },
            async (actorUpdated) => {
              if(actorUpdated) {
                await loadCourse();
              }
              hideEmailDialog();
            },
            () => showEmailsSuccessMessage(Translate.get("ExtraFinalExamInfoSent")))
          );
      },
      hide:
        hasAccess(accessKeys.englishCompany) ||
        !isELearningCourse ||
        !hasExtraFinalExam,
      disabled: !!student.finalExamEndDate,
    },
    {
      icon: locked ? <LockOpenIcon /> : <LockIcon />,
      text: locked
        ? Translate.get("UnlockFinalExam")
        : Translate.get("LockFinalExam"),
      //disabled: disabledLock || !!student.dummyType || student.isDummyLicense,
      disabled: disabledLock,
      route: false,
      onClick: () =>
        toggleFinalExamLock(
          api,
          [student.eLearningLicenseId],
          props.locked,
          toggleLoading,
          loadCourse,
          enqueueSnackbar,
          closeSnackbar
        ),
      hide: !isELearningCourse || !!student.finalExamEndDate,
    },
    {
      icon: <LanguageIcon />,
      text: Translate.get("ChooseLanguage"),
      route: false,
      dialogHeader: Translate.get("ChooseLanguage"),
      component: (
        <Language
          {...props}
          eLearningLicenseIds={[student.eLearningLicenseId]}
          loadCourse={loadCourse}
        />
      ),
      hide: !isELearningCourse,
      customClass: "smallCourseDialog",
      //disabled: !!student.dummyType || student.isDummyLicense,
    },
    {
      icon: <HowToRegIcon />,
      text: Translate.get("ManualAuth"),
      disabled: student.dummyType,
      route: false,
      dialogHeader: Translate.get("ManualAuth"),
      component: (
        <ManualAuthentication
          loadCourse={loadCourse}
          courseParticipantId={student.courseParticipantId}
          existingManualAuths={student.manualAuthentications}
        />
      ),
      hide: !hasSomeAccess(
        accessKeys.standardPackage,
        accessKeys.advancedPackage
      ),
    },
    {
      icon: <QrCode2Icon />,
      text: Translate.get("SelfReg"),
      disabled: student.dummyType,
      route: false,
      dialogHeader: Translate.get("SelfReg"),
      onClick: () =>
        window.open(
          `${window.location.origin}/dashboard/course/${props.courseId}/${props.id}/${student.actorId}/selfreg`,
          "_blank"
        ),
      hide: !hasSomeAccess(
        accessKeys.standardPackage,
        accessKeys.advancedPackage
      ),
    },
    {
      icon: <DeleteIcon />,
      text: Translate.get("DeleteStudent"),
      disabled:
        disabledDelete || student.dummyType === 2 || student.actorLicense,
      route: false,
      dialogHeader:
        props.numberOfParticipants === 1
          ? Translate.get("DeleteStudentAndBooking")
          : Translate.get("DeleteStudent"),
      component: (
        <RemoveCourseParticipant
          courseBookingId={props.id}
          students={[
            {
              actorName: student.actorName,
              courseParticipantId: student.courseParticipantId,
              eLearningLicenseId: student.eLearningLicenseId,
            },
          ]}
          bookedNumberOfStudents={props.numberOfParticipants}
          loadCourse={loadCourse}
          resetSelected={resetSelected}
        />
      ),
      hide: false,
      customClass: "smallCourseDialog",
    },
  ];
  return menudata;
};

function eLearningCourse(eLearningType) {
  if (
    eLearningType !== undefined &&
    eLearningType !== null &&
    eLearningType > 0
  ) {
    return true;
  }
  return false;
}

function getPocPdf(
  eLearningLicenseId,
  participantName,
  toggleLoading,
  enqueueSnackbar,
  closeSnackbar
) {
  toggleLoading(true);
  const fileName =
    sanitizeFileName(participantName) +
    "_" +
    Translate.get("GenericProofOfCompletionTitle");

  let serviceUrl =
    "licenses/elearning/proof-of-completion/pdf/" + eLearningLicenseId;
  Api.fetchBlob(
    `${process.env.REACT_APP_MAIN_URL}` + serviceUrl,
    false,
    "GET",
    false,
    false
  )
    .then((data) => {
      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(data);
      a.download = fileName;
      a.click();
    })
    .catch(function () {
      const errorMessage = enqueueSnackbar(Translate.get("SomethingFailed"), {
        variant: "error",
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      });
    })
    .finally((f) => {
      toggleLoading(false);
    });
}

function getPocPdfMany(
  eLearningLicenseIds,
  toggleLoading,
  loadCourse,
  enqueueSnackbar,
  closeSnackbar
) {
  if (eLearningLicenseIds === undefined || eLearningLicenseIds.length === 0) {
    return;
  }

  toggleLoading(true);

  let dateObj = new Date();
  let date = dateObj.toLocaleString();

  const fileName = "poc_" + date;
  const serviceUrl = "licenses/elearning/proof-of-completions/pdf";

  const reqObj = {
    eLearningLicenseIds: eLearningLicenseIds,
  };

  Api.fetchBlob(
    `${process.env.REACT_APP_MAIN_URL}` + serviceUrl,
    reqObj,
    "POST",
    false,
    true
  )
    .then((data) => {
      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(data);
      a.download = fileName;
      a.click();
    })
    .catch(function () {
      const errorMessage = enqueueSnackbar(Translate.get("SomethingFailed"), {
        variant: "error",
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      });
    })
    .finally((f) => {
      toggleLoading(false);
    });
}

function toggleFinalExamLock(
  api,
  eLearningLicenseIds,
  locked,
  toggleLoading,
  loadCourse,
  enqueueSnackbar,
  closeSnackbar
) {
  toggleLoading(true);
  const reqObj = {
    eLearningLicenseIds: eLearningLicenseIds,
  };

  let serviceUrl = locked
    ? "licenses/elearning/exam/unlock"
    : "licenses/elearning/exam/lock";

  api
    .fetch(
      `${process.env.REACT_APP_MAIN_URL}` + serviceUrl,
      reqObj,
      "POST",
      false,
      true
    )
    .finally((f) => {
      toggleLoading(false);
      loadCourse();
    })
    .catch((ex) => {
      const errorMessage = enqueueSnackbar(Translate.get("SomethingFailed"), {
        variant: "error",
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      });
    });
}

const getLinkMenu = (
  api,
  students,
  props,
  toggleLoading,
  loadCourse,
  resetSelected,
  enqueueSnackbar,
  closeSnackbar,
  displayEmailDialog,
  hideEmailDialog,
  showEmailsSuccessMessage
) => {
  const selectedStudents = students.filter(
    (row) => props.selected.includes(row.actorOrDummyId) && !row.isDummyLicense
  );
  const selectedELs = selectedStudents.map((r) => r.eLearningLicenseId);
  const selectedAndCompletedELs = selectedStudents
    .filter((s) => s.finalExamScore !== null)
    .map((s) => s.eLearningLicenseId);
  const deletableStudents = selectedStudents.filter(
    (s) => !(s.progressPercent > 0 || s.dummyType === 2 || s.actorLicense)
  );
  let isELearningCourse = eLearningCourse(props.eLearningType);
  const menudata = [
    {
      icon: <LockOpenIcon />,
      text: Translate.get("UnlockFinalExam"),
      route: false,
      onClick: () =>
        toggleFinalExamLock(
          api,
          selectedELs,
          true, // Always unlock
          toggleLoading,
          loadCourse,
          enqueueSnackbar,
          closeSnackbar
        ),
      hide: !isELearningCourse,
      disabled: !selectedStudents.some((r) => r.finalExamIsLocked === true),
      disabledTooltip: Translate.get("ExamsAlreadyUnlocked"),
    },
    {
      icon: <VpnKeyIcon />,
      text: Translate.get("SendPassword"),
      route: false,
      dialogHeader: Translate.get("SendPassword"),
      onClick: () => displayEmailDialog(
        getSendPasswordEmailDialogPropertiesForStudents(
          api,
          props.companyActorId,
          props.companyName,
          selectedStudents,
          props.eLearningType,
          async () => {
            await loadCourse();
            hideEmailDialog();
          },
          async (actorUpdated) => {
            if(actorUpdated) {
              await loadCourse();
            }
            hideEmailDialog();
          },
          () => showEmailsSuccessMessage(Translate.get("PasswordSentPlural"))
         )),
      hide: !isELearningCourse,
      disabled: selectedStudents.length === 0,
      customClass: "mediumCourseDialog",
    },
    {
      icon: <AddAlertIcon />,
      text: Translate.get("SendCourseInvitation"),
      dialogHeader: Translate.get("SendCourseInvitation"),
      route: false,
      onClick: () => 
        displayEmailDialog(
          getCourseInvitaitonEmailDialogPropertiesForStudents(
          api,
          props.companyActorId,
          props.companyName,
          props.courseId,
          props.id, //.courseBookingId,
          props.courseFromDate,
          selectedStudents, 
          async () => {
            await loadCourse();
            hideEmailDialog();
          },
          async (actorUpdated) => {
            if(actorUpdated) {
              await loadCourse();
            }
            hideEmailDialog();
          },
          () => showEmailsSuccessMessage(Translate.get("CourseInvitationSent"))
        )
      ),
      hide: !hasAccess(accessKeys.advancedPackage),
      customClass: "mediumCourseDialog",
    },
    {
      icon: <SmsIcon />,
      text: Translate.get("SendSms2"),
      route: false,
      dialogHeader: Translate.get("SendSms2"),
      component: hasAccess(accessKeys.advancedPackage) ? (
        <SendSms
          {...{
            selectedStudents,
            courseId: props.courseId,
            companyActorId: props.companyActorId,
            companyName: props.companyName,
            reloadCourse: loadCourse
          }}
        />
      ) : null,
      hide: !hasAccess(accessKeys.advancedPackage),
      disabled: selectedStudents.length === 0,
      customClass: "mediumCourseDialog",
    },
    {
      icon: <SchoolIcon />,
      text: Translate.get("DownloadCertificate"),
      route: false,
      onClick: () =>
        getPocPdfMany(
          selectedAndCompletedELs,
          toggleLoading,
          loadCourse,
          enqueueSnackbar,
          closeSnackbar
        ),
      disabled: selectedAndCompletedELs.length === 0,
      hide: !isELearningCourse,
    },
    {
      icon: <LanguageIcon />,
      text: Translate.get("ChooseLanguage"),
      route: false,
      dialogHeader: Translate.get("ChooseLanguage"),
      component: (
        <Language
          {...props}
          eLearningLicenseIds={selectedELs}
          loadCourse={props.loadCourse}
        />
      ),
      hide: !isELearningCourse,
      customClass: "smallCourseDialog",
      disabled: selectedStudents.length === 0,
    },
    {
      icon: <DeleteIcon />,
      text: Translate.get("DeleteStudent"),
      route: false,
      dialogHeader:
        deletableStudents.length === props.numberOfParticipants
          ? Translate.get("DeleteStudentAndBooking")
          : Translate.get("DeleteStudent"),
      customClass: "smallCourseDialog",
      component: (
        <RemoveCourseParticipant
          courseBookingId={props.id}
          students={deletableStudents.map((r) => ({
            courseParticipantId: r.courseParticipantId,
            eLearningLicenseId: r.eLearningLicenseId,
            actorName: r.actorName,
          }))}
          bookedNumberOfStudents={props.numberOfParticipants}
          loadCourse={loadCourse}
          resetSelected={resetSelected}
        />
      ),
      disabled: deletableStudents.length === 0,
      disabledTooltip: Translate.get("DeleteParticipantNotPossible"),
      hide: false,
    },
  ];
  return menudata;
};

function descendingComparator(a, b, orderBy) {
  // - kolla först om någon av actors är dummies
  // isåfall ska de sorteras efter de som är riktiga
  if (b["dummyType"] && a["dummyType"]) {
    if (b["dummyType"] < a["dummyType"]) {
      return -1;
    }
    if (b["dummyType"] > a["dummyType"]) {
      return 1;
    }
    return 0;
  }
  if (b["dummyType"]) {
    return 1;
  }
  if (a["dummyType"]) {
    return -1;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  // //console.log('stableSort', array);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getHeaders(isElearning) {
  if (isElearning) {
    const headCellsElearning = [
      {
        id: "actorName",
        numeric: false,
        disablePadding: true,
        label: Translate.get("ActorName"),
        icon: <PersonIcon />,
      },
      {
        id: "birthDate",
        numeric: false,
        disablePadding: false,
        label: Translate.get("PersonalNo"),
        icon: <FingerprintIcon />,
      },
      {
        id: "gotMail",
        numeric: false,
        disablePadding: false,
        label: Translate.get("InfoShort"),
        icon: <InfoIcon />,
      },
      {
        id: "progressPercent",
        numeric: false,
        disablePadding: false,
        label: Translate.get("Results"),
        icon: <EqualizerIcon />,
      },
      {
        /* id: "finalExamIsLocked", */
        id: "finalExamScore",
        numeric: false,
        disablePadding: false,
        label: Translate.get("FinalTest"),
        icon: <SchoolIcon />,
      },
      {
        id: "eLearningLicenseId",
        numeric: false,
        disablePadding: false,
        label: Translate.get("Password"),
        icon: <VpnKeyIcon />,
      },
      {
        id: "eTruckLanguageCode",
        numeric: false,
        disablePadding: false,
        label: Translate.get("Language"),
        icon: <LanguageIcon />,
      },
      {
        id: "more",
        disablePadding: true,
        numeric: true,
      },
    ];
    return headCellsElearning;
  } else {
    const headCellsNoElearning = [
      {
        id: "actorName",
        numeric: false,
        disablePadding: true,
        label: Translate.get("ActorName"),
        icon: <PersonIcon />,
      },
      {
        id: "birthDate",
        numeric: false,
        disablePadding: false,
        label: Translate.get("PersonalNo"),
        icon: <FingerprintIcon />,
      },
      {
        id: "gotMail",
        numeric: false,
        disablePadding: false,
        label: Translate.get("InfoShort"),
        icon: <InfoIcon />,
      },
      {
        id: "more",
        disablePadding: true,
        numeric: true,
      },
    ];
    return headCellsNoElearning;
  }
}

function preProcessStudent(student, index, courseBookingId) {
  let auth = student.authentication;
  let manAuths = student.manualAuthentications;

  // Här skall all utdata fixas som har felaktigt format från eport
  // //console.log('Userdata', row.actor || 'missing actor');
  if (student.actor) {
    // row.actor.utskick skall innehålla boolean, vet inte vad
    student.actor.utskick = true;
    ////console.log(row.actor.actorId || 'missing actor id');
  } else {
    // Gissa att denna del bara inträffar för E-leaning licenser
    student.actor = {};
    student.actor.doctypes = {};
    student.actor.actorName =
      student.dummyType === 1
        ? Translate.get("AvailableSlot")
        : Translate.get("RemovedUser");
    student.actor.actorEmail = null;
    student.actor.birthDate = "N/A";
    student.actor.gotMail = false;
    student.actor.utskick = false;
  }

  // Vi har inget actorId för users som ännu inte har registrerat sig,
  // men behöver något för att kunna skilja deltagarn-platserna åt
  student.actor.actorOrDummyId = student.actor.actorId ?? `tempId${index}`;

  // Platta till data
  student = Object.assign({}, student.actor, student.eLearningLicense, {
    courseParticipantId: student.courseParticipantId,
    dummyType: student.dummyType,
    actorLicense: student.actorLicense,
  });
  window.global.bookings[courseBookingId][student.actorOrDummyId] = student;

  student.authentication = auth;
  student.manualAuthentications = manAuths;
  return student;
}

function StudentTableHead(props) {
  // //console.log('NU HÄNDER DET', props.eLearningType);
  const headCells = getHeaders(!!props.eLearningType);

  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            sx={{ zIndex: 1 }}
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                "&:hover": {
                  color: "unset",
                },
              }}
            >
              {headCell.icon}
              <Box sx={{ ml: "10px" }}>{headCell.label}</Box>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

StudentTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function StudentTable({
  rows,
  sentCommunications,
  bookingId,
  ...props
}) {
  window.global.bookings[bookingId] = {};
  const [students, setStudents] = useState(
    rows.map((row, index) => preProcessStudent(row, index, bookingId))
  );
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("actorName");
  const [selected, setSelected] = useState([]);
  const [page] = useState(0);
  const [dense] = useState(false);
  const [rowsPerPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [emailDialogProperties, setEmailDialogProperties] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const api = useApi();

  // This one is used to close PersonCrud opened via DotMenu. PersonCrud already has a similar method,
  // but we cannot use it, becuase we need to execute some functions before the reload. And the we cannot
  // refactor easily because it is based on url and navigation in DotMenu rather than react state.
  const closePersonCrudAndReload = () => {
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  useEffect(
    () =>
      setStudents(
        rows.map((row, index) => preProcessStudent(row, index, bookingId))
      ),
    [rows, bookingId]
  );

  const handleRequestSort = (event, property) => {
    // //console.log('handleRequestSort', property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.actorOrDummyId);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (actorOrDummyId) => {
    const selectedIndex = selected.indexOf(actorOrDummyId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, actorOrDummyId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - students.length) : 0;

  const showEmailsSuccessMessage = (message) => {
    const infoMessage = enqueueSnackbar(message, {
      variant: "success",
      autoHideDuration: 6000,
      onClick: () => closeSnackbar(infoMessage),
    });
  };

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1, color: props.hexColor }}>
          <LinearProgress
            color="inherit"
            variant="determinate"
            value={props.value}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  function toggleIsLoading(newValue) {
    setIsLoading(newValue);
  }

  function resetSelected() {
    setSelected([]);
  }

  const languageCodeEnum = {
    0: "English (N/A)",
    9: Translate.get("LanguageCodeEnum_English"),
    12: Translate.get("LanguageCodeEnum_French"),
    20: Translate.get("LanguageCodeEnum_Norwegian"),
    29: Translate.get("LanguageCodeEnum_Swedish"),
  };
  const LangDD = (props) => {
    /*const [lang, setLang] = useState(props.value);

    const handleChange = (event) => {
      setLang(event.target.value);
    };
    return (
      <FormControl fullWidth>
        <Select value={lang} onChange={handleChange}>
          <MenuItem value={9}>English</MenuItem>
          <MenuItem value={12}>French</MenuItem>
          <MenuItem value={20}>Norweigan</MenuItem>
          <MenuItem value={29}>Swedish</MenuItem>
        </Select>
      </FormControl>
    );*/

    return <>{languageCodeEnum[props.value || 0]}</>;
  };

  // If we can we will add the student to the course right away. But if we find the same person as a
  // student of the same course type we will show a dialog asking if we want to move the person from
  // the other course, or add the person as a new student on this course instance as well.
  async function handleSaveStudent(
    oldActorId,
    newActorId,
    courseBookingId,
    courseParticipantId,
    eLearningLicenseId,
    actorOrDummyId
  ) {
    const isUpdatingStudent = oldActorId === newActorId;
    const activeCourseParticipantId =
      !isUpdatingStudent &&
      (await getStudentActiveCourseParticipantId(
        api,
        newActorId,
        props.courseId
      ));
    if (isUpdatingStudent || !activeCourseParticipantId) {
      await postAddOrUpdateStudent(api, {
        courseBookingId,
        courseParticipantId,
        actorId: newActorId,
        eLearningLicense: { eLearningLicenseId },
      });
      closePersonCrudAndReload();
    } else {
      const studentIndex = students.findIndex(
        (s) => s.actorOrDummyId === actorOrDummyId
      );
      const newStudent = {
        activeCourseParticipantId, // This will make the YesOrNoDialog show up
        courseParticipantId,
        eLearningLicenseId,
        actorId: newActorId,
        actorOrDummyId: newActorId,
        doctypes: {},
      };
      const studentsCopy = [...students];
      studentsCopy.splice(studentIndex, 1, newStudent);
      setStudents(studentsCopy);
    }
  }

  async function handleAddAsNewStudent(student) {
    await postAddOrUpdateStudent(api, {
      courseBookingId: bookingId,
      courseParticipantId: student.courseParticipantId,
      actorId: student.actorId,
      eLearningLicense: { eLearningLicenseId: student.eLearningLicenseId },
    });
    closePersonCrudAndReload();
  }

  async function handleMoveExistingStudent(student) {
    await postMoveStudent(
      api,
      bookingId,
      student.activeCourseParticipantId,
      student.courseParticipantId,
      student.eLearningLicenseId,
      props.isPrivateBooking,
      enqueueSnackbar,
      closeSnackbar
    );
    closePersonCrudAndReload();
  }

  function getCourseParticipantAvatar(
    actorId,
    actorName,
    actorEmail,
    birthDate,
    profilePictureUrl,
    courseParticipantId
  ) {
    if (courseParticipantId !== -1) {
      return (
        /*   <ActorAvatar
          link={"/dashboard/person/" + actorId}
          name={actorName}
          email={actorEmail}
          profilePictureUrl={profilePictureUrl + ""}
          align='center'
        /> */
        <TableAvatar
          src={profilePictureUrl + ""}
          //variant="rounded"
          title={actorName}
          subtitle={[
            <a href={"mailto:" + actorEmail}>{actorEmail}</a>,
            birthDate,
          ]}
          href={"/dashboard/person/" + actorId}
        />
      );
    }

    return (
      /*  <ActorAvatar
        name={actorName}
        email={actorEmail}
        profilePictureUrl={profilePictureUrl + ""}
        align='center'
      /> */
      <TableAvatar
        src={profilePictureUrl + ""}
        //variant="rounded"
        title={actorName}
        subtitle={[actorEmail, birthDate]}
      />
    );
  }

  return (
    <>
      {isLoading && <Preloader />}
      <Box sx={{ width: "100%" }}>
        <Paper className="stdBoxShadow" sx={{ width: "100%", mb: 2 }}>
          {/* wip <EnhancedTableToolbar numSelected={selected.length} /> */}
          {/*  <GlobalMoreMenu numSelected={selected.length} selected={selected} courseBookingId={bookingId} /> */}
          {selected.length > 0 && (
            <Toolbar className="studentToolBar">
              <>
                <div>
                  {selected.length} {Translate.get("ePort2Selected")}
                </div>
                <LinkMenu
                  content={getLinkMenu(
                    api,
                    students,
                    {
                      courseId: props.courseId,
                      courseFromDate: props.courseFromDate,
                      id: bookingId,
                      eLearningType: props.eLearningType,
                      companyName: props.companyName,
                      companyActorId: props.companyActorId,
                      contactActor: props.contactActor,
                      selected: selected,
                      allowedLanguages: props.langs,
                      loadCourse: props.loadCourse,
                      numberOfParticipants: props.numberOfParticipants,
                    },
                    toggleIsLoading,
                    props.loadCourse,
                    resetSelected,
                    enqueueSnackbar,
                    closeSnackbar,
                    (emailProperties) => setEmailDialogProperties(emailProperties),
                    () => setEmailDialogProperties(null),
                    showEmailsSuccessMessage
                  )}
                />
              </>
            </Toolbar>
          )}

          <TableContainer>
            <Table
              className="studentTable"
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <StudentTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={students.length}
                eLearningType={props.eLearningType}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(students, getComparator(order, orderBy))
                  ///.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((student, index) => {
                    const isItemSelected = isSelected(student.actorOrDummyId);

                    return (
                      <TableRow hover key={student.actorOrDummyId}>
                        <TableCell
                          className="hideSmall"
                          onClick={(event) =>
                            handleClick(student.actorOrDummyId)
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={student.actorOrDummyId}
                          selected={isItemSelected}
                          padding="checkbox"
                        >
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": student.actorOrDummyId,
                            }}
                          />
                        </TableCell>
                        <TableCell className="userNameEmail" align="left">
                          <div style={{ display: "inline-flex" }}>
                            {getCourseParticipantAvatar(
                              student.actorId,
                              student.actorName,
                              student.actorEmail,
                              student.birthDate,
                              student.profilePictureUrl,
                              student.courseParticipantId
                            )}
                            {student.actorIdOfDuplicate && (
                              <div style={{ marginLeft: "2em" }}>
                                <DuplicateSelfRegActorMerge
                                  actorId1={student.actorIdOfDuplicate}
                                  actorId2={student.actorId}
                                  descriptionText="DuplicateSelfReg"
                                />
                              </div>
                            )}
                          </div>
                        </TableCell>
                        <TableCell align="left">{student.orgNo}</TableCell>
                        <TableCell align="left">
                          <Box>
                            {findSentCommunication(
                              sentCommunications,
                              student.actorId,
                              MediaType.Email,
                              DocumentType.ETruckLicense,
                              CommunicationRole.Student
                            ) && (
                              <Tooltip
                                className="iconbox"
                                title={
                                  findSentCommunication(
                                    sentCommunications,
                                    student.actorId,
                                    MediaType.Email,
                                    DocumentType.ETruckLicense,
                                    CommunicationRole.Student
                                  ).bounced === false
                                    ? `${Translate.get("PasswordSent")} ` +
                                      findSentCommunication(
                                        sentCommunications,
                                        student.actorId,
                                        MediaType.Email,
                                        DocumentType.ETruckLicense,
                                        CommunicationRole.Student
                                      ).sendDate.substring(0, 10)
                                    : Translate.get("EmailBounce")
                                }
                                arrow
                              >
                                <VpnKeyIcon
                                  color={
                                    findSentCommunication(
                                      sentCommunications,
                                      student.actorId,
                                      MediaType.Email,
                                      DocumentType.ETruckLicense,
                                      CommunicationRole.Student
                                    ).bounced === true
                                      ? "error"
                                      : ""
                                  }
                                />
                              </Tooltip>
                            )}
                            {findSentCommunication(
                              sentCommunications,
                              student.actorId,
                              MediaType.Email,
                              DocumentType.ExtraExamInvitation,
                              CommunicationRole.Student
                            ) && (
                              <Tooltip
                                className="iconbox"
                                title={
                                  findSentCommunication(
                                    sentCommunications,
                                    student.actorId,
                                    MediaType.Email,
                                    DocumentType.ExtraExamInvitation,
                                    CommunicationRole.Student
                                  ).bounced === false
                                    ? `${Translate.get(
                                        "ExtraFinalExamInfoSent"
                                      )} ` +
                                      findSentCommunication(
                                        sentCommunications,
                                        student.actorId,
                                        MediaType.Email,
                                        DocumentType.ExtraExamInvitation,
                                        CommunicationRole.Student
                                      ).sendDate.substring(0, 10)
                                    : Translate.get("EmailBounce")
                                }
                                arrow
                              >
                                <NoteAddIcon
                                  color={
                                    findSentCommunication(
                                      sentCommunications,
                                      student.actorId,
                                      MediaType.Email,
                                      DocumentType.ExtraExamInvitation,
                                      CommunicationRole.Student
                                    ).bounced === true
                                      ? "error"
                                      : ""
                                  }
                                />
                              </Tooltip>
                            )}
                            {findSentCommunication(
                              sentCommunications,
                              student.actorId,
                              MediaType.Sms,
                              DocumentType.FreeTextSMS,
                              CommunicationRole.Student
                            ) && (
                              <Tooltip
                                className="iconbox"
                                title={
                                  Translate.get("SmsSent") +
                                  " " +
                                  findSentCommunication(
                                    sentCommunications,
                                    student.actorId,
                                    MediaType.Sms,
                                    DocumentType.FreeTextSMS,
                                    CommunicationRole.Student
                                  ).sendDate.substring(0, 10)
                                }
                                arrow
                              >
                                <SmsIcon />
                              </Tooltip>
                            )}
                            {findSentCommunication(
                              sentCommunications,
                              student.actorId,
                              MediaType.Email,
                              DocumentType.CourseSummoning,
                              CommunicationRole.Student
                            ) && (
                              <Tooltip
                                className="iconbox"
                                title={
                                  findSentCommunication(
                                    sentCommunications,
                                    student.actorId,
                                    MediaType.Email,
                                    DocumentType.CourseSummoning,
                                    CommunicationRole.Student
                                  ).bounced === true
                                    ? Translate.get("EmailBounce")
                                    : Translate.get("CourseInvitationSent") +
                                      " " +
                                      findSentCommunication(
                                        sentCommunications,
                                        student.actorId,
                                        MediaType.Email,
                                        DocumentType.CourseSummoning,
                                        CommunicationRole.Student
                                      ).sendDate.substring(0, 10)
                                }
                                arrow
                              >
                                <AddAlertIcon
                                  color={
                                    findSentCommunication(
                                      sentCommunications,
                                      student.actorId,
                                      MediaType.Email,
                                      DocumentType.CourseSummoning,
                                      CommunicationRole.Student
                                    ).bounced === true
                                      ? "error"
                                      : ""
                                  }
                                />
                              </Tooltip>
                            )}
                            {student.actorLicense && (
                              <StudentLicenseIconbox
                                actorLicense={student.actorLicense}
                              />
                            )}
                            {Authenticated(student)}
                          </Box>
                        </TableCell>
                        {props.eLearningType && (
                          <>
                            <TableCell align="left">
                              <LinearProgressWithLabel
                                variant="determinate"
                                value={student.progressPercent}
                                hexColor={student.progressColor}
                              />
                            </TableCell>
                            <TableCell align="left">
                              {student.finalExamScore !== null ? (
                                <Tooltip
                                  title={
                                    Translate.get("FinalExamDate") +
                                    " " +
                                    tryFormatDate(student.finalExamEndDate)
                                  }
                                  placement="top-start"
                                >
                                  <Box
                                    sx={{
                                      bgcolor: student.finalExamColor,
                                      width:
                                        student.currentFinalExamNumber > 1 &&
                                        !!student.finalExamEndDate
                                          ? 42
                                          : 38,
                                      boxShadow:
                                        student.currentFinalExamNumber > 1 &&
                                        !!student.finalExamEndDate
                                          ? `2px 2px #f05032`
                                          : "none",
                                    }}
                                    className={
                                      "stdInfoRect" +
                                      (student.currentFinalExamNumber > 1 &&
                                      !!student.finalExamEndDate
                                        ? " shadowEdge"
                                        : "")
                                    }
                                  >
                                    {student.finalExamScore}%
                                  </Box>
                                </Tooltip>
                              ) : student.finalExamIsLocked ? (
                                <LockIcon />
                              ) : (
                                <LockOpenIcon />
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {student.eLearningLicenseId}
                            </TableCell>
                            <TableCell align="left">
                              <LangDD value={student.eTruckLanguageCode} />
                            </TableCell>
                          </>
                        )}
                        <TableCell align="right" sx={{ padding: 0 }}>
                          {/* WIP <UserMoreMenu actorData={{actorId:row.actorId,eLearningLicenseId:row.eLearningLicenseId}}/> */}
                          <DotMenu
                            horizontal={true}
                            content={getDotMenu(
                              api,
                              student,
                              {
                                courseId: props.courseId,
                                id: bookingId,
                                eLearningType: props.eLearningType,
                                companyActorId: props.companyActorId,
                                companyName: props.companyName,
                                contactActor: props.contactActor,
                                locked: student.finalExamIsLocked,
                                selectedELearningLanguageCode:
                                  student.eTruckLanguageCode,
                                allowedLanguages: props.langs,
                                isPrivateBooking: props.isPrivateBooking,
                                numberOfParticipants:
                                  props.numberOfParticipants,
                                courseFromDate: props.courseFromDate,
                              },
                              student.finalExamIsLocked,
                              student.finalExamScore,
                              student.progressPercent,
                              toggleIsLoading,
                              props.loadCourse,
                              resetSelected,
                              enqueueSnackbar,
                              closeSnackbar,
                              handleSaveStudent,
                              closePersonCrudAndReload,
                              (emailProperties) => setEmailDialogProperties(emailProperties),
                              () => setEmailDialogProperties(null),
                              showEmailsSuccessMessage
                            )}
                          />
                          {/* <UserMoreMenu actorData={row} courseBookingId={bookingId}/> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/*  <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        </Paper>
        {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
        <YesOrNoDialog
          open={students.some((s) => s.activeCourseParticipantId)}
          title={Translate.get("MoveStudentTitle")}
          text={Translate.get("MoveStudentQuestion")}
          onNo={async () => {
            const student = students.find((s) => s.activeCourseParticipantId);
            await handleAddAsNewStudent(student);
          }}
          onYes={async () => {
            const student = students.find((s) => s.activeCourseParticipantId);
            await handleMoveExistingStudent(student);
          }}
          noText={Translate.get("MoveStudentNo")}
          yesText={Translate.get("MoveStudentYes")}
          onCancel={() =>
            setStudents(
              students.map((s) => ({ ...s, activeCourseParticipantId: null }))
            )
          }
        />
      </Box>
      {emailDialogProperties && <GroupEmailDialog {...emailDialogProperties} />}
    </>
  );
}
