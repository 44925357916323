import { CircularProgress, Box } from "@mui/material";

export function CircularProgressWithIcon({dataNum, color, icon, dataStr, text}) {
    /*
    Har påbörjat utbildning people 
    Medelresultat övningsuppgifter equalizer
    Har påbörjat slutprovet people
    props result,text,icon
    
    
    */
    //let result= props.value;
    let result = 0;
    if (!isNaN(dataNum)) {
      result = Math.round(dataNum * 100);
    }
    return (
      <div>
        <Box sx={{ position: "relative", display: "inline-flex" }}>
          <CircularProgress
            className="customCP"
            variant="determinate"
            sx={{
              color: "silver",
            }}
            //size={96}
            thickness={2}
            value={100}
          />
          <CircularProgress
            className="customCP"
            variant="determinate"
            //size={96}
            thickness={2}
            value={result}
            color="warning"
            sx={{
              position: "absolute",
              left: 0,
              color: color,
            }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {icon}
          </Box>
        </Box>
        <Box>
          <h1>{dataStr}</h1>
          { text}
        </Box>
      </div>
    );
  }