/* xeslint-disable */
import { useState, useEffect } from "react";
import { Box, Card, Grid, Tooltip, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import PlaceIcon from "@mui/icons-material/Place";
import EmailIcon from "@mui/icons-material/Email";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CourseGrid from "../../components/courses/CourseGrid";
import Preloader from "../../components/Preloader";
import { useApi } from "../../utils/Api";
import ActorCardAvatar from "./ActorCardAvatar";
import Contacts from "./Contacts";
import Persons from "./Persons";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DotMenu from "../../components/DotMenu";
import Translate from "../../utils/Translate";
import { formatDate } from "../../utils/dateTimeFormat";
import SpeedMenu from "../SpeedMenu";
import ConnectPersonAndCompanyDialog from "./ConnectPersonAndCompanyDialog";
import CompanyCrud from "./crud/CompanyCrud";
import YesOrNoDialog from "../YesOrNoDialog";
import { useSnackbar } from "notistack";
import BusinessIcon from "@mui/icons-material/Business";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import ImportActorsDialog from "./dialogs/ImportActorsDialog";
import { hasAccess, accessKeys, hasSomeAccess } from "../../utils/userAccess";
import NotesIcon from "@mui/icons-material/Notes";
import HomeIcon from "@mui/icons-material/Home";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommentList from "./dialogs/CommentList";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import NumbersIcon from "@mui/icons-material/Numbers";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import DocUpload from "./dialogs/DocUpload";
import AttachmentIcon from "@mui/icons-material/Attachment";
import ComputerIcon from "@mui/icons-material/Computer";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import download from "downloadjs";
import PasswordBalance from "../_dashboard/app/PasswordBalance";
import OrderFormDialog from "../orders/OrderFormDialog";
import OrdersGrid from "../orders/OrdersGrid";
import OrderFormBase from "../orders/OrderFormBase";
import { formatTime } from "../../utils/dateTimeFormat";
import AgreementsGrid from "../agreements/AgreementsGrid";
import AgreementForm from "../agreements/AgreementForm";
import EPortLicenseDialog from "./dialogs/EPortLicenseDialog";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import IframeDialog from "./dialogs/IframeDialog";
import EPortProfilesDialog from "./dialogs/EPortProfilesDialog";

const getDotMenu = ({
  company,
  onDelete,
  onCreateDataId,
  onToggleAutoOrder,
  onCreateTemporaryUser,
  onClickActorPermit,
}) => {
  const menudata = [
    {
      icon: <EditIcon />,
      text: Translate.get("EditCompany"),
      route: false,
      component: (
        <CompanyCrud
          actorId={company.actorId}
          isEditOnly
          isMatchingActorsDisabled
        />
      ),
    },
    {
      icon: <NotesIcon />,
      text: Translate.get("Notes"),
      dialogHeader: Translate.get("Notes"),
      customClass: "mediumCourseDialog",
      route: false,
      component: <CommentList actorId={company.actorId} />,
    },
    {
      icon: <AttachmentIcon />,
      text: Translate.get("Document"),
      //dialogHeader: Translate.get('Document'),
      customClass: "mediumCourseDialog",
      route: false,
      component: (
        <DocUpload
          actorId={company.actorId}
          files={company.files}
          type="actor"
        />
      ),
      hide: !hasSomeAccess(
        accessKeys.standardPackage,
        accessKeys.advancedPackage
      ),
    },
    {
      icon: <SupportAgentIcon />,
      text: Translate.get("CreateTemporaryAdminUser"),
      route: false,
      hide: !hasAccess(accessKeys.licenseIssuer),
      disabled: !company.externalDataId || company.isOwnCompanyActor,
      onClick: onCreateTemporaryUser,
    },
    // MA-system shows a dialog before creating the DataId,
    // and can edit it after it has been created too
    ...(hasAccess(accessKeys.isMASystem)
      ? [
          {
            icon: <ComputerIcon />,
            customClass: "mediumCourseDialog",
            text: company.externalDataId
              ? Translate.get("EditEPortLicense")
              : Translate.get("CreateNewCompanyCode"),
            dialogHeader: company.externalDataId
              ? Translate.get("EditEPortLicense")
              : Translate.get("CreateNewCompanyCode"),
            component: (
              <EPortLicenseDialog
                companyActorId={company.actorId}
                externalDataId={company.externalDataId}
                defaults={{ companyName: company.actorName }}
              />
            ),
            route: false,
            disabled: company.externalDataId
              ? false
              : company.isOwnCompanyActor,
            hide: !hasAccess(accessKeys.licenseIssuer),
          },
          {
            icon: <ControlPointDuplicateIcon />,
            customClass: "mediumCourseDialog",
            text: company.hasCourseBookingIframe
              ? `${Translate.get("Alter")}: ${Translate.get(
                  "IframeCoursebooking"
                )}`
              : `${Translate.get("Add")}: ${Translate.get(
                  "IframeCoursebooking"
                )}`,
            dialogHeader: company.hasCourseBookingIframe
              ? `${Translate.get("Alter")}: ${Translate.get(
                  "IframeCoursebooking"
                )}`
              : `${Translate.get("Add")}: ${Translate.get(
                  "IframeCoursebooking"
                )}`,
            component: (
              <IframeDialog
                companyActorId={company.actorId}
                hasCourseBookingIframe={company.hasCourseBookingIframe}
                defaults={{
                  courseBookingEmail: company.actorEmail,
                  courseBookingEmailName: company.actorName,
                }}
              />
            ),
            route: false,
            hide:
              !hasAccess(accessKeys.licenseIssuer) || !company.externalDataId,
          },
          {
            icon: <ControlPointDuplicateIcon />,
            customClass: "mediumCourseDialog",
            text: company.hasActorPermit
              ? `${Translate.get("Delete")}: ${Translate.get("ActorPermit")}`
              : `${Translate.get("Add")}: ${Translate.get("ActorPermit")}`,
            dialogHeader: company.hasActorPermit
              ? `${Translate.get("Delete")}: ${Translate.get("ActorPermit")}`
              : `${Translate.get("Add")}: ${Translate.get("ActorPermit")}`,
            onClick: onClickActorPermit,
            route: false,
            hide:
              !hasAccess(accessKeys.licenseIssuer) || !company.externalDataId,
          },
          {
            icon: <GroupIcon />,
            customClass: "mediumCourseDialog",
            text: Translate.get("EPortProfiles"),
            dialogHeader: Translate.get("EPortProfiles"),
            component: (
              <EPortProfilesDialog externalDataId={company.externalDataId} />
            ),
            route: false,
            hide:
              !hasAccess(accessKeys.licenseIssuer) || !company.externalDataId,
          },
        ]
      : [
          {
            icon: <ComputerIcon />,
            text: Translate.get("CreateNewCompanyCode"),
            route: false,
            hide: !hasAccess(accessKeys.licenseIssuer),
            disabled: !!company.externalDataId || company.isOwnCompanyActor,
            onClick: onCreateDataId,
          },
        ]),
    {
      icon: <AutoModeIcon />,
      text: company.isAutoOrderAllowed
        ? Translate.get("DisableAutoOrder")
        : Translate.get("EnableAutoOrder"),
      route: false,
      hide: !hasAccess(accessKeys.licenseIssuer),
      disabled: !company.externalDataId,
      onClick: onToggleAutoOrder,
    },
    {
      icon: <DeleteIcon />,
      text: Translate.get("DeleteCompany"),
      onClick: onDelete,
      disabled:
        !!company.externalDataId ||
        company.isOwnCompanyActor ||
        (company.courses && company.courses.length > 0) ||
        (company.contacts && company.contacts.length > 0) ||
        (company.students && company.students.length > 0),
    },
  ];
  return menudata;
};

const getPersonDotMenuFunc = ({ onDeleteRelation }) => {
  return (personActorId) => {
    const menudata = [
      {
        icon: <PersonIcon />,
        text: Translate.get("GoToPerson"),
        route: `/dashboard/person/${personActorId}`,
      },

      {
        icon: <ClearIcon />,
        text: Translate.get("DeleteConnection"),
        onClick: () => onDeleteRelation(personActorId),
      },
    ];
    return menudata;
  };
};

const Comp = (props) => {
  const [showDeleteActorPermit, setShowDeleteActorPermit] = useState(false);
  const smallScreen = useMediaQuery("(max-width:900px)");
  const [id] = useState(parseInt(useParams().id));
  //const id = props.id;
  const [company, setCompany] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [showDeleteQuestion, setShowDeleteQuestion] = useState(false);
  const [showCreateDataIdQuestion, setShowCreateDataIdQuestion] =
    useState(false);
  const [relationChildActorIdToDelete, setRelationChildActorIdToDelete] =
    useState(null);
  const [showOrderForm, setShowOrderForm] = useState(false);
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const navigate = useNavigate();

  //actors/{actorId}/as-client/  eller as-person
  useEffect(async () => {
    await getAsClient();
  }, [id]);

  async function getAsClient() {
    setIsLoading(true);

    let res = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/${id}/as-client`,
      false,
      "GET"
    );

    if (res.isSuccessful) {
      setCompany(res);
      setDataFetched(true);
    }

    setIsLoading(false);
  }

  const speedMenuActions = [
    ...(hasSomeAccess(accessKeys.standardPackage, accessKeys.advancedPackage)
      ? [
          {
            icon: <UploadIcon />,
            text: Translate.get("ImportStudents"),
            dialogHeader: Translate.get("ImportStudents"),
            component: <ImportActorsDialog companyActorId={id} />,
          },
        ]
      : []),
    {
      icon: <AddIcon />,
      text: Translate.get("AddAPerson"), //AddPersonalConnection
      component: (
        <ConnectPersonAndCompanyDialog
          companyActorId={id}
          companyExternalDataId={company.externalDataId}
        />
      ),
    },
    ...(hasAccess(accessKeys.isMASystem) && company.externalDataId
      ? [
          {
            icon: <AddIcon />,
            text: Translate.get("CreateServiceAgreement"),
            customClass: "mediumCourseDialog",
            component: <AgreementForm companyActorId={id} />,
          },
        ]
      : []),
    ...(hasAccess(accessKeys.isMASystem) || (hasAccess(accessKeys.licenseIssuer) && company.externalDataId)
      ? [
          {
            icon: <AddIcon />,
            text: Translate.get("CreateOrder"),
            customClass: "mediumCourseDialog",
            customClass: hasAccess(accessKeys.isMASystem)
              ? "bigCourseDialog"
              : "mediumCourseDialog",
            component: <OrderFormBase companyActorId={id} />,
          },
        ]
      : []),
  ];

  async function handleSetAllowAutoOrder() {
    setDataFetched(false);
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/elearning/autoorder/config`,
      {
        dataId: company.externalDataId,
        isAutoOrderAllowed: !company.isAutoOrderAllowed,
      },
      "POST"
    );
    setDataFetched(true);
    if (response && response.isSuccessful) {
      navigate(0);
    }
  }

  async function toggleHasActorPermit() {
    setDataFetched(false);
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport/${company.actorId}/actor-permit`,
      true,
      company.hasActorPermit ? "DELETE" : "POST"
    );
    setDataFetched(true);
    if (response && response.isSuccessful) {
      setCompany((prev) => ({ ...prev, hasActorPermit: !prev.hasActorPermit }));
      const successSnackbar = enqueueSnackbar(
        Translate.get("EPortLicenseUpdated"),
        {
          variant: "success",
          autoHideDuration: 10000,
          onClick: () => closeSnackbar(successSnackbar),
        }
      );
    }
    setShowDeleteActorPermit(false);
  }

  async function handleCreateTemporaryUser() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport/users/temporary?externalDataId=${company.externalDataId}`,
      true,
      "POST"
    );

    if (response && response.isSuccessful) {
      const successMessage = `${Translate.get(
        "TemporaryAdminUserValidUntil"
      )}: ${formatTime(response.validUntil)}`;
      const successSnackbar = enqueueSnackbar(successMessage, {
        variant: "success",
        autoHideDuration: 10000,
        onClick: () => closeSnackbar(successSnackbar),
      });
    }
  }

  async function handleCreateDataId() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport`,
      { actorId: company.actorId },
      "POST"
    );

    if (response && response.isSuccessful) {
      navigate(0);
    }
  }

  async function handleDeleteCompany() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/delete`,
      {
        actorId: company.actorId,
      }
    );
    setShowDeleteQuestion(false);
    if (response && response.isSuccessful) {
      window.history.back();
    }
  }

  async function postDeleteActorRelation(
    parentActorId,
    childActorId,
    actorTypeId
  ) {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/relations/delete`,
      { parentActorId, childActorId, actorTypeId }
    );
    return response && response.isSuccessful;
  }

  async function handleDeleteStudentRelation() {
    const relationDeleted = await postDeleteActorRelation(
      company.actorId,
      relationChildActorIdToDelete,
      4
    );
    setRelationChildActorIdToDelete(null);
    if (relationDeleted) {
      navigate(0);
    }
  }
  const downLoader = (documentId) => {
    //Denna borde kanske vara egen js,
    //gissar att det alltid är så här man vill göra med av användare uppladdade filer.
    //och kanse även andra filer som sparas i Azure blob storage
    //I sådana fall med full fetch path som parameter istället för documentId
    (async () => {
      const data = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/file/get/${documentId}`
      );
      //console.log("download", data);
      (async () => {
        const res = await fetch(data.sasURI);
        const contentType = res.headers.get("Content-Type");
        const raw = await res.blob();
        //console.log("meta", data.filename, contentType);
        download(raw, data.filename, contentType);
      })();
    })();
  };
  if (dataFetched) {
    return (
      <>
        {isLoading && <Preloader />}

        {showOrderForm && (
          <OrderFormDialog
            companyActorId={company.actorId}
            onClose={() => setShowOrderForm(false)}
          />
        )}
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <div className="stdFlexRowTop">
              <h2>{Translate.get("Company")}</h2>
            </div>
            <Card sx={{ padding: "48px" }}>
              <div className="bigActorAvtar">
                <ActorCardAvatar
                  actorName={company.actorName}
                  profilePictureUrl={company.profilePictureUrl}
                  variant="rounded"
                  avatarWidth={80}
                  avatarHeight="auto"
                />
                <h1>{company.actorName}</h1>
                <div className="stdMenuPos">
                  <DotMenu
                    content={getDotMenu({
                      company,
                      onDelete: () => setShowDeleteQuestion(true),
                      onCreateDataId: () => setShowCreateDataIdQuestion(true),
                      onToggleAutoOrder: handleSetAllowAutoOrder,
                      onCreateTemporaryUser: handleCreateTemporaryUser,
                      onClickActorPermit: () =>
                        company.hasActorPermit
                          ? setShowDeleteActorPermit(true)
                          : toggleHasActorPermit(),
                    })}
                  />
                </div>
              </div>

              {company.orgNo && (
                <div className="stdListType">
                  <NumbersIcon />
                  <div>{company.orgNo}</div>
                </div>
              )}
              {company.actorEmail && (
                <div className="stdListType">
                  <EmailIcon />
                  <div>
                    <a
                      className="stdMailToLink"
                      href={"mailto:" + company.actorEmail}
                    >
                      {company.actorEmail}{" "}
                    </a>
                  </div>
                </div>
              )}

              {(company.mobilePhone?.actorPhoneNumber ||
                company.businessPhone?.actorPhoneNumber) && (
                <div className="stdListType">
                  <LocalPhoneIcon />
                  <div>
                    {company.businessPhone?.actorPhoneNumber && (
                      <>
                        {company.businessPhone.actorPhoneNumber} <br />
                      </>
                    )}
                    {company.mobilePhone?.actorPhoneNumber && (
                      <>{company.mobilePhone.actorPhoneNumber} </>
                    )}
                  </div>
                </div>
              )}
              {(company.invoiceAddress?.actorAddressZipCode ||
                company.invoiceAddress?.actorAddressCity ||
                company.invoiceAddress?.actorAddressStreet2) && (
                <div className="stdListType">
                  <BusinessIcon />
                  <div>
                    <div className="smallHeader" style={{ marginTop: 0 }}>
                      {Translate.getActorAddressType(1)}
                    </div>
                    {company.invoiceAddress?.actorAddressStreet2 && (
                      <>
                        {company.invoiceAddress?.actorAddressStreet2} <br />
                      </>
                    )}
                    {(company.invoiceAddress?.actorAddressZipCode ||
                      company.invoiceAddress?.actorAddressCity) && (
                      <>
                        {company.invoiceAddress?.actorAddressZipCode}{" "}
                        {company.invoiceAddress?.actorAddressCity} <br />
                      </>
                    )}
                    {company.invoiceAddress?.actorAddressCountry && (
                      <>{company.invoiceAddress?.actorAddressCountry}</>
                    )}
                  </div>
                </div>
              )}
              {(company.postalAddress?.actorAddressZipCode ||
                company.postalAddress?.actorAddressCity ||
                company.postalAddress?.actorAddressStreet2) && (
                <div className="stdListType">
                  <PlaceIcon />
                  <div>
                    <div className="smallHeader" style={{ marginTop: 0 }}>
                      {Translate.getActorAddressType(3)}
                    </div>
                    {company.postalAddress?.actorAddressStreet2 && (
                      <>
                        {company.postalAddress?.actorAddressStreet2} <br />
                      </>
                    )}
                    {(company.postalAddress?.actorAddressZipCode ||
                      company.postalAddress?.actorAddressCity) && (
                      <>
                        {company.postalAddress?.actorAddressZipCode}{" "}
                        {company.postalAddress?.actorAddressCity} <br />
                      </>
                    )}
                    {company.postalAddress?.actorAddressCountry && (
                      <>{company.postalAddress?.actorAddressCountry}</>
                    )}
                  </div>
                </div>
              )}
              {company.webSite && (
                <div className="stdListType">
                  <HomeIcon />
                  <div>{company.webSite}</div>
                </div>
              )}
              {hasAccess(accessKeys.licenseIssuer) &&
                company.externalDataId !== 9999 &&
                company.externalDataId && (
                  <div className="stdListType">
                    <ComputerIcon />
                    <div>{company.externalDataId}</div>
                  </div>
                )}
              {hasAccess(accessKeys.licenseIssuer) &&
                hasAccess(accessKeys.isMASystem) &&
                company.ePortLicense && (
                  <div className="stdListType">
                    <VerifiedUserIcon />
                    <div>{`${company.ePortLicense.part.description}${
                      company.ePortLicense.terminateDate
                        ? ` (${formatDate(company.ePortLicense.terminateDate)})`
                        : ""
                    }`}</div>
                  </div>
                )}
              {hasAccess(accessKeys.licenseIssuer) &&
                company.externalDataId !== 9999 &&
                company.externalDataId && (
                  <div className="stdListType">
                    <Tooltip title={Translate.get("AutoOrder")}>
                      <AutoModeIcon />
                    </Tooltip>
                    <div>
                      {company.isAutoOrderAllowed
                        ? Translate.get("Enabled")
                        : Translate.get("Disabled")}
                    </div>
                  </div>
                )}
              {company.observationNote && (
                <div className="stdListType">
                  <ErrorOutlineIcon />
                  <div>{company.observationNote}</div>
                </div>
              )}
              {/* ersätt observationNote med riktig notes array + fake array med detsamma */}
              {Boolean(company.actorFreeText.length) && (
                <Accordion className="stdAccordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="stdFlexLeft16">
                      <NotesIcon />
                      {Translate.get("Notes")} ({company.actorFreeText.length})
                    </div>
                  </AccordionSummary>
                  {company.actorFreeText.map((row, index) => (
                    <Tooltip
                      title={`${formatDate(row.updDate ?? row.regDate)} / ${
                        row.authorName
                      }`}
                      key={index}
                    >
                      <AccordionDetails>{row.textNote}</AccordionDetails>
                    </Tooltip>
                  ))}
                </Accordion>
              )}
              {Boolean(company.files.length) && (
                <Accordion className="stdAccordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="stdFlexLeft16">
                      <AttachmentIcon />
                      {Translate.get("Document")} ({company.files.length})
                    </div>
                  </AccordionSummary>
                  {company.files.map((row, index) => (
                    <AccordionDetails key={index}>
                      <div
                        onClick={() => downLoader(row.blobDocumentId)}
                        className="stdFlexLeft selectable"
                      >
                        <AttachmentIcon />
                        <div>{row.originalFileName}</div>
                      </div>
                    </AccordionDetails>
                  ))}
                </Accordion>
              )}
            </Card>
            <div className="stdFlexRow">
              <h2>{Translate.get("Contacts")}</h2>
            </div>
            <Card>
              <Contacts
                companyActorId={id}
                externalDataId={company.externalDataId}
                contacts={company.contacts}
                smallScreen={true}
              />
            </Card>
            {company.externalDataId && (
              <>
                <div className="stdFlexRow">
                  <h2>{Translate.get("Resources")}</h2>
                </div>
                <PasswordBalance
                  data={company.eLearningLicenseBalances.map((el) => ({
                    name: Translate.getELearningType(el.eLearningType),
                    value: el.numberOfFreeLicenses,
                    color: el.webColor,
                    externalDataId: company.externalDataId,
                    eLearningType: el.eLearningType,
                  }))}
                  onOrder={() => setShowOrderForm(true)}
                  label={Translate.get("CreateOrder")}
                  getAsClient={getAsClient}
                  showRecallDotMenu={true}
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            {hasAccess(accessKeys.courseAdmin) && (
              <>
                <div className="stdFlexRowTop">
                  <h2>{Translate.get("CourseBookingsList")}</h2>
                </div>
                <Card>
                  {dataFetched && (
                    <CourseGrid
                      rows={company.courses}
                      smallScreen={smallScreen}
                      hideFilter={true}
                      hideDotMenu
                      loadFilters={false}
                    />
                  )}
                </Card>
              </>
            )}
            <div
              className={
                hasAccess(accessKeys.courseAdmin)
                  ? "stdFlexRow"
                  : "stdFlexRowTop"
              }
            >
              <h2>{Translate.get("Students")}</h2>
            </div>
            <Card>
              {dataFetched && (
                <Persons
                  rows={company.students}
                  cardView={true}
                  smallScreen={smallScreen}
                  hideFilter={smallScreen}
                  getDotMenuActions={getPersonDotMenuFunc({
                    onDeleteRelation: (personActorId) =>
                      setRelationChildActorIdToDelete(personActorId),
                  })}
                />
              )}
            </Card>
            {hasAccess(accessKeys.licenseIssuer) 
              && (!!company.externalDataId || hasAccess(accessKeys.isMASystem)) && (
                <>
                  <div className="stdFlexRow">
                    <h2>{Translate.get("Orders")}</h2>
                  </div>
                  <Card>
                    <OrdersGrid actorId={company.actorId} />
                  </Card>
                </>
              )}
            {hasAccess(accessKeys.isMASystem) && !!company.externalDataId && (
              <>
                <div className="stdFlexRow">
                  <h2>{Translate.get("ServiceAgreementsTitle")}</h2>
                </div>
                <Card>
                  <AgreementsGrid companyActorId={company.actorId} />
                </Card>
              </>
            )}
          </Grid>
        </Grid>
        <SpeedMenu content={speedMenuActions} />
        <YesOrNoDialog
          open={showDeleteQuestion}
          title={Translate.get("DeleteCompany")}
          text={Translate.get("SureRemoveCompany")}
          onNo={() => setShowDeleteQuestion(false)}
          onYes={handleDeleteCompany}
          noText={Translate.get("Cancel")}
          yesText={Translate.get("Delete")}
        />
        <YesOrNoDialog
          open={!!relationChildActorIdToDelete}
          title={Translate.get("DeleteConnection")}
          text={Translate.get("SureRemoveRelation")}
          onNo={() => setRelationChildActorIdToDelete(null)}
          onYes={handleDeleteStudentRelation}
          noText={Translate.get("Cancel")}
          yesText={Translate.get("Delete")}
        />
        <YesOrNoDialog
          open={showCreateDataIdQuestion}
          title={Translate.get("CreateNewCompanyCode")}
          text={Translate.get("AreYoueSureCompanyCode")}
          onNo={() => setShowCreateDataIdQuestion(false)}
          onYes={handleCreateDataId}
          noText={Translate.get("Cancel")}
          yesText={Translate.get("Create")}
        />
        <YesOrNoDialog
          open={showDeleteActorPermit}
          text={Translate.get("SureDeleteEPortLicenseLine")}
          onYes={toggleHasActorPermit}
          onNo={() => setShowDeleteActorPermit(false)}
        />
      </>
    );
  }
  return <Preloader />;
};
export default Comp;
