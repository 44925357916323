import { Box, Tooltip } from "@mui/material";
import Translate from "./Translate";
import {
  Done,
  HourglassBottom,
  HourglassTop,
  Inventory,
  LocalShipping,
  PriceCheck,
  RequestQuote,
  School,
  Warning,
} from "@mui/icons-material";
import { isAutoPickPartType, isFreightPartType } from "./part";

export const orderStatus = {
  canceled: 0,
  missingInfo: 9,
  registered: 10,
  registeredAutoOrder: 11,
  ordered: 20,
  underPicking: 30,
  waitingForCourseInvoice: 35,
  picked: 40,
  pickedAutoOrder: 41,
  waitingForInvoice: 50,
  sentToERP: 60,
  invoicedInERP: 65,
  partlyPaid: 70,
  paid: 80,
  finished: 90,
};

export function getOrderIconbox(status, overrideColor, isMini) {
  let className;
  let title;
  let icon;
  if (status < orderStatus.registered) {
    className = `iconbox ${overrideColor ?? "warning"} ${isMini && "mini"}`;
    title = Translate.getOrderStatus(status);
    icon = <Warning />;
  } else if (status === orderStatus.waitingForCourseInvoice) {
    className = `iconbox ${overrideColor ?? "blue"} ${isMini && "mini"}`;
    title = Translate.getOrderStatus(orderStatus.waitingForCourseInvoice);
    icon = <School />;
  } else if (status === orderStatus.underPicking) {
    className = `iconbox ${overrideColor ?? "blue"} ${isMini && "mini"}`;
    title = Translate.getOrderStatus(orderStatus.underPicking);
    icon = <Inventory />;
  } else if (status === orderStatus.picked) {
    className = `iconbox ${overrideColor ?? "blue"} ${isMini && "mini"}`;
    title = Translate.getOrderStatus(orderStatus.picked);
    icon = <LocalShipping />;
  } else if (status === orderStatus.pickedAutoOrder) {
    className = `iconbox ${overrideColor ?? "blue"} ${isMini && "mini"}`;
    title = Translate.getOrderStatus(orderStatus.pickedAutoOrder);
    icon = <LocalShipping />;
  } else if (status === orderStatus.waitingForInvoice) {
    className = `iconbox ${overrideColor ?? "blue"} ${isMini && "mini"}`;
    title = Translate.getOrderStatus(orderStatus.waitingForInvoice);
    icon = <HourglassTop />;
  } else if (status === orderStatus.sentToERP) {
    className = `iconbox ${overrideColor ?? "blue"} ${isMini && "mini"}`;
    title = Translate.getOrderStatus(orderStatus.sentToERP);
    icon = <HourglassBottom />;
  } else if (status === orderStatus.invoicedInERP) {
    className = `iconbox ${overrideColor ?? "blue"} ${isMini && "mini"}`;
    title = Translate.getOrderStatus(orderStatus.invoicedInERP);
    icon = <RequestQuote />;
  } else if (status === orderStatus.partlyPaid) {
    className = `iconbox ${overrideColor ?? "blue"} ${isMini && "mini"}`;
    title = Translate.getOrderStatus(orderStatus.partlyPaid);
    icon = <RequestQuote />;
  } else if (status === orderStatus.paid) {
    className = `iconbox ${overrideColor ?? "success"} ${isMini && "mini"}`;
    title = Translate.getOrderStatus(orderStatus.paid);
    icon = <PriceCheck />;
  } else if (status === orderStatus.finished) {
    className = `iconbox ${overrideColor ?? "success"} ${isMini && "mini"}`;
    title = Translate.getOrderStatus(orderStatus.finished);
    icon = <Done />;
  } else {
  }

  if (className) {
    return (
      <Tooltip arrow className={className} title={title}>
        {icon}
      </Tooltip>
    );
  } else {
    return undefined;
  }
}

export function canUpdateOrderLineQty(orderLine) {
  if (!orderLine.status || !orderLine.part?.partType) {
    // Could probably be considered an error. But false will suffice
    return false;
  }

  const isFreight = isFreightPartType(orderLine.part.partType);
  if (isFreight) {
    return orderLine.status <= orderStatus.waitingForInvoice;
  }

  const isAutoPicked = isAutoPickPartType(orderLine.part.partType);
  if (isAutoPicked && orderLine.status <= orderStatus.picked) {
    return true;
  } else if (!isAutoPicked && orderLine.status < orderStatus.picked) {
    return true;
  }

  return false;
}

export function canUpdateOrderLineNotQty(orderLine) {
  if (!orderLine.status || !orderLine.part?.partType) {
    // Could probably be considered an error. But false will suffice
    return false;
  }

  return orderLine.status <= orderStatus.waitingForInvoice;
}

export function canUpdateOrderLine(orderLine) {
  return (
    canUpdateOrderLineNotQty(orderLine) || canUpdateOrderLineQty(orderLine)
  );
}
