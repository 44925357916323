import {
  Autocomplete,
  Box,
  Button,
  DialogTitle,
  TextField,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
//import DialogContentText from '@mui/material/DialogContentText';
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useMemo, useEffect, useCallback } from "react";
import { useApi } from "../../../utils/Api";
import Preloader from "../../Preloader";
import EPortAvatar from "../../EPortAvatar";
import { DatePicker } from "@mui/x-date-pickers";
import {
  getCurrentDateMask,
  getCurrentInputFormat,
  toSameDateTimeUTC,
} from "../../../utils/dateTimeFormat";
import Translate from "../../../utils/Translate";
import * as Yup from "yup";
import debounce from "lodash.debounce";
import YesOrNoDialog from "../../YesOrNoDialog";
import ActorAvatar from "../../ActorAvatar";
import { useSnackbar } from "notistack";
import AutocompleteSearchField from "../../AutocompleteSearchField";
import ActorAutocompleteOption from "../../ActorAutocompleteOption";
import { actorSearch, actorPersonTypes } from "../../../utils/actorSearch";
import {
  tryCreatePersonnummerWithSettings,
  getPersonnummerPlaceholderText,
} from "../../../utils/personnummer";
import { LoadingButton } from "@mui/lab";
import { hasAccess, accessKeys } from "../../../utils/userAccess";

import { validatePhoneNumber } from "../../../utils/phoneNumber";

// Get the text for submit button when saving a new actor
function getAddActorSubmitText(actorRole) {
  switch (actorRole) {
    case "teacher":
      return Translate.get("AddInstructor");
    case "student":
      return Translate.get("AddStudent");
    case "contact":
      return Translate.get("AddContact");
    case "permit-holder":
      return Translate.get("AddPerson2"); // Lägg till innehavare
    default:
      return Translate.get("AddPerson");
  }
}

// Get the text for submit button when saving an existing actor
function getUpdateActorSubmitText(
  actorRole,
  isEditOnly,
  hasSwitchedActor,
  actorId
) {
  // isEditOnly determines whether we're only editing an actor or also selecting an actor for a purpose.
  // For example, consider the difference between simply editing an instructor via the list in settings,
  // and editing an instructor via a specific course. In the latter case we want a change of actor in
  // this dialog to change the instructor for the course too, and make that effect clear to the user.
  if (isEditOnly || (actorId > 0 && !hasSwitchedActor)) {
    return Translate.get("Save");
  } else {
    switch (actorRole) {
      case "teacher":
        return Translate.get("SelectInstructor");
      case "student":
        return Translate.get("SelectStudent");
      case "contact":
        return Translate.get("SelectContact");
      case "permit-holder":
        return Translate.get("SelectHolder"); // Välj innehavare
      default:
        return Translate.get("SelectPerson");
    }
  }
}

const PersonCrud = ({
  actorId,
  parentActorId,
  name,
  actorRole,
  isEditOnly,
  isMatchingActorsDisabled,
  onActorSaved,
  onClose,
  onCancel,
  hideHeader,
  parentZIndexOverride,
}) => {
  if (
    (actorRole === "student" ||
      actorRole === "contact" ||
      actorRole == "permit-holder") &&
    (!parentActorId || parentActorId <= 0)
  ) {
    throw new Error("Missing parentActorId");
  }

  const [originalEmail, setOriginalEmail] = useState();
  const [hasEmailChanged, setHasEmailChanged] = useState(false);
  const [originalPersonalNo, setOriginalPersonalNo] = useState();
  const [hasPersonalNoChanged, setHasPersonalNoChanged] = useState(false);
  const [matchingActor, setMatchingActor] = useState(null);
  const [submitText, setSubmitText] = useState("OK");
  const [headerText, setHeaderText] = useState(
    Translate.get("EditPersonalInfo")
  );
  const [formData, setFormData] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [errorMess, setErrorMess] = useState({});
  const [isBlankForm, setIsBlankForm] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [imageResponse, setImageResponse] = useState(null);
  const [showActorWithUserEmailQuestion, setShowActorWithUserEmailQuestion] =
    useState(false);
  const [showActorWithUserOrgNoQuestion, setShowActorWithUserOrgNoQuestion] =
    useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [personnummerPlaceholderText, setPersonnummerPlaceholderText] =
    useState("");
  const [invalidOrgNoMessage, setInvalidOrgNoMessage] = useState("");
  const [oldInvalidPersonalNumber, setOldInvalidPersonalNumber] =
    useState(null);
  const [countries, setCountries] = useState([]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();

  const schema = Yup.object({
    required: Yup.string()
      .min(1, Translate.get("ePortNameRequired"))
      .optional(),
    email: Yup.string().email(Translate.get("InvalidEmail")).optional(),
    emailRequired: Yup.string().email(Translate.get("InvalidEmail")).optional(),

    mobilePhone: Yup.string()
      .test(
        "mobilePhone",
        Translate.get("InvalidPhoneNumber"),
        function (item) {
          if (!item) {
            return true;
          }

          let validationResult = validatePhoneNumber(item);
          return validationResult;
        }
      )
      .optional(),
  });

  useEffect(() => {
    getPersonnummerPlaceholderText().then((result) =>
      setPersonnummerPlaceholderText(result)
    );
  }, []);

  const closeAndReload = () => {
    //Soft reload with redirect
    const hash = window.location.hash.endsWith("#dialog")
      ? window.location.hash.substring(0, window.location.hash.length - 7)
      : window.location.hash;
    const loc = encodeURIComponent(
      `${location.pathname}${location.search}${hash}`
    );
    navigate(`/redirect/${loc}`, { replace: true });
  };

  const countrySearch = useCallback(
    async (searchText) => {
      const lang = localStorage.lang ?? 29;
      const search = encodeURIComponent(searchText);
      const url = `${process.env.REACT_APP_MAIN_URL}countries?searchText=${search}&languageCode=${lang}`;
      const result = await api.fetch(url, false, "GET");
      result.sort((a, b) =>
        a.alpha2 === "SE"
          ? -1
          : b.alpha2 === "SE"
          ? 1
          : a.name.localeCompare(b.name)
      );
      return result;
    },
    [api]
  );

  useEffect(() => {
    countrySearch("").then((result) => setCountries(result));
  }, [countrySearch]);

  const actorNameAutocompleteSearch = useCallback(async (searchText) => {
    var foundActors = await actorSearch(
      searchText,
      actorPersonTypes,
      ["ActorName"],
      50
    );
    if (foundActors) {
      return foundActors.map((actor) => ({
        actorId: actor.actorId,
        actorName: actor.actorName,
        orgNo: actor.orgNo,
        email: actor.email,
        companies: actor.parentActors
          ?.filter(
            (x, index, allParentActors) =>
              x.parentActor.actorId > 0 &&
              x.parentActor.actorId !== actor.actorId &&
              allParentActors.findIndex(
                (y) => y.parentActor.actorId === x.parentActor.actorId
              ) === index
          )
          .map((x) => x.parentActor.actorName),
      }));
    }
  }, []);

  const debouncedExactMatchActorSearch = useMemo(
    () =>
      debounce(async (searchText, searchFields) => {
        const isValidSearchText =
          !!searchText &&
          typeof searchText === "string" &&
          searchText.trim() !== "";
        if (!isMatchingActorsDisabled && isValidSearchText) {
          var result = await actorSearch(
            searchText,
            actorPersonTypes,
            searchFields,
            1,
            true
          );
          if (!!result && result.length > 0) {
            setMatchingActor(result[0]);
          }
        }
      }, 700),
    [isMatchingActorsDisabled]
  );

  const loadActor = (id) => {
    api
      .fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/${id}/as-person`,
        false,
        "GET"
      )
      .then((data) => {
        if (data) {
          const existingActorFormData = {
            actorId: data.actorId,
            actorName: data.actorName,
            externalActorNo: data.externalActorNo,
            // For some reason null BirthDates are set to empty
            // string, so converting back to null in that case
            birthDate: data.birthDate || null,
            orgNo: data.orgNo,
            actorEmail: data.actorEmail,
            isConnectedToUser: data.isConnectedToUser,
            isConnectedToUserAtClient: data.isConnectedToUserAtClient,
            isConnectedToUserAtIssuer: data.isConnectedToUserAtIssuer,
            profilePictureUrl: data.profilePictureUrl,
            observationNote: data.observationNote,
            defaultCustomerOrderNo: data.defaultCustomerOrderNo,
            hasApprovedGDPR: data.hasApprovedGDPR,
            gdprApprovalDate: data.gdprApprovalDate,
            mobilePhone: {
              actorId: data.actorId,
              actorPhoneNumber: data.mobilePhone
                ? data.mobilePhone.actorPhoneNumber
                : null,
            },
            postalAddress: {
              actorId: data.actorId,
              actorAddressStreet2: data.postalAddress?.actorAddressStreet2,
              actorAddressCity: data.postalAddress?.actorAddressCity,
              actorAddressZipCode: data.postalAddress?.actorAddressZipCode,
              actorAddressCountry: data.postalAddress?.actorAddressCountry,
            },
            nationality: data.nationality,
          };
          setOriginalEmail(data.actorEmail);
          setHasEmailChanged(false);
          setOriginalPersonalNo(data.orgNo);
          setHasPersonalNoChanged(false);
          setIsBlankForm(false);
          setFormData(existingActorFormData);
          setDataFetched(true);
        }
      });
  };

  const uploadImage = (actorId, imageId) => {
    return api.fetch(
      `${process.env.REACT_APP_IMAGE_SERVICE_URL}UploadActorImageDatabase`,
      { actorId: actorId, imageId: imageId }
    );
  };

  const deleteImage = async (actorId) => {
    const response = await api.fetch(
      `${process.env.REACT_APP_IMAGE_SERVICE_URL}DeleteActorImageDatabase`,
      { actorId: actorId }
    );
    if (response?.isSuccessful !== false) {
      delete formData.profilePictureUrl;
      setFormData({ ...formData });
    }
  };

  const submitFormData = async (formData) => {
    if (
      isMatchingActorsDisabled &&
      actorId > 0 &&
      formData.actorId !== actorId
    ) {
      // Just a sanity check, to avoid mistakes when making future changes
      throw new Error("Invalid actorId");
    }

    delete formData.profilePictureUrl;
    let endPoint = `${process.env.REACT_APP_MAIN_URL}actors/`;
    if (actorRole === "teacher") {
      endPoint = `${process.env.REACT_APP_MAIN_URL}actors/teachers/`;
    } else if (actorRole === "student" || actorRole === "permit-holder") {
      endPoint = `${process.env.REACT_APP_MAIN_URL}actors/${parentActorId}/students/`;
    } else if (actorRole === "contact") {
      endPoint = `${process.env.REACT_APP_MAIN_URL}actors/clients/${parentActorId}/contacts/`;
    }

    const data = await api.fetch(endPoint, formData);

    if (data && data.isSuccessful) {
      if (imageResponse?.imageId) {
        await uploadImage(data.actorId, imageResponse.imageId);
      }

      if (onActorSaved) {
        await onActorSaved(data);
      }

      if (onClose) {
        onClose();
      } else {
        closeAndReload();
      }
    }
    setIsSubmitLoading(false);
  };

  function validateInput(input, value, validator) {
    value = value ?? "";
    let result;
    try {
      result = schema.validateSync({ [validator]: value }) && false;
    } catch (err) {
      if (err.errors) {
        result = err.errors[0];
      }
    }
    setErrorMess((oldValue) => ({
      ...oldValue,
      [input]: result,
    }));
  }

  useEffect(() => {
    if (isBlankForm) {
      setSubmitDisabled(true);
    }
  }, [isBlankForm]);

  useEffect(
    () => setSubmitDisabled(Object.values(errorMess).some((x) => x)),
    [errorMess]
  );

  useEffect(() => {
    if (actorId) {
      loadActor(actorId);
      setHeaderText(Translate.get("EditPersonalInfo"));
      setSubmitText(
        getUpdateActorSubmitText(actorRole, isEditOnly, false, actorId)
      );
    } else {
      const addActorSubmitText = getAddActorSubmitText(actorRole);
      setHeaderText(addActorSubmitText);
      setSubmitText(addActorSubmitText);
      const blankFormData = {
        actorName: name ?? null,
        externalActorNo: null,
        birthDate: null,
        orgNo: null,
        actorEmail: null,
        mobilePhone: { actorPhoneNumber: null },
        postalAddress: {
          actorAddressStreet2: null,
          actorAddressCity: null,
          actorAddressZipCode: null,
          actorAddressCountry: null,
        },
      };

      setOriginalEmail(null);
      setHasEmailChanged(false);
      setOriginalPersonalNo(null);
      setHasPersonalNoChanged(false);
      setIsBlankForm(!name);
      setFormData(blankFormData);
      setDataFetched(true);
    }
  }, [actorId, name, actorRole, isEditOnly]);

  function handleCancelSubmit() {
    setShowActorWithUserOrgNoQuestion(false);
    setShowActorWithUserEmailQuestion(false);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const askAboutUserOrgNo =
      hasPersonalNoChanged &&
      formData.isConnectedToUserAtIssuer &&
      hasAccess(accessKeys.licenseIssuer) &&
      (hasAccess(accessKeys.swedishCompany) ||
        hasAccess(accessKeys.isMASystem));
    if (askAboutUserOrgNo) {
      setShowActorWithUserOrgNoQuestion(true);
    }

    const askAboutUserEmail = hasEmailChanged && formData.isConnectedToUser;
    if (askAboutUserEmail) {
      setShowActorWithUserEmailQuestion(true);
    }

    if (!askAboutUserEmail && !askAboutUserOrgNo) {
      setIsSubmitLoading(true);
      submitFormData(formData);
    }
  };

  function handleSubmitActorConnectedToUser() {
    setShowActorWithUserEmailQuestion(false);
    setShowActorWithUserOrgNoQuestion(false);
    setIsSubmitLoading(true);
    submitFormData(formData);
  }

  function handleUseMatchingActor() {
    setDataFetched(false);
    loadActor(matchingActor.actorId);
    setHeaderText(Translate.get("EditPersonalInfo"));
    setSubmitText(
      getUpdateActorSubmitText(actorRole, isEditOnly, true, actorId)
    );
    setMatchingActor(null);
  }

  function handleActorNameAutocompleteChange(actor) {
    if (actor && actor.actorId > 0) {
      setDataFetched(false);
      loadActor(actor.actorId);
      setHeaderText(Translate.get("EditPersonalInfo"));
      setSubmitText(
        getUpdateActorSubmitText(actorRole, isEditOnly, true, actorId)
      );
    } else {
      setFormData({
        ...formData,
        actorName: actor?.actorName,
      });
      validateInput("actorName", actor?.actorName, "required");
      setIsBlankForm(false);
    }
  }

  function handleCancel() {
    if (onCancel) {
      onCancel();
    }

    if (onClose) {
      onClose();
    } else {
      closeAndReload();
    }
  }

  async function handleOrgNoBlur(e) {
    if (formData.orgNo === oldInvalidPersonalNumber) {
      return;
    }

    let res = await tryCreatePersonnummerWithSettings(e.target.value);
    if (res === null) {
      return;
    }
    if (!res.isValid) {
      setOldInvalidPersonalNumber(e.target.value);
      setInvalidOrgNoMessage(Translate.get("SsnDeviate"));
    } else if (res.formattedPersonalNumber !== null) {
      formData.orgNo = res.formattedPersonalNumber;
      setInvalidOrgNoMessage("");
    }
  }

  // Using this to avoid validating the potentially empty
  // name value when opening an empty form the first time
  const isActorNameBlank =
    !("actorName" in formData) || typeof formData?.actorName === typeof null;

  return (
    <>
      {!dataFetched && (
        <>
          <Preloader />
          {!hideHeader && <DialogTitle>{headerText}</DialogTitle>}
          <DialogContent className="stdDialogContent"></DialogContent>
        </>
      )}
      {dataFetched && (
        <>
          {!hideHeader && <DialogTitle>{headerText}</DialogTitle>}
          <DialogContent
            dividers={true}
            className="stdDialogContent dialogOverlay"
          >
            {/* <FormControl fullWidth margin="normal" size='medium' fullWidth={true} onSubmit={handleSubmit}> */}
            <form onSubmit={handleSubmit} className="twoColForm">
              <div className="formAvatar">
                <div className="smallHeader">
                  {Translate.get("ProfilePicture")}
                </div>
                <EPortAvatar
                  avatarWidth={160}
                  avatarHeight={160}
                  fontSize={72}
                  displayName={formData.actorName ? formData.actorName : "?"}
                  pictureUrl={formData.profilePictureUrl + ""}
                  onImageUploaded={(resp) => setImageResponse(resp)}
                  onDeleteImage={() => deleteImage(actorId)}
                  disableSubmit={(resp) => setSubmitDisabled(resp)}
                  //disableSubmit={setSubmitDisabled}
                  imageType={"person"}
                />
              </div>
              <div className="stdForm">
                <div className="smallHeader">
                  {Translate.get("PersonalInfo")}
                </div>
                <div>
                  <AutocompleteSearchField
                    placeholder={Translate.get("NameFieldHelptext")}
                    label={Translate.get("Name")}
                    value={{ actorName: formData.actorName ?? "" }}
                    onValueChange={handleActorNameAutocompleteChange}
                    getOptionLabel={(option) => option?.actorName ?? ""}
                    renderOption={(option) => (
                      <ActorAutocompleteOption
                        actorName={option?.actorName}
                        orgNo={option?.orgNo}
                        email={option?.email}
                        companies={option?.companies}
                      />
                    )}
                    keyPropName="actorId"
                    search={
                      !formData || !formData.actorId || formData.actorId <= 0
                        ? actorNameAutocompleteSearch
                        : null
                    }
                    createNewValue={(text) => ({
                      actorName: text,
                    })}
                    textFieldProps={{
                      required: true,
                      error: !isActorNameBlank && !!errorMess["actorName"],
                      helperText:
                        (invalidOrgNoMessage.length > 0 ? " " : "") ||
                        (!isActorNameBlank && errorMess["actorName"]),
                    }}
                    hideOptionsFilter={
                      formData.actorId > 0 &&
                      ((option) => option.actorId === formData.actorId)
                    }
                    disablePortal={parentZIndexOverride}
                  />
                  <TextField
                    inputProps={{ maxLength: 13 }}
                    fullWidth
                    value={formData.orgNo ?? ""}
                    onChange={(event, value) => {
                      setFormData({ ...formData, orgNo: event.target.value });
                      setIsBlankForm(false);
                      setHasPersonalNoChanged(
                        originalPersonalNo !== event.target.value
                      );
                      debouncedExactMatchActorSearch(event.target.value, [
                        "OrgNo",
                      ]);
                    }}
                    label={Translate.get("PersonalNo")}
                    placeholder={Translate.get(personnummerPlaceholderText)}
                    error={!!invalidOrgNoMessage}
                    helperText={
                      invalidOrgNoMessage ||
                      (!isActorNameBlank && errorMess["actorName"] && " ")
                    }
                    onBlur={handleOrgNoBlur}
                  />
                </div>
                <div>
                  {/* <TextField
                    fullWidth
                    value={formData.birthDate ?? ""}
                    onChange={(event, value) => {
                      setFormData({
                        ...formData,
                        birthDate: event.target.value,
                      });
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("BirthDate")}
                  /> */}
                  <DatePicker
                    mask={getCurrentDateMask()}
                    inputFormat={getCurrentInputFormat()}
                    value={formData.birthDate}
                    onChange={(newDate, realVal) => {
                      if (
                        (realVal && realVal.length === 10) ||
                        (!realVal && newDate)
                      ) {
                        setFormData({
                          ...formData,
                          birthDate: toSameDateTimeUTC(newDate),
                        });
                        setIsBlankForm(false);
                      } else if (!realVal && !newDate) {
                        setFormData({
                          ...formData,
                          birthDate: null,
                        });
                        setIsBlankForm(false);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: Translate.get("DatePlaceholder"),
                        }}
                        fullWidth
                        label={Translate.get("BirthDate")}
                      />
                    )}
                  />

                  <TextField
                    fullWidth
                    value={formData.externalActorNo ?? ""}
                    onChange={(event, value) => {
                      setFormData({
                        ...formData,
                        externalActorNo: event.target.value,
                      });
                      setIsBlankForm(false);
                      debouncedExactMatchActorSearch(event.target.value, [
                        "ExternalActorNo",
                      ]);
                    }}
                    label={Translate.get("EmployeeNo")}
                  />
                </div>
                <div>
                  <AutocompleteSearchField
                    requireSelection
                    disableAddAsNew
                    disablePortal={parentZIndexOverride}
                    label={Translate.get("Nationality")}
                    defaultOptions={countries}
                    value={
                      formData.nationality && countries && countries.length > 0
                        ? countries.find(
                            (c) => c.alpha2 === formData.nationality
                          )
                        : ""
                    }
                    onValueChange={(option) => {
                      setFormData({
                        ...formData,
                        nationality: option?.alpha2,
                      });
                      setIsBlankForm(false);
                    }}
                    getOptionLabel={(option) =>
                      option ? `${option.name} (${option.alpha2})` : ""
                    }
                    renderOption={(option) =>
                      `${option.name} (${option.alpha2})`
                    }
                    keyPropName="alpha2"
                    search={countrySearch}
                    textFieldProps={{
                      required: false,
                    }}
                    hideOptionsFilter={
                      formData.nationality &&
                      ((option) => option.alpha2 === formData.nationality)
                    }
                  />
                </div>
                <div className="smallHeader">
                  {Translate.get("ContactInformation")}
                </div>
                <div>
                  <TextField
                    fullWidth
                    spellCheck={false}
                    value={formData.actorEmail ?? ""}
                    onChange={(event, value) => {
                      setFormData({
                        ...formData,
                        actorEmail: event.target.value,
                      });
                      if (errorMess.actorEmail) {
                        validateInput(
                          "actorEmail",
                          event.target.value,
                          "email"
                        );
                      }
                      setIsBlankForm(false);
                      setHasEmailChanged(originalEmail !== event.target.value);
                      debouncedExactMatchActorSearch(event.target.value, [
                        "ActorEmail",
                      ]);
                    }}
                    onBlur={() =>
                      validateInput("actorEmail", formData?.actorEmail, "email")
                    }
                    label={Translate.get("Email")}
                    error={!!errorMess["actorEmail"]}
                    helperText={
                      errorMess["actorEmail"] ? errorMess["actorEmail"] : " "
                    }
                  />
                  <TextField
                    fullWidth
                    value={formData.mobilePhone.actorPhoneNumber ?? ""}
                    onChange={(event, value) => {
                      setFormData({
                        ...formData,
                        mobilePhone: {
                          actorPhoneNumber: event.target.value,
                          actorId: formData.actorId,
                        },
                      });
                      setIsBlankForm(false);
                      if (errorMess.actorPhoneNumber) {
                        validateInput(
                          "actorPhoneNumber",
                          event.target.value,
                          "mobilePhone"
                        );
                      }
                    }}
                    onBlur={() =>
                      validateInput(
                        "actorPhoneNumber",
                        formData?.mobilePhone?.actorPhoneNumber,
                        "mobilePhone"
                      )
                    }
                    label={Translate.get("MobilePhone")}
                    error={!!errorMess["actorPhoneNumber"]}
                    helperText={
                      errorMess["actorPhoneNumber"]
                        ? errorMess["actorPhoneNumber"]
                        : " "
                    }
                  />
                </div>
                <div className="smallHeader">{Translate.get("Address")}</div>
                <div>
                  <TextField
                    fullWidth
                    value={formData.postalAddress.actorAddressStreet2 ?? ""}
                    onChange={(event, value) => {
                      const updatedAddress = {
                        ...formData.postalAddress,
                        actorAddressStreet2: event.target.value,
                        actorId: formData.actorId,
                      };
                      setFormData({
                        ...formData,
                        postalAddress: updatedAddress,
                      });
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("StreetAddress")}
                  />

                  <TextField
                    fullWidth
                    value={formData.postalAddress.actorAddressCity ?? ""}
                    onChange={(event, value) => {
                      const updatedAddress = {
                        ...formData.postalAddress,
                        actorAddressCity: event.target.value,
                        actorId: formData.actorId,
                      };
                      setFormData({
                        ...formData,
                        postalAddress: updatedAddress,
                      });
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("City")}
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    value={formData.postalAddress.actorAddressZipCode ?? ""}
                    onChange={(event, value) => {
                      const updatedAddress = {
                        ...formData.postalAddress,
                        actorAddressZipCode: event.target.value,
                        actorId: formData.actorId,
                      };
                      setFormData({
                        ...formData,
                        postalAddress: updatedAddress,
                      });
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("ZipCode")}
                  />
                  <TextField
                    fullWidth
                    value={formData.postalAddress.actorAddressCountry ?? ""}
                    onChange={(event, value) => {
                      const updatedAddress = {
                        ...formData.postalAddress,
                        actorAddressCountry: event.target.value,
                        actorId: formData.actorId,
                      };
                      setFormData({
                        ...formData,
                        postalAddress: updatedAddress,
                      });
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("Country")}
                  />
                </div>
                <div className="smallHeader">{Translate.get("Other")}</div>
                <div>
                  <TextField
                    fullWidth
                    value={formData.defaultCustomerOrderNo ?? ""}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        defaultCustomerOrderNo: event.target.value,
                      });
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("DefaultCustomerOrderNo")}
                    inputProps={{ maxLength: 30 }}
                  />
                </div>
                <div>
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    value={formData.observationNote ?? ""}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        observationNote: event.target.value,
                      });
                      setIsBlankForm(false);
                    }}
                    label={Translate.get("ObservationNote")}
                    inputProps={{ maxLength: 255 }}
                  />
                </div>
              </div>
            </form>
            {/* </FormControl> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>{Translate.get("Cancel")}</Button>
            <LoadingButton
              className="stdLightLoader"
              loading={isSubmitLoading}
              disabled={submitDisabled}
              onClick={handleSubmit}
            >
              {submitText}
            </LoadingButton>
          </DialogActions>
          {/* YesOrNoDialog for asking if we should use an existing matching actor instead */}
          <YesOrNoDialog
            open={!!matchingActor && matchingActor.actorId !== formData.actorId}
            title={Translate.get("UseMatchingPersonTitle")}
            content={
              !!matchingActor ? (
                <>
                  {Translate.get("UseMatchingPersonQuestion")}
                  <Box marginTop="24px">
                    <ActorAvatar
                      name={matchingActor.actorName}
                      email={matchingActor.email}
                      extraFields={[
                        matchingActor.orgNo,
                        matchingActor.externalActorNo,
                        matchingActor.parentActors
                          ? [
                              ...matchingActor.parentActors.map(
                                (parent) => parent.parentActor.actorName
                              ),
                            ]
                          : [],
                      ]}
                      profilePictureUrl={matchingActor.profilePictureUrl}
                      vertical
                    />
                  </Box>
                </>
              ) : (
                ""
              )
            }
            onNo={() => setMatchingActor(null)}
            onYes={handleUseMatchingActor}
            noText={Translate.get("No")}
            yesText={Translate.get("Yes")}
          />
          {/* YesOrNoDialog for verifying that we should update an actor connected to a user.
              There may be more than one question, so they need to be displayed sequentially */}
          <YesOrNoDialog
            open={showActorWithUserEmailQuestion}
            title={Translate.get("UpdatePersonConnectedToUserTitle")}
            content={Translate.get("UpdatePersonConnectedToUserQuestion")}
            onNo={handleCancelSubmit}
            onYes={() =>
              showActorWithUserOrgNoQuestion
                ? setShowActorWithUserEmailQuestion(false)
                : handleSubmitActorConnectedToUser()
            }
            noText={Translate.get("No")}
            yesText={Translate.get("Yes")}
          />
          <YesOrNoDialog
            open={
              showActorWithUserOrgNoQuestion && !showActorWithUserEmailQuestion
            }
            title={Translate.get("UpdatePersonConnectedToUserOrgNoTitle")}
            content={Translate.get("UpdatePersonConnectedToUserOrgNoQuestion")}
            onNo={handleCancelSubmit}
            onYes={handleSubmitActorConnectedToUser}
            noText={Translate.get("No")}
            yesText={Translate.get("Yes")}
          />
          {/* Allt innehåll */}
        </>
      )}
    </>
  );
};
export default PersonCrud;
