import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Container, Typography, useMediaQuery } from '@mui/material';
// components
import Page from '../components/Page';
import Translate from '../utils/Translate';
import { SetPasswordForm } from '../components/authentication/register';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));


const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {
  const [transLoad, setTransLoad] = useState(false);
  const smallScreen = useMediaQuery('(max-width:700px)');
  Translate.loadPreLogin(false).then(() => {
    
    setTransLoad(true);
  });
  return (
    <>
    <RootStyle className={window.global.isKs ? smallScreen ? "loginPagePermitsMobile" : "loginPagePermits" : "loginPage"}>
      {transLoad && <Container sx={{display:'flex',minHeight: '100vh'}}>
        <ContentStyle className="loginBox"  sx={{minHeight: 'auto'}}>
         <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
            {Translate.get('ChooseNewPassword')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
            {Translate.get('MinimumPasswordLength')}
            </Typography>
          </Box>
          <SetPasswordForm />
          <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
             
              <Link to="/login" component={RouterLink}>
              {Translate.get('LogIn')}
              </Link>
            </Typography>
        </ContentStyle>
      </Container>}
    </RootStyle>
    </>
  );
}
