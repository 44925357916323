import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tooltip,
} from "@mui/material";
import Translate from "../../utils/Translate";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ActorAvatar from "../ActorAvatar";
import DotMenu from "../DotMenu";
import { Clear, Edit, Email } from "@mui/icons-material";
import AutocompleteSearchField from "../AutocompleteSearchField";
import ActorAutocompleteOption from "../ActorAutocompleteOption";
import { actorPersonTypes, actorSearch } from "../../utils/actorSearch";
import { useState } from "react";
import { validateEmailRecipient } from "../actor-permits/validation/ActorValidator";
import PersonCrudDialog from "../actors/crud/PersonCrudDialog";
import EditEmailPreviewDialog from "./EditEmailPreviewDialog";
import { useSnackbar } from "notistack";
import { formatDate } from "../../utils/dateTimeFormat";
import ErrorMessage from "../ErrorMessage";

// Räknare att använda som unikt id.
// Unikt id behövs ifall samma actor finns flera gånger i olika roller i mottagarlistan
let uniqueCounter = 1;

function formatSearchResultActor(actor) {
  return {
    actorId: actor.actorId,
    name: actor.actorName,
    orgNo: actor.orgNo,
    externalActorNo: actor.externalActorNo,
    email: actor.email,
    profilePictureUrl: actor.profilePictureUrl,
    phoneNumber: actor.phoneNumbers?.find((ph) => ph.type === 2)?.phoneNumber,
    companies: actor.parentActors
      ?.filter(
        (x, index, allParentActors) =>
          x.parentActor.actorId !== actor.actorId &&
          x.parentActor.actorId > 0 &&
          allParentActors.findIndex(
            (y) => y.parentActor.actorId === x.parentActor.actorId
          ) === index
      )
      .map((x) => ({
        actorId: x.parentActor.actorId,
        name: x.parentActor.actorName,
      })),
  };
}

function initialRecipientValidation(recipentsGrouped) {
  return recipentsGrouped.map((group) => ({
    ...group,
    recipients: group.recipients.map((recipient) => validateRecipient(recipient))
  }));
}

function validateRecipient(recipient) {
  return {
    ...recipient,
    error: validateEmailRecipient(recipient)
  };
}

function addUniqueIdToRecipients(recipients) {
  return recipients.map(recipientGroup => ({
    ...recipientGroup,
    recipients: recipientGroup.recipients.map(
      (recipient) => ({
        ...recipient,
        ...addRecipientUniqueId(recipient) 
      }))
  }));
}

// lägg till ett unikt id på varje actor.
// ActorId kan vara otillräckligt unikt eftersom samma actor kan finnas flera gånger i olika roller
function addRecipientUniqueId(recipient) {
  return {
    ...recipient,
    uniqueId: recipient.uniqueId || uniqueCounter++ // lägg till nytt unikt id om det inte redan finns
  };
}


export default function GroupEmailDialog({
  title,
  recipientsGrouped: recipientsGroupedProp, // [{recipientsGroupName: "xxx", recipients:[{...}]}]
  getRecipientEmailPreview,
  sendRecipientEmail,
  displayAddRecipient,
  defaultOptions, // defaultOptions för AutocompleteSearchField
  newActorParentActorId,
  onClose,
  onCancel,
  overrideOnSend,
  overrideSendText,
  overrideCancelText,
  extraRecipientMenuItems, // möjlighet att lägga till extra menyval i dotmenyn för mottagare
}) {

  const [recipientsGrouped, setRecipientsGrouped] = useState(
        addUniqueIdToRecipients(initialRecipientValidation(recipientsGroupedProp)));
  const [recipientToCrudActorId, setRecipientToCrudActorId] = useState(null);
  const [emailPreviews, setEmailPreviews] = useState({});
  const [editEmailPreviewActorUniqueId, setEditEmailPreviewActorUniqueId] = useState(null);
  const [isLoadingActorUniqueId, setIsLoadingActorUniqueId] = useState(false);
  const [anyActorWasUpdated, setAnyActorWasUpdated] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function compareContacts(lhs, rhs) {
    const lhsCompanyConnections = lhs.parentActors?.filter(
      (parent) => parent.parentActor.actorId === newActorParentActorId
    );
    const lhsIsConnectedToCompany =
      !!lhsCompanyConnections && lhsCompanyConnections.length > 0;
    const lhsIsContactToCompany =
      lhsIsConnectedToCompany &&
      lhsCompanyConnections.some((parent) => parent.actorType === 3);
    const rhsCompanyConnections = rhs.parentActors?.filter(
      (parent) => parent.parentActor.actorId === newActorParentActorId
    );
    const rhsIsConnectedToCompany =
      !!rhsCompanyConnections && rhsCompanyConnections.length > 0;
    const rhsIsContactToCompany =
      rhsIsConnectedToCompany &&
      rhsCompanyConnections.some((parent) => parent.actorType === 3);
    if (
      (lhsIsContactToCompany && !rhsIsContactToCompany) ||
      (lhsIsConnectedToCompany && !rhsIsConnectedToCompany)
    ) {
      return -1;
    }
    if (
      (rhsIsContactToCompany && !lhsIsContactToCompany) ||
      (rhsIsConnectedToCompany && !lhsIsConnectedToCompany)
    ) {
      return 1;
    }
    // Use the order from backend in this case
    return 0;
  }

  async function personSearch(searchText) {
    const foundActors = await actorSearch(
      searchText,
      actorPersonTypes,
      ["ActorName", "OrgNo", "ExternalActorNo"],
      50
    );
    if (foundActors) {
      return foundActors
        .sort(compareContacts)
        .filter((actor) => !recipientsGrouped.some(x => x.recipients.some((d) => d.actorId === actor.actorId)))
        .map((actor) => formatSearchResultActor(actor));
    }
  }

  async function handleRecipientChanged(recipient) {
    if (!recipient) {
      // This happens if the user presses the "Enter" key,
      // rather than selecting the "Add" from the list
      return;
    }
    
    addRecipientUniqueId(recipient);

    const isExistingActor = recipient.actorId > 0;
    if (isExistingActor) {
      recipient = validateRecipient(recipient);
    }

    // Just nu lägger vi till nya mottagare i första gruppen
    // kan behöva förbättras senare
    let recipientsCopy;
    if(recipientsGrouped?.length > 0) {
      const firstGroup = { 
        recpientGroupName: recipientsGrouped[0].recpientGroupName,
        recipients: [...recipientsGrouped[0].recipients, recipient]
      }; 
      recipientsCopy = [firstGroup, ...recipientsGrouped.splice()]
    } else { // om det inte finns några grupper tidigare - skapa ny
      recipientsCopy = [{ 
        recpientGroupName: Translate.get("EmailReceivers"),
        recipients: [recipient]
      }]; 
    }
    setRecipientsGrouped(recipientsCopy);

    // Automatically open the PersonCrud to edit the new actor
    if (!isExistingActor) {
      setRecipientToCrudActorId(recipient.actorId);
    }
  }
 
  function handleRecipientCrudSave(recipient) {
    if (!recipient || !recipient.actorId) {
      throw new Error("Missing data");
    }

    recipient = {
      ...recipient,
      name: recipient.actorName,
      email: recipient.actorEmail,
      companies: recipient.childActorGroups
        ? recipient.childActorGroups.map((x) => ({ actorId: x.parentActorId }))
        : null,
    };
    recipient = validateRecipient(recipient);

    const recipientsCopy =
      recipientsGrouped.map(rg => 
        ({
          ...rg,
          recipients: rg.recipients.map(r => r.actorId === recipientToCrudActorId ? recipient : r)
        }));
    setAnyActorWasUpdated(true);
    setRecipientsGrouped(recipientsCopy);
  }

  function handleRemoveRecipient(actorId) {
    const recipientsCopy =
      recipientsGrouped.map(rg => 
        ({ 
          ...rg,
          recipients: rg.recipients.filter(r => r.actorId !== actorId)
        }));

    setRecipientsGrouped(recipientsCopy);
  }

  async function handleEditPreview(recipient) {
    if (!(recipient.uniqueId in emailPreviews)) {
      setIsLoadingActorUniqueId(recipient.uniqueId);
      let response
      try {
        response = await getRecipientEmailPreview(recipient.actorId, recipient);
      } catch {
        setIsLoadingActorUniqueId(null);
        return;  
      }

      if (!!response && response.isSuccessful !== false) {
        setEmailPreviews((prev) => ({
          ...prev,
          [recipient.uniqueId]: response,
        }));
        setEditEmailPreviewActorUniqueId(recipient.uniqueId);
      }
      setIsLoadingActorUniqueId(null);
    } else {
      setEditEmailPreviewActorUniqueId(recipient.uniqueId);
    }
  }

  async function handleClickSend() {
    if (overrideOnSend) {
      const emails =
        recipientsGrouped.flatMap((group) => 
          group.recipients.map((r) => {
            const emailPreview = emailPreviews[r.uniqueId];
            return {
              receiverActorId: r.actorId,
              subject: emailPreview?.subject,
              htmlBody: emailPreview?.htmlBody,
              actor: r
            };
          })
        );
      await overrideOnSend(emails);
    } else {
      await handleSendAllEmails();
    }
  }

  async function handleSendAllEmails() {
    const results = [];
    for(const group of recipientsGrouped) {
      for (const recipient of group.recipients) {
        const emailPreview = emailPreviews[recipient.uniqueId];
        const response = await sendRecipientEmail(
          recipient.actorId,
          emailPreview?.subject,
          emailPreview?.htmlBody
        );
        results.push({ actorId: recipient.actorId, response });
      }
    }

    const failedResults = results.filter((r) => !r.response.isSuccessful);
    if (failedResults.length > 0) {
      setRecipientsGrouped(
        (prevValue) => prevValue.map((group) => ({
          ...group,
          recipients: group.recipients.map((rec) => { 
            const errorResult = failedResults.find(f => f.actorId === rec.actorId);
            return errorResult
            ? {
              ...rec,
              error: Translate.get(errorResult.response.errorMessageTranslationKey)
            }
            : rec;
          })
        })) 
      );
    } else {
      const successMessage = enqueueSnackbar(
        Translate.get("SendEmailsSuccess"),
        {
          variant: "success",
          autoHideDuration: 6000,
          onClick: () => closeSnackbar(successMessage),
        }
      );
      onClose(anyActorWasUpdated);
    }
  }

  function getDotMenuActions({ recipient, onRemoveRecipient, onCrudPerson, extraRecipientMenuItems }) {
    const menuItems = [
      {
        icon: <Edit />,
        text: Translate.get("EditPersonalInfo"),
        onClick: onCrudPerson,
      },
      ...(getRecipientEmailPreview
        ? [
            {
              icon: <Edit />,
              text: Translate.get("EditMessage"),
              onClick: () => handleEditPreview(recipient),
            },
          ]
        : []),
      ...(extraRecipientMenuItems
           ? extraRecipientMenuItems.map(item => ({ 
              ...item,
              onClick: async ()  => {
                setIsLoadingActorUniqueId(recipient.uniqueId);
                await item.onClick(recipient);
                setIsLoadingActorUniqueId(null);
              },
              hide: item.isVisible ? !item.isVisible(recipient.actorId, recipient) : undefined
            }))
            : []),
      {
        icon: <Clear />,
        text: Translate.get("RemoveRecipient"),
        onClick: onRemoveRecipient,
      },
    ];
    return menuItems;
  }

  function getRecipientByActorId(actorId) {
    for(const group of recipientsGrouped ?? []) {
      for(const recipient of group.recipients ?? []) {
        if(recipient.actorId === actorId) {
          return recipient;
        }
      }
    }
    return null;
  }

  const showRecipientCrud = recipientToCrudActorId != null;

  return (
    <>
      {editEmailPreviewActorUniqueId && (
        <EditEmailPreviewDialog
          {...emailPreviews[editEmailPreviewActorUniqueId]}
          onOk={(updatedPreview) => {
            setEmailPreviews((prev) => ({
              ...prev,
              [editEmailPreviewActorUniqueId]: updatedPreview,
            }));
            setEditEmailPreviewActorUniqueId(null);
          }}
          onClose={() => setEditEmailPreviewActorUniqueId(null)}
        />
      )}
      {showRecipientCrud && (
        <PersonCrudDialog
          open={showRecipientCrud}
          personCrudProps={{
            name: getRecipientByActorId(recipientToCrudActorId)?.name ?? "",
            onClose: () => setRecipientToCrudActorId(null),
            onActorSaved: (actor) =>
              handleRecipientCrudSave(actor),
            actorId: recipientToCrudActorId,
            parentActorId: newActorParentActorId,
            actorRole: "contact",
            onCancel: () => setRecipientToCrudActorId(null),
          }}
        />
      )}
      <Dialog open={true} className="smallCourseDialog" onClose={() => onClose(anyActorWasUpdated)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <Stack spacing={3}>
            {displayAddRecipient && <AutocompleteSearchField
              placeholder={Translate.get("AddReceiver")}
              label={Translate.get("AddReceiver")}
              value={""}
              onValueChange={handleRecipientChanged}
              getOptionLabel={(option) => option?.name ?? ""}
              renderOption={(option) => (
                <ActorAutocompleteOption
                  actorName={option?.name}
                  orgNo={option?.orgNo}
                  email={option?.email}
                  companies={option?.companies?.map((x) => x.name)}
                  parentActors={option?.parentActors}
                  externalActorNo={option?.externalActorNo}
                />
              )}
              keyPropName="actorId"
              requireSelection={true}
              search={personSearch}
              createNewValue={(text) => ({
                name: text,
              })}
              defaultOptions={defaultOptions}
            />}
            {recipientsGrouped.map((group) =>
              <> 
                <div className="smallHeader">{`${group.recpientGroupName || Translate.get("EmailReceivers")} (${group.recipients.length})`}</div>
                {group.recipients.map((recipient, i) => (
                  
                  <Stack key={i} direction="column" spacing={1} className="stdList">
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      gap="10px"
                    >
                      <ActorAvatar
                        name={recipient.name}
                        email={recipient.email}
                        actorType={recipient.actorType}
                        profilePictureUrl={recipient.profilePictureUrl}
                      />
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        gap="10px"
                      >
                        {recipient.sentCommunication && (
                          <Tooltip
                            title={`${
                              recipient.sentCommunication.bounced
                                ? Translate.get("EmailBounce")
                                : Translate.get("Ok")
                            } (${formatDate(
                              recipient.sentCommunication.sendDate
                            )})`}
                            className={
                              "iconbox " +
                              (recipient.sentCommunication.bounced ? "warning" : "")
                            }
                          >
                            <Email />
                          </Tooltip>
                        )}
                        {isLoadingActorUniqueId === recipient.uniqueId ? (
                          <CircularProgress
                            color="inherit"
                            size={20}
                            sx={{ marginTop: "8px" }}
                          />
                        ) : (
                          <DotMenu
                            content={getDotMenuActions({
                              recipient,
                              onRemoveRecipient: () => handleRemoveRecipient(recipient.actorId),
                              onCrudPerson: () => setRecipientToCrudActorId(recipient.actorId),
                              extraRecipientMenuItems: extraRecipientMenuItems
                            })}
                            horizontal
                          />
                        )}
                      </Box>
                    </Box>
                    {recipient.error && (
                      <ErrorMessage error={recipient.error} onClick={() => setRecipientToCrudActorId(recipient.actorId)} /> 
                    )}
                  </Stack>
                ))
              }
            </>)
            }
          </Stack>
        </DialogContent>
        <DialogActions>
          <ButtonWithSpinner variant="text" onClick={() => onCancel ? onCancel(anyActorWasUpdated) : onClose(anyActorWasUpdated)}>
            {overrideCancelText ?? Translate.get("Cancel")}
          </ButtonWithSpinner>
          <ButtonWithSpinner
            variant="contained"
            disabled={
              !recipientsGrouped ||
              recipientsGrouped.length === 0 ||
              recipientsGrouped.flatMap(x => x.recipients).length === 0 ||
              recipientsGrouped.flatMap(x => x.recipients).some((r) => r.error)
            }
            onClick={handleClickSend}
          >
            {overrideSendText ?? Translate.get("Send")}
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>
    </>
  );
}
