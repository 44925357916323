import {
  Avatar,
  Input,
  Box,
  Stack,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  Skeleton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { useApi } from "../utils/Api";
import Translate from "../utils/Translate";
import { useSnackbar } from "notistack";
import YesOrNoDialog from "./YesOrNoDialog";
import { useEffect } from "react";
import ImgCrop from "./imgCrop/ImgCrop";
const EPortAvatar = ({
  id, // not mandatory, will be appended to file name in blob
  pictureUrl, // if an image exists, use this url
  displayName, // the alt text do be displayed if no url exists
  avatarWidth, // avatar width, mui units
  avatarHeight, // avatar height, mui units
  fontSize, // display letter size
  variant = "circular",
  showBorder, // show a circular border around the avatar
  onImageUploaded, //({imageId: "afbdf...24"}) => {} callback when image is uploaded, this may be used to attach image to actor
  onDeleteImage,
  token, // not mandatory
  disableSubmit = undefined,
  imageType,
}) => {
  const [internalPictureUrl, setInternalPictureUrl] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [showImageDialog, setShowImageDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [deleteDisabled, setDeleteDisabled] = useState(false);
  const [showImgCrop, setShowImgCrop] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const tenMib = 1024 * 1024 * 10;
  const api = useApi();

  useEffect(() => {
    let internalUploadResponse = uploadResponse;
    if (pictureUrl !== internalPictureUrl) {
      setUploadResponse(null);
      internalUploadResponse = null;
      setInternalPictureUrl(pictureUrl);
    }
    ////console.log("pictureUrl", pictureUrl);
    const hasUploadResponse = (internalUploadResponse && true) || false;
    const hasPictureUrl =
      (pictureUrl && pictureUrl !== "null" && true) || false; // TODO why may this become the *string* "null" ?
    ////console.log("has upload response", hasUploadResponse);
    ////console.log("has picture url", hasPictureUrl);

    const disabled = hasUploadResponse || !hasPictureUrl;
    ////console.log("disabled", disabled);
    setDeleteDisabled(disabled);
  }, [pictureUrl, uploadResponse]);

  const showErrorMessage = (errorMessageTranslationKey) => {
    const errorMessage = enqueueSnackbar(
      Translate.get(errorMessageTranslationKey),
      {
        variant: "error",
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      }
    );
  };

  const preUploadImage = (evt) => {
    const file = evt.target.files[0];
    //showErrorMessage(file.size);
    //return
    if (file?.size > tenMib) {
      showErrorMessage("FileIsTooLarge");
      return;
    }
    if (variant === "circular") {
      //uploadImage(file); //Här gör vi extra upload om man inte vill beskära bilden
      setShowImgCrop(file);
    } else {
      uploadImage(file);
    }
  };

  const uploadImage = (file) => {
    ////console.log('----FILE----',file)
    ////console.log('----FILE SIZE----',file.size / 1024 / 1024 )
    if (file?.size > tenMib) {
      showErrorMessage("FileIsTooLarge");
      return;
    }
    setShowImgCrop(false);
    setUploadResponse(null);
    setUploadingImage(true);
    const data = new FormData();
    data.append("actorId", id);
    data.append("imageFile", file);
    data.append("type", imageType)
    token = token ? token : false;

    api
      .fetchWithOverride(
        `${process.env.REACT_APP_IMAGE_SERVICE_URL}ProcessUpload`,
        data,
        "POST",
        (resp) => resp,
        null,
        null,
        null,
        token
      )
      .then(
        (resp) => {
          if (resp.imageId) {
            setUploadResponse(resp);
            if (onImageUploaded) {
              onImageUploaded(resp);
            }
          } else if (resp.isSuccessful === false && resp.errorMessageTranslationKey) {
            showErrorMessage(resp.errorMessageTranslationKey);
          } else {
            showErrorMessage("SomethingFailed");
          }
        }
      )
      .finally(() => setUploadingImage(false));
  };

  const avatarClick = () => {
    if (getImageUrl()) {
      setShowImageDialog(true);
    }
  };

  const onDeleteImageYesClick = () => {
    onDeleteImage();
    setShowDeleteDialog(false);
  };

  const getImageUrl = () =>
    uploadResponse
      ? `${process.env.REACT_APP_IMAGE_SERVICE_URL}GetProcessedImage?imageId=${uploadResponse.imageId}`
      : pictureUrl;

  const deleteImageClick = () => {
    setShowDeleteDialog(true);
  };

  const avatar = () =>
    uploadingImage ? (
      <Skeleton
        animation="wave"
        variant="circular"
        width={avatarWidth}
        height={avatarHeight}
      />
    ) : (
      <Avatar
        onClick={avatarClick}
        alt={displayName}
        src={getImageUrl()}
        sx={{ width: avatarWidth, height: avatarHeight, fontSize: fontSize }}
        variant={variant}
      />
    );
  if (disableSubmit !== undefined) {
    if (showImgCrop) {
      disableSubmit(true);
    } else {
      disableSubmit(false);
    }
  }

  if (showImgCrop) {
    return (
      <ImgCrop
        img={showImgCrop}
        uploadingImage={uploadingImage}
        uploadImage={uploadImage}
      />
    );
  }
  return (
    <>
      <Stack direction="column" alignItems="center" spacing={2} sx={{ pt: 2 }}>
        {showBorder ? (
          <Box
            border="dashed 1px rgba(0, 0, 0, 0.12)"
            borderRadius="50%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="144px"
            height="144px"
          >
            {avatar()}
          </Box>
        ) : (
          avatar()
        )}
        <Stack direction="row" alignItems="center">
          <label htmlFor="upload-image-input">
            <Input
              inputProps={{ accept: "image/*" }}
              id="upload-image-input"
              onInput={preUploadImage}
              type="file"
              sx={{ display: "none" }}
            />
            <Button sx={{ marginTop: ".2rem" }} component="span">
              {Translate.get("Upload")}
            </Button>
            {/* <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                    </IconButton> */}
          </label>
          |
          <IconButton
            onClick={deleteImageClick}
            title={Translate.get("DeletePicture")}
            disabled={deleteDisabled}
          >
            <DeleteIcon color={deleteDisabled ? "disabled" : "primary"} />
          </IconButton>
        </Stack>
      </Stack>
      {showImageDialog && (
        <Dialog
          open={showImageDialog}
          onClose={() => setShowImageDialog(false)}
        >
          <DialogContent>
            <img src={getImageUrl()} alt="" />
          </DialogContent>
        </Dialog>
      )}
      <YesOrNoDialog
        open={showDeleteDialog}
        text={Translate.get("DeletePictureQuestion")}
        onYes={onDeleteImageYesClick}
        onNo={() => setShowDeleteDialog(false)}
        onCancel={() => setShowDeleteDialog(false)}
      />
    </>
  );
};

export default EPortAvatar;
