import { Box, Paper, Typography, Tooltip } from '@mui/material';
import DotMenu from '../DotMenu';
import AttachmentIcon from '@mui/icons-material/Attachment';

export default function SkillItem({
  isSelected,
  onClick,
  isSmall,
  imageUrl,
  label,
  isFailed,
  extraActions,
  tooltip,
  disabled,
  gotDoc: attachedFiles = false,
}) {
  const connectedDocsStr = (files) => {
    if (files) {
      return files.map((x) => x.originalFileName).join(', ');
    } else {
      return '';
    }
  };

  const getImgStyle= (files) =>
    files && (!Array.isArray(files) || files.length > 0) ? {
      marginTop: "-.75rem" // dra bort för attachment ikonens plats 
    } : {};

  // TODO Är transform nedan ok? LAgt så att position fixed funkar som jag vill
  return (
    <Tooltip
      arrow
      title={tooltip ?? ''}
      {...(!tooltip
        ? {
            disableHoverListener: true,
            disableFocusListener: true,
            disableTouchListener: true,
          }
        : {})}
    >
      <Paper
        className={
          'skillItem' +
          (isSelected ? ' selected' : '') +
          (disabled ? ' disabled' : '') +
          (isFailed ? ' failed' : '') +
          (isSmall ? ' small' : '') 
        }
        elevation={0}
        {...(!disabled ? { onClick } : {})}
        {...(extraActions && extraActions.length > 0
          ? { sx: { transform: 'translateZ(0)' } }
          : {})}
      >
        {attachedFiles && (!Array.isArray(attachedFiles) || attachedFiles.length > 0) && (
          <Tooltip
            title={connectedDocsStr(attachedFiles)}
            placement="right"
          >
            <AttachmentIcon />
          </Tooltip>
        )}
        {imageUrl && (
          <img style={getImgStyle(attachedFiles)}
            className={
              'skillImage' +
              (disabled ? ' disabled' : '') +
              (isSmall ? ' small' : '')
            }
            alt={''}
            src={imageUrl}
          />
        )}
        <Typography
          //variant="body2"
          fontSize={'14px'}
          sx={{
            color: 'text.secondary',
            lineHeight: '1',
          }}
        >
          {label}
        </Typography>
        {extraActions && extraActions.length > 0 && (
          <Box sx={{ position: 'fixed', top: '2px', right: '5px' }}>
            <DotMenu content={extraActions} horizontal />
          </Box>
        )}
      </Paper>
    </Tooltip>
  );
}
