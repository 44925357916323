// material
import { styled } from '@mui/material/styles';
import { Box, Container, Typography, useMediaQuery } from '@mui/material';
// components
import Page from '../components/Page';
import Translate from '../utils/Translate';
import { ForgotPasswordForm } from '../components/authentication/register';


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {
  const smallScreen = useMediaQuery('(max-width:700px)');

  return (
    <RootStyle className={window.global.isKs ? smallScreen ? "loginPagePermitsMobile" : "loginPagePermits" : "loginPage"}>

      <Container sx={{display:'flex',minHeight: '100vh'}}>
        <ContentStyle className="loginBox"  sx={{minHeight: 'auto'}}>
         <Box sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
            {Translate.get('ForgotYourPassword')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
            {Translate.get('ResetLinkPassword')}
            </Typography>
          </Box>


          <ForgotPasswordForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
