export function sanitizeFileName(filename) {
    const parts = splitFilenameExtension(filename.trim());
    return parts.name
        .trim()
        .replace(/[^a-zåäöA-ZÅÄÖ0-9_-]/g, "_")
        .replace(/_{2,}/g, "_")
        + parts.ext;
  }

  export function splitFilenameExtension(fileName) {
    const splitIdx = fileName.lastIndexOf('.')
    return {
        name: splitIdx > 0 ? fileName.substring(0, splitIdx) : fileName,
        ext: splitIdx > 0 ? fileName.substring(splitIdx) : ""
    }
  }