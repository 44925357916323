import { Box, Typography, Stack, Avatar, Chip } from "@mui/material";
import ConditionalWrapper from "./ConditionalWrapper";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import BusinessIcon from "@mui/icons-material/Business";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import { stringToColor } from "../utils/formatString";
import Translate from "../utils/Translate";
import { actorTypes } from "../utils/actorSearch";

export default function ActorAvatar({
  name,
  email,
  extraFields,
  profilePictureUrl,
  actorType, // om actorType finns, visa ett "chip" med ikon och text för actorType
  vertical,
  link,
  align = "center",
  disabled,
  hideImage,
}) {
  // TODO det finns två till ställen so manvänder className="avatarInfo",
  // men sidan krashade så jag kunde inte komma åt dom. Se över om dom
  // också ska använda denna komponenten istället för egen kod

  const actorTypeIcon = 
    actorType === actorTypes.client ? <BusinessIcon />
    : actorType === actorTypes.contact ? <HeadsetMicIcon />
      : actorType === actorTypes.student ? <PersonIcon />
        : actorType === actorTypes.teacher ? <SchoolIcon />
          : actorType === actorTypes.externalInstructor ? null // fyll på här senare om det behövs
            : actorType === actorTypes.privateClient ? null
              :null;

  const actorTypeTranslationKey =
    actorType === actorTypes.client ? "Company"
      : actorType === actorTypes.contact ? "Contact"
        : actorType === actorTypes.student ? "Student"
          : actorType === actorTypes.teacher ? "Instructor"
          : null; 

  let avatarSx = {
    bgcolor: stringToColor(name),
    ...(vertical ? { margin: "auto" } : {}),
  };

  return (
    <ConditionalWrapper
      condition={link}
      wrapper={(children) => (
        <a className="RemoveHyperLinkDecorations" href={link}>
          {children}
        </a>
      )}
    >
      <Stack
        sx={disabled ? { opacity: 0.3 } : {}}
        direction={vertical ? "column" : "row"}
        spacing={2}
        alignItems={align}
      >
        {!hideImage && (
          <Avatar alt={name} src={profilePictureUrl} sx={avatarSx}>
            {/* visa intial om det inte finns någon url */}
            {(profilePictureUrl && null) ||
              (name?.length > 0 && name[0]) ||
              null}
          </Avatar>
        )}
        <Box
          className="avatarInfo"
          sx={vertical ? { margin: "auto", textAlign: "center" } : {}}
        >
          <Box>{name}</Box>
          {actorType && (
            <Chip
              sx={{ width: "8rem" }}
              icon={actorTypeIcon}
              size="small"
              label={Translate.get(actorTypeTranslationKey)}
            />
          )}
          {email && (
            <Box>
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                }}
              >
                {email}
              </Typography>
            </Box>
          )}
        {/* Borttagen vertical regel mao: vertical && extraFields && */}
          {extraFields &&
            extraFields.map((field, i) => (
              <Box key={i}>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                  }}
                >
                  {field}
                </Typography>
              </Box>
            ))}
        </Box>
      </Stack>
    </ConditionalWrapper>
  );
}
