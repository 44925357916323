import download from "downloadjs";
import Translate from "../../utils/Translate";
import { eLearningTypes } from "../../utils/eLearningTypes";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { sanitizeFileName } from "../../utils/filename";
import { formatDate } from "../../utils/dateTimeFormat";
import { actorTypes } from "../../utils/actorSearch";

 export function getSendPasswordEmailDialogPropertiesForCourseBookings(api, courseId, courseBookings, onEmailsSent, onCancel, showEmailsSuccessMessage) {
  const findCourseBookingByContactActorId = (actorId) => 
    courseBookings.find((cb) => cb.contactActorId === actorId);

  return getGroupEmailDialogPropertiesForCourseBookings(
    Translate.get("SendPassword"),
    courseId,
    courseBookings,
    false,
    async (actorId) => { 
      const courserBooking = findCourseBookingByContactActorId(actorId);
      const resp = await api.fetch(`${process.env.REACT_APP_MAIN_URL}licenses/elearning/booking/${courserBooking.courseBookingId}/invitation/to-contact`, null, "GET");
      return {
        isSuccessful: resp.isSuccessful,
        errorMessageTranslationKey: resp.errorMessageTranslationKey,
        subject: resp.emailSubject,
        htmlBody: resp.htmlBody,
        attachmentImages: resp.attachementImages // TODO: misspelled serverside
      }
    },
    null,
    (sendEmailRequest) => {
      const request = {
        emails: sendEmailRequest.contacts.map(email => ({
                      receiverEmail: email.contactActorEmail,
                      receiverName: email.contactActorName, 
                      courseBookingId: email.courseBookingId,
                      //languageCode,
                      emailSubject: email.subject,
                      htmlBody: email.htmlBody,
                      eLearningLicenses: email.courseParticipants.map((cp) => ({ eLearningLicenseId: cp.courseParticipantELearningLicenseId }))
        }))
      };
      return api.fetch(
        `${process.env.REACT_APP_MAIN_URL}licenses/elearning/invitations/to-contacts`,
        request,
        "POST")
    },
    [
      downloadELearningPdfMenuItem({
        menuItemText: Translate.get("DownloadPasswords"),
        makeApiRequest: (actorId) => {
          return api.fetchBlob(`${process.env.REACT_APP_MAIN_URL}licenses/elearning/pdf/all/${findCourseBookingByContactActorId(actorId)?.courseBookingId}`);
        },
        getFileName: () => Translate.get("PasswordsConcatenated")
      })],
    onEmailsSent,
    onCancel,
    showEmailsSuccessMessage
  );
}

export function getSendCourseInvitaitonEmailDialogPropertiesForCourseBookings(api, courseId, courseFromDate, courseBookings, onEmailsSent, onCancel, showEmailsSuccessMessage) {
  window.analyticsEvent("eport_xtras", "eport_course_invitation");

  const findCourseBookingByContactActorId = (actorId) => 
    courseBookings.find((cb) => cb.contactActorId === actorId);

  const findCourseBookingByCourseParticipantActorId = (actorId) => 
    courseBookings.find(cb => cb.courseParticipants.some(cp => cp.courseParticipantActorId === actorId));

  const findCourseParticipantbyActorId = (actorId) => 
    courseBookings.flatMap(cb => cb.courseParticipants)
      .find(cp => cp.courseParticipantActorId === actorId);

  return getGroupEmailDialogPropertiesForCourseBookings(
    Translate.get("SendCourseInvitation"),
    courseId,
    courseBookings,
    true,
    async (actorId) => {
      const resp = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/email/contact/preview`, { 
          courseId, 
          courseBookingId: findCourseBookingByContactActorId(actorId)?.courseBookingId,
         });
      
      return {
        isSuccessful: resp.isSuccessful,
        errorMessageTranslationKey: resp.errorMessageTranslationKey,
        subject: resp.emailSubject,
        htmlBody: resp.htmlBody?.html,
        attachmentImages: resp.htmlBody?.attachments.map(x => ({
          name: x.contentId, 
          url: `data:${x.contentType};base64,${x.dataBase64}`
        }))
      };
    },
    async (actorId, actor) => {
      const booking = findCourseBookingByCourseParticipantActorId(actorId);
      const participant = findCourseParticipantbyActorId(actorId);

        const previewRequest = {
          courseId,
          courseBookingId: booking.courseBookingId,
          courseParticipantId: participant.courseParticipantId,
        };
        const resp = await api.fetch(`${process.env.REACT_APP_MAIN_URL}courses/email/student/preview`, previewRequest);
        return {
          isSuccessful: resp.isSuccessful,
          errorMessageTranslationKey: resp.errorMessageTranslationKey,
          subject: resp.emailSubject,
          htmlBody: resp.htmlBody?.html,
          attachmentImages: resp.htmlBody?.attachments.map(x => ({
            name: x.contentId, 
            url: `data:${x.contentType};base64,${x.dataBase64}`
          }))
        };
    },
    (req) => {
      const sendBothContactsAndParticipants = req.courseParticipants?.length > 0;
      const sendEmailsRequest = {
        courseId,
        contacts: req.contacts.map((contactData) => {
          return {
            courseBookingId: contactData.courseBookingId,
            subject: contactData.subject,
            htmlBody: contactData.htmlBody,
            courseParticipantIds: contactData.courseParticipants.map((x) => x.courseParticipantId),
          }
        }),
        courseParticipants: req.courseParticipants
      };

      
      return !sendBothContactsAndParticipants 
      ? api.fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/email/contacts/coursesummonings`,
        sendEmailsRequest,
        "POST")
      : api.fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/email/coursesummonings`,
        sendEmailsRequest,
        "POST")
    },
    [
      downloadELearningPdfMenuItem({
        api,
        isVisible: (actorId, actor) => actor.actorType !== actorTypes.contact,
        menuItemText: Translate.get("Download"),
        makeApiRequest: (actorId) => {
          const actor = findCourseParticipantbyActorId(actorId);
          const request = {
            courseParticipantId: actor.courseParticipantId,
            courseId: courseId,
            courseBookingId: findCourseBookingByCourseParticipantActorId(actorId).courseBookingId,
          };
          return api.fetchBlob(`${process.env.REACT_APP_MAIN_URL}courses/pdf/coursesummoning`, request);
        },
        getFileName: (actorId) => {
          const actor = findCourseParticipantbyActorId(actorId);
          const beginning = Translate.get("CourseSummoning")
          const actorName = `${(actor && " " + actor?.courseParticipantActorName)}`;
          const date = `${(courseFromDate && " " + formatDate(courseFromDate))}`;
          return sanitizeFileName(`${beginning}${actorName}${date}.pdf`);
        }
      })],
    onEmailsSent,
    onCancel,
    showEmailsSuccessMessage
  );
}

export function getSendPasswordEmailDialogPropertiesForStudents(api, companyActorId, companyName, actors, eLearningType, onEmailsSent, onCancel, showEmailsSuccessMessage) {
    const findActorByActorId = (actorId) =>
      actors.find((actor) => actor.actorId === actorId);
    return getGroupEmailDialogPropertiesForStudents(
      Translate.get("SendPassword"),
      companyActorId, 
      companyName,
      actors,
      async (actorId) => {
        const eLearningLicenseId = findActorByActorId(actorId)?.eLearningLicenseId;
        const resp = await api.fetch(`${process.env.REACT_APP_MAIN_URL}licenses/elearning/${eLearningLicenseId}/invitation/to-student`, null, "GET");
        return {
          isSuccessful: resp.isSuccessful,
          errorMessageTranslationKey: resp.errorMessageTranslationKey,
          subject: resp.emailSubject,
          htmlBody: resp.htmlBody,
          attachmentImages: resp.attachementImages // TODO: misspelled serverside
        }
      },
      (sendEmailRequest) => api.fetch(
        `${process.env.REACT_APP_MAIN_URL}licenses/elearning/invitations/to-students`,
        sendEmailRequest
      ),
      [
        downloadELearningPdfMenuItem({
          menuItemText: Translate.get("DownloadAttachment"),
          makeApiRequest: (actorId) => {
            return api.fetchBlob(`${process.env.REACT_APP_MAIN_URL}licenses/elearning/pdf/${findActorByActorId(actorId)?.eLearningLicenseId}`);
          },
          getFileName: (actorId) => sanitizeFileName(`${(Translate.get(`HereIsYourPersonalCode${eLearningTypes[eLearningType]}`))} ${findActorByActorId(actorId)?.actorName}.pdf`)
      })],
      onEmailsSent,
      onCancel,
      showEmailsSuccessMessage
    );
}

export function getSendExtraExamEmailDialogPropertiesForStudents(api, companyActorId, companyName, actors, onEmailsSent, onCancel, showEmailsSuccessMessage) {
  const findActorByActorId = (actorId) =>
    actors.find((actor) => actor.actorId === actorId);
  return getGroupEmailDialogPropertiesForStudents(
    Translate.get("SendExtraExamEmailLabel"),
    companyActorId, 
    companyName,
    actors,
    async (actorId) => {
      const eLearningLicenseId = findActorByActorId(actorId)?.eLearningLicenseId;
      const resp = await api.fetch(`${process.env.REACT_APP_MAIN_URL}licenses/elearning/${eLearningLicenseId}/extra-exam-invitation/to-student`, null, "GET");
      return {
        isSuccessful: resp.isSuccessful,
        errorMessageTranslationKey: resp.errorMessageTranslationKey,
        subject: resp.emailSubject,
        htmlBody: resp.htmlBody,
        attachmentImages: resp.attachementImages // TODO: misspelled serverside
      }
    },
    (sendEmailRequest) => api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/elearning/extra-exam-invitations/to-students`,
      sendEmailRequest
    ),
    [],
    onEmailsSent,
    onCancel,
    showEmailsSuccessMessage
  );
}


export function getCourseInvitaitonEmailDialogPropertiesForStudents(api, companyActorId, companyName, courseId, courseBookingId, courseFromDate, actors, onEmailsSent, onCancel, showEmailsSuccessMessage) {
  window.analyticsEvent("eport_xtras", "eport_course_invitation");
  const findActorByActorId = (actorId) =>
    actors.find((actor) => actor.actorId === actorId);

  return getGroupEmailDialogPropertiesForStudents(
    Translate.get("SendCourseInvitation"),
    companyActorId,
      companyName,
      actors,
      async (actorId) => {
        const actor = findActorByActorId(actorId);

        const previewRequest = {
          courseId,
          courseBookingId,
          courseParticipantId: actor.courseParticipantId,
        };
        const resp = await api.fetch(`${process.env.REACT_APP_MAIN_URL}courses/email/student/preview`, previewRequest);
        return {
          isSuccessful: resp.isSuccessful,
          errorMessageTranslationKey: resp.errorMessageTranslationKey,
          subject: resp.emailSubject,
          htmlBody: resp.htmlBody?.html,
          attachmentImages: resp.htmlBody?.attachments.map(x => ({
            name: x.contentId, 
            url: `data:${x.contentType};base64,${x.dataBase64}`
          }))
        };
      },
      (req) => {
        const sendEmailsRequest = {
          courseId,
          courseParticipants: req.emails.map(({courseParticipantId, subject, htmlBody}) => 
          ({
              courseBookingId,
              courseParticipantId,
              subject,
              htmlBody,
          }))
        };

        return api.fetch(
          `${process.env.REACT_APP_MAIN_URL}courses/email/students/coursesummonings`,
          sendEmailsRequest,
          "POST")
      },
    [
      downloadELearningPdfMenuItem({
        api,
        menuItemText: Translate.get("Download"),
        makeApiRequest: (actorId) => {
          const actor = findActorByActorId(actorId);
          const request = {
            courseParticipantId: actor.courseParticipantId,
            courseId: courseId,
            courseBookingId: courseBookingId,
          };
          return api.fetchBlob(`${process.env.REACT_APP_MAIN_URL}courses/pdf/coursesummoning`, request);
        },
        getFileName: (actorId) => {
          const actor = findActorByActorId(actorId);
          const beginning = Translate.get("CourseSummoning")
          const actorName = `${(actor && " " + actor?.actorName)}`;
          const date = `${(courseFromDate && " " + formatDate(courseFromDate))}`;
          return sanitizeFileName(`${beginning}${actorName}${date}.pdf`);
        }
      })],
      onEmailsSent,
      onCancel,
      showEmailsSuccessMessage
  );
}

export function getOrderConfirmationEmailDialogProperties(api, companyActorId, companyName, orderId, actor, onEmailsSent, onCancel, showEmailsSuccessMessage) {
  // const findActorByActorId = (actorId) =>
  //   actors.find((actor) => actor.actorId === actorId);

  // vi använder getGroupEmailDialogPropertiesForStudents eftersom den har det som behövs - även om detta inte är ett studentmail
  return getGroupEmailDialogPropertiesForStudents(
    Translate.get("SendOrderConfirmation"),
    companyActorId,
      companyName,
      [actor],
      async () => {
        // const actor = findActorByActorId(actorId);

        const previewRequest = {
          orderId,
          receiverActorId: actor.actorId
        };
        const resp = await api.fetch(`${process.env.REACT_APP_MAIN_URL}orders/confirmation/email/preview`, previewRequest);
        return {
          isSuccessful: resp.isSuccessful,
          errorMessageTranslationKey: resp.errorMessageTranslationKey,
          subject: resp.emailSubject,
          htmlBody: resp.htmlBody?.html,
          attachmentImages: resp.htmlBody?.attachments.map(x => ({
            name: x.contentId, 
            url: x.dataBase64.startsWith('data:')
              ? x.dataBase64 // TODO: backend returnerar data url, inte som andra
              : `data:${x.contentType};base64,${x.dataBase64}` 
          }))
        };
      },
      async (req) => {
        const sendEmailsRequests = req.emails.map(({subject, htmlBody}) => 
          ({
              orderId,
              receiverActorId: actor.actorId,
              subject,
              htmlBody,
          }));
        
        for(const request of sendEmailsRequests) {
          await api.fetch(
            `${process.env.REACT_APP_MAIN_URL}orders/confirmation/email`,
            request,
            "POST");
        }
      },
    [],
    onEmailsSent,
    onCancel,
    showEmailsSuccessMessage
  );
}

function getGroupEmailDialogPropertiesForCourseBookings( 
  windowTitle,
  courseId,
  courseBookings,
  showBothContatctsAndStudents,  
  getContactMessagePreview,
  getStudentMessagePreview,
  sendEmails,
  extraMenuItems,
  onEmailsSent,
  onCancel,
  showEmailsSuccessMessage) {
  
  const findCourseBookingByContactActorId = (actorId) => 
    courseBookings.find((cb) => cb.contactActorId === actorId);

  const courseParticipants = courseBookings.flatMap(cb => cb.courseParticipants.map(cp => ({
    courseBookingId: cb.courseBookingId,
    courseParticipantId: cp.courseParticipantId,
    eLearningLicenseId: cp.eLearningLicenseId,
    courseParticipantActorId: cp.courseParticipantActorId })));
  
  const findCourseParticipantByCourseParticipantActorId = (actorId) => 
    courseParticipants.find((cp) => cp.courseParticipantActorId === actorId);

  const emailProperties = {
    title: windowTitle,
    recipientsGrouped: courseBookings.map((booking) => ({
      recpientGroupName: booking.companyName,
      recipients:[
        {
          actorId: booking.contactActorId,
          actorType: actorTypes.contact,
          email: booking.contactActorEmail,
          name: booking.contactActorName,
          profilePictureUrl: booking.contactActorProfilePictureUrl, 
        },
        ...showBothContatctsAndStudents   
          ? booking.courseParticipants
            .filter(participant => participant.courseParticipantActorId && participant.courseParticipantActorId > 0)
            .map(participant => ({
              actorId: participant.courseParticipantActorId,    
              email: participant.courseParticipantEmail,
              name: participant.courseParticipantActorName,
              profilePictureUrl: participant.courseParticipantProfilePictureUrl
          }))  : []
      ]
    })),
    getRecipientEmailPreview: (actorId, actor) => actor.actorType === actorTypes.contact 
      ? getContactMessagePreview(actorId)
      : getStudentMessagePreview(actorId),
    overrideOnSend: async (emails) => {
      const contacts = emails.filter(({actor}) => actor.actorType === actorTypes.contact);
      const participants = emails.filter(({actor}) => actor.actorType !== actorTypes.contact);

        // Kan användas för att skicka både mail till kontaktpersoner och kursdeltagare
        const sendEmailsRequest = {
          courseId,
          contacts: contacts.map(({receiverActorId, subject, htmlBody}) => {
            const courseBooking = findCourseBookingByContactActorId(receiverActorId);
            return {
              courseBookingId: courseBooking.courseBookingId,
              receiverEmail: courseBooking.contactActorEmail,
              receiverName: courseBooking.contactActorName,
              subject,
              htmlBody,
              courseParticipants: courseBooking.courseParticipants
            };
          }),
          courseParticipants: participants.map(({receiverActorId, subject, htmlBody}) => {
            const participant = findCourseParticipantByCourseParticipantActorId(receiverActorId);
            return {
              courseBookingId: participant.courseBookingId,
              courseParticipantId: participant.courseParticipantId,
              eLearningLicenseId: participant.eLearningLicenseId,
              subject, 
              htmlBody
            };
          }
          )
        }
        await sendEmails(sendEmailsRequest);
        if(showEmailsSuccessMessage) {
          showEmailsSuccessMessage(Translate.get("SendEmailSuccess"));
        }
        await onEmailsSent();
    },
    newActorParentActorId: courseBookings.length > 0 ? courseBookings[0].companyActorId : null,
    onClose: onEmailsSent,
    onCancel: onCancel,
    extraRecipientMenuItems: extraMenuItems
  }
  return emailProperties;
}

function getGroupEmailDialogPropertiesForStudents(
  windowTitle,
  companyActorId,
  companyName,
  actors,
  getStudentMessagePreview,
  sendEmails,
  extraMenuItems,
  onEmailsSent,
  onCancel,
  showEmailsSuccessMessage
) {

    const findActorByActorId = (actorId) =>
      actors.find((actor) => actor.actorId === actorId);

    const emailProperties = {
      title: windowTitle,
      recipientsGrouped: [{
        recpientGroupName: companyName,
        recipients: actors.map((actor) => 
          ({
            actorId: actor.actorId,
            email: actor.actorEmail,
            name: actor.actorName,
            profilePictureUrl: actor.profilePictureUrl 
          }))
      }],
      getRecipientEmailPreview: (actorId) => getStudentMessagePreview(actorId),
      // här använder vi overrideOnSend för att skicka alla mailen med ett anrop till backend
      // sendRecipientEmail å andra sidan gör ett anrop till backend per mottagare
      overrideOnSend: async (emails) => {
        const sendEmailsRequest = { 
          emails: emails.map(({receiverActorId, subject, htmlBody}) => {
          const student = findActorByActorId(receiverActorId);
          return {
              receiverEmail: student.actorEmail,
              receiverName: student.actorName,
              eLearningLicenseId: student.eLearningLicenseId ? student.eLearningLicenseId : undefined,
              courseBookingId: student.courseBookingId ? student.courseBookingId : undefined,
              courseParticipantId: student.courseParticipantId ? student.courseParticipantId : undefined,
              subject,
              htmlBody
            }
          })
        };

        await sendEmails(sendEmailsRequest);
        if(showEmailsSuccessMessage) {
          showEmailsSuccessMessage(Translate.get("SendEmailSuccess"));
        }
        await onEmailsSent();
      },
      newActorParentActorId: companyActorId,
      onClose: onEmailsSent,
      onCancel: onCancel,
      extraRecipientMenuItems: extraMenuItems
    }

    return emailProperties;
}

const downloadELearningPdfMenuItem = ({menuItemText, makeApiRequest, getFileName, isVisible}) => {
  async function downloadPdf(actorId)
  {
      const fileName = getFileName(actorId);
      const blob = await makeApiRequest(actorId);
      download(blob, fileName, "application/pdf");
  }

  return {
    isVisible,
    icon: <PictureAsPdfIcon />,
    text: menuItemText,
    onClick: async (recipient) => downloadPdf(recipient.actorId)
    
  };
}
  