import { useTheme, useMediaQuery, Box } from "@mui/material";
import { useState, useCallback, useEffect, useRef } from "react";
import Translate from "../../utils/Translate";
import { accessKeys, hasAccess } from "../../utils/userAccess";
import { useApi } from "../../utils/Api";
import { useNavigate, useLocation } from "react-router-dom";
import { actorSearch, actorCompanyTypes } from "../../utils/actorSearch";
import YesOrNoDialog from "../YesOrNoDialog";
import Preloader from "../Preloader";
import { AlertDialog } from "../AlertDialog";
import { isFreightPartType, partType } from "../../utils/part";
import OrderFormAdvanced from "./OrderFormAdvanced";
import OrderFormSimple from "./OrderFormSimple";
import download from "downloadjs";
import AddressDialog from "./AddressDialog";
import OrderLineDialog from "./OrderLineDialog";
import FreightDialog from "./FreightDialog";
import { orderStatus } from "../../utils/order";
import { useReactToPrint } from "react-to-print";
import AddressPrintView from "./AddressPrintView";
import GroupEmailDialog from "../emails/GroupEmailDialog";
import { addressType } from "../../utils/address";
import OrderSaveQuestionDialog from "./OrderSaveQuestionDialog";
import { documentType, mediaType } from "../../utils/sentCommunication";

// We use IDs like crypto.RandomUUID() here in frontend for new orderLines
function isDummyOrderLineId(orderLineId) {
  return typeof orderLineId !== "number";
}

function getDefaultDeliveryAddress(addresses) {
  return addresses.find(
    (adr) =>
      adr.actorAddressType === addressType.delivery ||
      adr.actorAddressType === addressType.postal
  );
}

function getDefaultInvoiceAddress(addresses) {
  return addresses.find((adr) => adr.actorAddressType === addressType.billing);
}

function buildOrderDto(order, orderLines) {
  const hasTempAddress = order.deliveryAddressId === -1; // -1 means tempAddress
  const deliveryAddressId = hasTempAddress ? null : order.deliveryAddressId;
  const tempAddress = hasTempAddress ? order.tempAddress : null;

  return {
    ...order,
    deliveryAddressId: deliveryAddressId,
    tempAddress: tempAddress,
    orderLines: orderLines.map((ol) => ({
      ...ol,
      orderLineId: !isDummyOrderLineId(ol.orderLineId) ? ol.orderLineId : 0,
    })),
  };
}

export default function OrderFormBase({ companyActorId, orderId, onClose }) {
  if (companyActorId && orderId) {
    throw new Error("Use either actorId or orderId");
  }

  const [useAdvancedOrderForm] = useState(hasAccess(accessKeys.isMASystem));
  const [order, setOrder] = useState({ canUpdate: true });
  const [orderLines, setOrderLines] = useState(
    useAdvancedOrderForm
      ? []
      : [{ orderLineId: crypto.randomUUID(), status: orderStatus.registered }]
  );
  const [orderCommunications, setOrderCommunications] = useState([]);
  const [hasOrderChanged, setHasOrderChanged] = useState(
    orderId ? false : true
  );
  const [hasInitialized, setHasInitialized] = useState(false);
  const [company, setCompany] = useState(null);
  const [receiverCompany, setReceiverCompany] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [receiverContacts, setReceiverContacts] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [parts, setParts] = useState([]);
  const [availableLicenseCounts, setAvailableLicenseCounts] = useState({});
  const [companyDefaultCustomerOrderNo, setCompanyDefaultCustomerOrderNo] =
    useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [longErrorMessage, setLongErrorMessage] = useState(null);
  const [isServiceAgreement, setIsServiceAgreement] = useState(false);
  const [isEPortLicense, setIsEPortLicense] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [receiverCompanyAddresses, setReceiverCompanyAddresses] = useState([]);
  const [modesOfTransport, setModesOfTransport] = useState([]);
  const [deliveryAgreements, setDeliveryAgreements] = useState([]);
  const [paymentAgreements, setPaymentAgreements] = useState([]);
  const [showTempAddressDialog, setShowTempAddressDialog] = useState(false);
  const [editOrderLineId, setEditOrderLineId] = useState(null);
  const [saveCapabilities, setSaveCapabilities] = useState(null);
  const [showFreightDialog, setShowFreightDialog] = useState(false);
  const [showNeedToSaveOrderQuestion, setShowNeedToSaveOrderQuestion] =
    useState();
  const [
    showEmailOrderConfirmationDialog,
    setShowEmailOrderConfirmationDialog,
  ] = useState(false);
  const [useSeparateReceiverCompany, setUseSeparateReceiverCompany] =
    useState(false);
  const [useSeparateInvoiceReference, setUseSeparateInvoiceReference] =
    useState(false);
  const [useTempReference, setUseTempReference] = useState(false);

  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  const theme = useTheme();
  const isBig = useMediaQuery(theme.breakpoints.up("sm"));

  const deliveryAddressPrintRef = useRef();
  const handlePrintDeliveryAddress = useReactToPrint({
    content: () => deliveryAddressPrintRef.current,
  });

  const handleAvailableLicenseCountChanged = useCallback(
    (newCount, eLearningType) =>
      setAvailableLicenseCounts((prevCounts) => ({
        ...prevCounts,
        [eLearningType]: newCount,
      })),
    []
  );

  const handleOrderFieldChanged = useCallback((fieldName, fieldValue) => {
    setHasOrderChanged(true);
    setOrder((prevOrder) => ({
      ...prevOrder,
      [fieldName]: fieldValue,
    }));
  }, []);

  const handleSimpleOrderLineFieldChanged = useCallback(
    (orderLineId, fieldName, fieldValue) => {
      setHasOrderChanged(true);
      setOrderLines((prev) => {
        const index = prev.findIndex((ol) => ol.orderLineId === orderLineId);
        if (index !== -1) {
          return prev.with(index, {
            ...prev[index],
            [fieldName]: fieldValue,
          });
        } else {
          return prev;
        }
      });
    },
    []
  );

  const handleDeliveryAddressSelected = useCallback((deliveryAddressId) => {
    if (deliveryAddressId === -1) {
      setShowTempAddressDialog(true);
    }
    setHasOrderChanged(true);
    setOrder((prevOrder) => ({
      ...prevOrder,
      deliveryAddressId: deliveryAddressId,
    }));
  }, []);

  const handleTempAddressChanged = useCallback((updatedTempAddress) => {
    setHasOrderChanged(true);
    setOrder((prevOrder) => ({
      ...prevOrder,
      tempAddress: { ...prevOrder.tempAddress, ...updatedTempAddress },
    }));
  }, []);

  const handleSetUseTempReference = useCallback((newValue) => {
    setUseTempReference(newValue);
    if (newValue) {
      // tempReferenceText "replaces" receiverActorRefId
      setOrder((prev) => ({ ...prev, receiverActorRefId: null }));
    } else {
      setOrder((prev) => ({ ...prev, tempReferenceText: null }));
    }
  }, []);

  const handleAddOrderLine = useCallback(() => {
    const tempOrderLineId = crypto.randomUUID();
    const newOrderLine = {
      orderLineId: tempOrderLineId,
      status: orderStatus.registered,
      canUpdate: true,
      isTempOrderLine: true,
    };
    setOrderLines((prev) => [...prev, newOrderLine]);
    setEditOrderLineId(tempOrderLineId);
    setHasOrderChanged(true);
  }, []);

  const handleEditOrderLine = useCallback((orderLineId) => {
    setEditOrderLineId(orderLineId);
  }, []);

  const handleRemoveOrderLine = useCallback((orderLineId) => {
    setOrderLines((prev) => {
      const index = prev.findIndex((ol) => ol.orderLineId === orderLineId);
      if (index !== -1) {
        if (isDummyOrderLineId(orderLineId)) {
          // Dummy orderLines have not yet been saved in DB, and
          // hence we can simply delete them rather than cancel them
          return prev.toSpliced(index, 1);
        } else {
          return prev.with(index, {
            ...prev[index],
            qty: 0,
            status: orderStatus.canceled,
          });
        }
      }
      return prev;
    });
    setHasOrderChanged(true);
  }, []);

  const handleAddOrEditFreight = useCallback(() => {
    setShowFreightDialog(true);
  }, []);

  const handleComputeFreight = useCallback(
    async (order, orderLines) => {
      if (!order.freightId) {
        return false;
      }
      if (!order.actorId) {
        return false;
      }

      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}orders/freights/compute`,
        buildOrderDto(order, orderLines),
        "POST"
      );
      if (response.isSuccessful) {
        // Here we simply replace the exisitng freight orderLines with the updated ones.
        // The updated ones maintain the same orderLineId etc. so changes to price or
        // status will update correctly when saving the order.
        const updatedFreightOrderLines = response.freightOrderLines.map(
          (ol) => ({
            ...ol,
            orderLineId:
              ol.orderLineId === 0 ? crypto.randomUUID() : ol.orderLineId,
          })
        );
        setOrderLines((prev) => [
          ...prev.filter((ol) => !isFreightPartType(ol.part.partType)),
          ...updatedFreightOrderLines,
        ]);
        setHasOrderChanged(true);
        return true;
      } else {
        return false;
      }
    },
    [api]
  );

  const handleFreightSelected = useCallback(
    async (freightId, weight, fixedPrice) => {
      const isOk = await handleComputeFreight(
        { ...order, freightId, weight, freightFixedPrice: fixedPrice },
        orderLines
      );
      if (isOk) {
        setOrder((prev) => ({
          ...prev,
          freightId,
          weight,
          freightFixedPrice: fixedPrice,
        }));
      }
      setShowFreightDialog(false);
    },
    [handleComputeFreight, order, orderLines]
  );

  const loadOrderCommunications = useCallback(
    async (orderId) => {
      if (!useAdvancedOrderForm) {
        return;
      }
      const response = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}orders/${orderId}/communications`,
        false,
        "GET",
        (response) => response && Array.isArray(response)
      );
      if (response && Array.isArray(response)) {
        setOrderCommunications(response);
      }
    },
    [api, useAdvancedOrderForm]
  );

  const loadModesOfTransport = useCallback(async () => {
    if (!useAdvancedOrderForm) {
      return;
    }
    const data = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}orders/modesoftransport`
    );
    setModesOfTransport(data.modesOfTransport);
    const defaultModeOfTransport = data.modesOfTransport.find(
      (x) => !!x.isDefaultValue
    );
    if (defaultModeOfTransport) {
      setOrder((prev) => ({
        ...prev,
        modeOfTransport:
          orderId || prev.orderId || prev.modeOfTransport
            ? prev.modeOfTransport
            : defaultModeOfTransport.ePortCodeId,
      }));
    }
  }, [api, orderId, useAdvancedOrderForm]);

  const loadDeliveryAgreements = useCallback(async () => {
    if (!useAdvancedOrderForm) {
      return;
    }
    const data = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}orders/deliveryagreements`
    );
    setDeliveryAgreements(data.deliveryAgreements);
    const defaultDeliveryAgreement = data.deliveryAgreements.find(
      (x) => !!x.isDefaultValue
    );
    if (defaultDeliveryAgreement) {
      setOrder((prev) => ({
        ...prev,
        deliveryAgreement:
          orderId || prev.orderId || prev.deliveryAgreement
            ? prev.deliveryAgreement
            : defaultDeliveryAgreement.ePortCodeId,
      }));
    }
  }, [api, orderId, useAdvancedOrderForm]);

  const loadPaymentAgreements = useCallback(async () => {
    if (!useAdvancedOrderForm) {
      return;
    }
    const data = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}orders/paymentagreements`
    );

    setPaymentAgreements(data.paymentAgreements);
    const defaultPaymentAgreement = data.paymentAgreements.find(
      (x) => !!x.isDefaultValue
    );
    if (defaultPaymentAgreement) {
      setOrder((prev) => ({
        ...prev,
        paymentAgreement:
          orderId || prev.orderId || prev.paymentAgreement
            ? prev.paymentAgreement
            : defaultPaymentAgreement.ePortCodeId,
      }));
    }
  }, [api, orderId, useAdvancedOrderForm]);

  const loadCurrencies = useCallback(async () => {
    if (!useAdvancedOrderForm) {
      return;
    }
    const data = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}orders/currencies`
    );
    setCurrencies(data);

    const defaultCurrency = data.find((x) => !!x.isDefault);
    if (defaultCurrency) {
      setOrder((prev) => ({
        ...prev,
        currency:
          orderId || prev.orderId || prev.currency
            ? prev.currency
            : defaultCurrency.code,
      }));
    }
  }, [api, orderId, useAdvancedOrderForm]);

  const loadActorOrderInfo = useCallback(
    async (actorId) => {
      if (!actorId) {
        return;
      }

      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/${actorId}/orderinfo`,
        false,
        "GET"
      );

      if (response.isSuccessful) {
        setCompany((prevCompany) => ({
          ...prevCompany,
          defaultCustomerOrderNoRequired:
            response.defaultCustomerOrderNoRequired,
          defaultCurrency: response.defaultCurrency,
          isMAToyotaClient: response.isMAToyotaClient,
          isToyotaInvoice: response.isToyotaInvoice,
        }));
        setOrder((prev) => ({
          ...prev,
          paymentAgreement:
            !prev.orderId && response.paymentAgreement
              ? response.paymentAgreement
              : prev.paymentAgreement,
          currency: prev.orderId
            ? prev.currency
            : response.defaultCurrency ?? prev.currency,
        }));
        return response;
      }
    },
    [api]
  );

  const loadContacts = useCallback(
    async (actorId) => {
      if (!actorId) {
        return;
      }
      const loadedContacts = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}actors/clients/${actorId}/contacts`,
        false,
        "GET",
        (response) => response && Array.isArray(response)
      );
      if (loadedContacts && Array.isArray(loadedContacts)) {
        return loadedContacts;
      }
    },
    [api]
  );

  const loadAddresses = useCallback(
    async (actorId) => {
      if (!actorId) {
        return;
      }
      const loadedAddresses = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}actors/${actorId}/addresses`,
        false,
        "GET",
        (response) => response && Array.isArray(response)
      );
      if (loadedAddresses && Array.isArray(loadedAddresses)) {
        return loadedAddresses;
      }
    },
    [api]
  );

  const loadLatestOrder = useCallback(
    async (actorId) => {
      if (!actorId) {
        return;
      }
      const response = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}orders/latest/${actorId}`,
        false,
        "GET",
        (response) => (response ? response.isSuccessful : true)
      );
      if (response && response.isSuccessful) {
        return response;
      }
    },
    [api]
  );

  const loadSaveCapabilities = useCallback(async () => {
    const request = buildOrderDto(order, orderLines);
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}orders/save-capabilities`,
      request,
      "POST"
    );
    if (response.isSuccessful) {
      return response;
    }
  }, [api, order, orderLines]);

  const reuseDetailsFromLatestOrder = useCallback(
    async (actorId) => {
      if (!actorId) {
        return;
      }

      const latestOrder = await loadLatestOrder(actorId);
      if (
        latestOrder &&
        latestOrder.receiverActorId &&
        latestOrder.actorId !== latestOrder.receiverActorId &&
        !useSeparateReceiverCompany
      ) {
        setUseSeparateReceiverCompany(true);
        setOrder((prev) => ({
          ...prev,
          receiverActorId: null,
          deliveryAddressId: null,
          tempAddress: null,
        }));
      }

      if (
        latestOrder &&
        latestOrder.actorRefId &&
        latestOrder.invoiceActorRefId &&
        latestOrder.invoiceActorRefId !== latestOrder.actorRefId &&
        !useSeparateInvoiceReference
      ) {
        setUseSeparateInvoiceReference(true);
      }
    },
    [loadLatestOrder, useSeparateReceiverCompany, useSeparateInvoiceReference]
  );

  const loadCompanyActor = useCallback(
    async (actorId) => {
      const foundCompanies = await actorSearch(
        actorId,
        actorCompanyTypes,
        ["ActorId"],
        1,
        true
      );
      if (foundCompanies && foundCompanies.length === 1) {
        setOrder((prev) => ({
          ...prev,
          actorId: foundCompanies[0].actorId,
          customerOrderNo:
            orderId || prev.orderId || prev.customerOrderNo
              ? prev.customerOrderNo
              : foundCompanies[0].defaultCustomerOrderNo,
        }));
        setCompany(foundCompanies[0]);
        setCompanyDefaultCustomerOrderNo(
          foundCompanies[0].defaultCustomerOrderNo
        );
        loadActorOrderInfo(foundCompanies[0].actorId);
        const [loadedContacts, loadedAddresses] = await Promise.all([
          loadContacts(foundCompanies[0].actorId),
          loadAddresses(foundCompanies[0].actorId),
        ]);
        setContacts(loadedContacts ?? []);
        setAddresses(loadedAddresses ?? []);

        setOrder((prev) => ({
          ...prev,
          deliveryAddressId:
            orderId || prev.orderId
              ? prev.deliveryAddressId
              : getDefaultDeliveryAddress(loadedAddresses ?? [])
                  ?.actorAddressId,
          invoiceAddressId:
            orderId || prev.orderId
              ? prev.invoiceAddressId
              : getDefaultInvoiceAddress(loadedAddresses ?? [])?.actorAddressId,
        }));
      }
    },
    [loadActorOrderInfo, loadContacts, loadAddresses, orderId]
  );

  const getPrice = useCallback(
    async (partId, qty, actorId, overrideCurrency) => {
      const request = {
        partId: partId,
        actorId: actorId,
        qty: qty,
        currency: overrideCurrency ?? order.currency,
      };

      return await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}warehouse/parts/get-price`,
        request,
        "POST"
      );
    },
    [api, order.currency]
  );

  const getStUnits = useCallback(
    async (partId) => {
      return await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}warehouse/parts/${partId}/stunits`,
        false,
        "GET"
      );
    },
    [api]
  );

  const updateOrderLinePrice = useCallback(
    async (orderLine, actorId, overrideCurrency) => {
      if (!orderLine || !orderLine.partId) {
        return orderLine;
      }

      const response = await getPrice(
        orderLine.partId,
        orderLine.qty,
        actorId,
        overrideCurrency
      );
      if (response.isSuccessful) {
        return {
          ...orderLine,
          discountedPcsPrice: response.price,
          discount: response.discount,
          originalPcsPrice: response.originalPrice,
          price: response.price * orderLine.qty,
          vat: response.vatPercent,
        };
      } else {
        return orderLine;
      }
    },
    [getPrice]
  );

  const downloadOrderConfirmation = useCallback(async () => {
    const blob = await api.fetchBlob(
      `${process.env.REACT_APP_MAIN_URL}orders/confirmation/pdf/temp-order`,
      buildOrderDto(order, orderLines),
      "POST"
    );

    const filename = `${Translate.get("OrderConfirmation").replace(
      " ",
      "_"
    )}.pdf`;
    download(blob, filename, blob.type);
  }, [api, order, orderLines]);

  const downloadDeliveryNotification = useCallback(async () => {
    const blob = await api.fetchBlob(
      `${process.env.REACT_APP_MAIN_URL}orders/deliverynotification/pdf/temp-order`,
      buildOrderDto(order, orderLines),
      "POST"
    );

    const filename = `${Translate.get("DeliveryNotification").replace(
      " ",
      "_"
    )}.pdf`;
    download(blob, filename, blob.type);
  }, [api, order, orderLines]);

  const downloadDeliveryNote = useCallback(async () => {
    const blob = await api.fetchBlob(
      `${process.env.REACT_APP_MAIN_URL}orders/deliverynote/pdf/temp-order`,
      buildOrderDto(order, orderLines),
      "POST"
    );

    const filename = `${Translate.get("DeliveryNoteLabel").replace(
      " ",
      "_"
    )}.pdf`;
    download(blob, filename, blob.type);
  }, [api, order, orderLines]);

  const handleEmailOrderConfirmation = useCallback(() => {
    if (company.isMAToyotaClient && company.isToyotaInvoice) {
      // We shouldn't send an order confirmation in this case
      throw new Error("Sending order confirmation not allowed");
    }

    if (hasOrderChanged) {
      setShowNeedToSaveOrderQuestion(true);
    } else {
      setShowEmailOrderConfirmationDialog(true);
    }
  }, [hasOrderChanged, company]);

  const handleResetOrderLinePrices = useCallback(
    async (actorId, overrideCurrency) => {
      const updatedOrderLines = await Promise.all(
        orderLines.map(async (ol) => {
          return ol.partId && !isFreightPartType(ol.part.partType)
            ? await updateOrderLinePrice(ol, actorId, overrideCurrency)
            : ol;
        })
      );

      setOrderLines(updatedOrderLines);
      setHasOrderChanged(true);

      // We also need to update freight prices based on the updatedOrderLines prices
      await handleComputeFreight(
        { ...order, actorId, currency: overrideCurrency ?? order.currency },
        updatedOrderLines
      );
    },
    [order, orderLines, updateOrderLinePrice, handleComputeFreight]
  );

  const handleCompanySelected = useCallback(
    async (newSelectedCompany) => {
      setHasOrderChanged(true);
      setIsLoading(true);
      setOrder((prev) => ({
        ...prev,
        actorId: newSelectedCompany?.actorId,
        actorRefId: null,
        invoiceActorId: newSelectedCompany?.actorId,
        invoiceActorRefId: null,
        customerOrderNo: newSelectedCompany?.defaultCustomerOrderNo,
      }));
      setCompany(newSelectedCompany);
      setCompanyDefaultCustomerOrderNo(
        newSelectedCompany?.defaultCustomerOrderNo
      );
      const actorOrderInfo = await loadActorOrderInfo(
        newSelectedCompany?.actorId ?? 0
      );
      const [loadedContacts, loadedAddresses] = await Promise.all([
        loadContacts(newSelectedCompany?.actorId ?? 0),
        loadAddresses(newSelectedCompany?.actorId ?? 0),
      ]);
      setContacts(loadedContacts ?? []);
      setAddresses(loadedAddresses ?? []);

      setOrder((prev) => ({
        ...prev,
        invoiceAddressId: getDefaultInvoiceAddress(loadedAddresses ?? [])
          ?.actorAddressId,
      }));

      if (!useSeparateReceiverCompany) {
        setOrder((prev) => ({
          ...prev,
          receiverActorId: newSelectedCompany?.actorId,
          receiverActorRefId: null,
          deliveryAddressId: getDefaultDeliveryAddress(loadedAddresses ?? [])
            ?.actorAddressId,
          tempAddress: null,
        }));
      }

      await reuseDetailsFromLatestOrder(newSelectedCompany?.actorId);

      const currency = order.orderId
        ? order.currency
        : actorOrderInfo?.defaultCurrency ?? order.currency;
      await handleResetOrderLinePrices(
        newSelectedCompany?.actorId ?? 0,
        currency
      );

      setIsLoading(false);
    },
    [
      loadActorOrderInfo,
      loadContacts,
      loadAddresses,
      reuseDetailsFromLatestOrder,
      handleResetOrderLinePrices,
      useSeparateReceiverCompany,
      order,
    ]
  );

  const handleResetReceiverCompany = useCallback((resetToOrderCompany) => {
    setOrder((prev) => ({
      ...prev,
      receiverActorId: resetToOrderCompany ? prev.actorId : null,
      receiverActorRefId: resetToOrderCompany ? prev.actorRefId : null,
      deliveryAddressId: null,
    }));
    setReceiverCompany(null);
    setReceiverContacts(null);
    setReceiverCompanyAddresses(null);
    setHasOrderChanged(true);
  }, []);

  const handleResetInvoiceReference = useCallback((resetToOrderRef) => {
    setOrder((prev) => ({
      ...prev,
      invoiceActorRefId: resetToOrderRef ? prev.actorRefId : null,
    }));
    setHasOrderChanged(true);
  }, []);

  const loadReceiverCompanyData = useCallback(
    async (actorId) => {
      const [loadedContacts, loadedAddresses] = await Promise.all([
        loadContacts(actorId ?? 0),
        loadAddresses(actorId ?? 0),
      ]);
      setReceiverContacts(loadedContacts ?? []);
      setReceiverCompanyAddresses(loadedAddresses ?? []);
    },
    [loadContacts, loadAddresses]
  );

  const handleReceiverCompanySelected = useCallback(
    async (newSelectedCompany) => {
      setOrder((prevOrder) => ({
        ...prevOrder,
        receiverActorId: newSelectedCompany?.actorId,
        receiverActorRefId: null,
        deliveryAddressId: null,
      }));
      setReceiverCompany(newSelectedCompany);
      setHasOrderChanged(true);
      await loadReceiverCompanyData(newSelectedCompany?.actorId);
    },
    [loadReceiverCompanyData]
  );

  const handleContactSelected = useCallback(
    (event) => {
      const actorId = event.target.value;

      // Only update customerOrderNo automatically if it's a new order, or if it's empty
      if (!order.orderId || (order.canUpdate && !order.customerOrderNo)) {
        if (order.actorRefId > 0) {
          const previousContact = contacts.find(
            (c) => c.actorId === order.actorRefId
          );
          // Reset to company customerOrderNo if deselecting a contact
          if (
            previousContact.defaultCustomerOrderNo === order.customerOrderNo
          ) {
            setOrder((prev) => ({
              ...prev,
              customerOrderNo: companyDefaultCustomerOrderNo,
            }));
          }
        }

        const contact = contacts.find((c) => c.actorId === actorId);
        if (contact.defaultCustomerOrderNo) {
          setOrder((prev) => ({
            ...prev,
            customerOrderNo: contact.defaultCustomerOrderNo,
          }));
        }
      }

      setOrder((prev) => ({
        ...prev,
        actorRefId: actorId,
        receiverActorRefId: useSeparateReceiverCompany
          ? prev.receiverActorRefId
          : actorId,
        invoiceActorRefId: useSeparateInvoiceReference
          ? prev.receiverActorRefId
          : actorId,
      }));
      setHasOrderChanged(true);
    },
    [
      order,
      contacts,
      companyDefaultCustomerOrderNo,
      useSeparateReceiverCompany,
      useSeparateInvoiceReference,
    ]
  );

  const handleSaveOrderLine = useCallback(
    async (orderLine) => {
      const index = orderLines.findIndex(
        (ol) => ol.orderLineId === orderLine.orderLineId
      );
      if (index !== -1) {
        setOrderLines((prev) => prev.with(index, orderLine));
        setHasOrderChanged(true);

        // Also need to update the freight
        await handleComputeFreight(order, orderLines.with(index, orderLine));

        // Set ModeOfTransport Digital if all are eLearning
        if (order.canUpdate) {
          const allELearning = orderLines.every((ol, i) => {
            const currentOrderLine = index === i ? orderLine : ol;
            return (
              currentOrderLine.part.partType ===
                partType.resellableELearningLicense ||
              currentOrderLine.part.partType ===
                partType.resellableExtraFinalExam
            );
          });
          const digitalTransport = modesOfTransport.find(
            (t) => t.externalCodeValue === "DIG"
          );
          if (allELearning && digitalTransport) {
            setOrder((prev) => ({
              ...prev,
              modeOfTransport: digitalTransport.ePortCodeId,
            }));
          }
        }
      }
    },
    [order, orderLines, modesOfTransport, handleComputeFreight]
  );

  const getOrderConfirmationEmailPreview = useCallback(
    async (receiverActorId) => {
      const data = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}orders/confirmation/email/preview`,
        { orderId: order.orderId, receiverActorId },
        "POST"
      );

      if (data.isSuccessful) {
        return {
          subject: data.emailSubject,
          htmlBody: data.htmlBody.html,
          attachmentImages: data.htmlBody.attachments.map((a) => ({
            name: "logotype.png",
            url: a.dataBase64,
          })),
        };
      }
    },
    [api, order]
  );

  const sendOrderConfirmationEmail = useCallback(
    async (receiverActorId, subject, htmlBody) => {
      let request = {
        orderId: order.orderId,
        receiverActorId,
        subject,
        htmlBody,
      };

      // Don't want to show snackbar on error. Displaying the errors elsewhere
      return await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}orders/confirmation/email`,
        request,
        "POST",
        (_) => true
      );
    },
    [api, order]
  );

  useEffect(() => {
    async function loadData() {
      await Promise.all([
        loadDeliveryAgreements(),
        loadPaymentAgreements(),
        loadModesOfTransport(),
        loadCurrencies(),
      ]);
      if (companyActorId > 0) {
        await loadCompanyActor(companyActorId);
        setOrder((prev) => ({
          ...prev,
          receiverActorId: companyActorId,
          invoiceActorId: companyActorId,
        }));
        await reuseDetailsFromLatestOrder(companyActorId);
        setIsLoading(false);
      } else if (orderId > 0) {
        const response = await api.fetch(
          `${process.env.REACT_APP_MAIN_URL}orders/${orderId}`,
          false,
          "GET"
        );
        if (
          response.isSuccessful &&
          !useAdvancedOrderForm &&
          response.orderLines.length > 1
        ) {
          throw new Error("Multiple order lines not yet supported");
        }

        if (response.isSuccessful) {
          response.deliveryAddressId = response.tempAddress
            ? -1
            : response.deliveryAddressId;
          setOrder(response);
          setOrderLines(response.orderLines);
          setUseSeparateReceiverCompany(
            response.receiverActorId &&
              response.receiverActorId !== response.actorId
          );
          setUseSeparateInvoiceReference(
            response.actorRefId &&
              response.invoiceActorRefId &&
              response.actorRefId !== response.invoiceActorRefId
          );
          setUseTempReference(!!response.tempReferenceText);

          // Since we don't allow adding either of these kinds of parts from the order form
          // we only need to do these checks here at the initial load of an order.
          setIsServiceAgreement(
            response.orderLines.some(
              (ol) => ol.part?.partType === partType.serviceAgreement
            )
          );
          setIsEPortLicense(
            response.orderLines.some(
              (ol) => ol.part?.partType === partType.ePortProgamLicense
            )
          );

          if (!useAdvancedOrderForm) {
            // The list of parts are loaded separately. If they're already loaded
            // we don't change anything here, otherwise add a list with the current part.
            // Only OrderFormSimple uses parts list, while OrderFormAdvanced will search.
            setParts((prevParts) =>
              prevParts && prevParts.length > 0
                ? prevParts
                : response.orderLines.map((ol) => ol.part)
            );
          }

          setContacts((prevContacts) =>
            response.actorRefId > 0 &&
            !prevContacts.find((c) => c.actorId === response.actorRefId)
              ? [
                  ...prevContacts,
                  {
                    actorName: response.actorRefName,
                    actorId: response.actorRefId,
                  },
                ]
              : prevContacts
          );
          await loadCompanyActor(response.actorId);

          if (
            response.receiverActorId &&
            response.receiverActorId !== response.actorId
          ) {
            setReceiverCompany({
              actorId: response.receiverActorId,
              actorName: response.receiverActorName,
            });
            await loadReceiverCompanyData(response.receiverActorId);
          }

          await loadOrderCommunications(orderId);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
    if (!hasInitialized) {
      setHasInitialized(true);
      loadData();
    }
  }, [
    companyActorId,
    orderId,
    loadCompanyActor,
    loadPaymentAgreements,
    loadDeliveryAgreements,
    loadModesOfTransport,
    loadCurrencies,
    handleReceiverCompanySelected,
    loadReceiverCompanyData,
    reuseDetailsFromLatestOrder,
    api,
    useAdvancedOrderForm,
    hasInitialized,
  ]);

  useEffect(() => {
    async function loadParts() {
      // The advanced order form doesn't load parts, it will search instead.
      if (useAdvancedOrderForm) {
        return;
      }

      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}warehouse/parts`,
        false,
        "GET"
      );
      if (response && response.isSuccessful) {
        setParts(response.parts);

        // Set the first and only part as selected when creating a new order
        if (response.parts.length === 1 && !orderId) {
          const part = response.parts[0];
          setOrderLines((prevLines) =>
            prevLines.length === 1
              ? [{ ...prevLines[0], partId: part.partId }]
              : prevLines
          );
        }
      }
    }
    loadParts();
  }, [api, orderId, useAdvancedOrderForm]);

  async function addOrUpdateOrder(
    autoPick,
    autoFinish,
    orderConfirmationEmails
  ) {
    const request = {
      order: buildOrderDto(order, orderLines),
      orderConfirmationEmails: orderConfirmationEmails ?? [],
      autoPick: !!autoPick,
      autoFinish: !!autoFinish,
    };
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}orders`,
      request,
      "POST"
    );

    if (response.isSuccessful) {
      setOrder(response);
      setOrderLines(response.orderLines);
      setHasOrderChanged(false);
      return true;
    } else if (!orderId && !!response.orderId) {
      // If we are here it means the order was already created, but something went wrong.
      // And it couldn't be automatically deleted. Probably because it was autopicked already.
      if (!response.errorMessageTranslationKey.startsWith("Something")) {
        setLongErrorMessage(response.errorMessageTranslationKey);
      }
      return false;
    }
  }

  async function handleSaveOrSendOrder() {
    const loadedSaveCapabilities = await loadSaveCapabilities();
    if (!loadedSaveCapabilities) {
      // Something failed and we cannot continue. If there was an error it should've
      // already been displayed in loadSaveCapabilities, so no need to do anything more
      return;
    }

    const hasSomeSaveCapability =
      loadedSaveCapabilities.canAutoPick ||
      loadedSaveCapabilities.canAutoFinish ||
      loadedSaveCapabilities.autoShowOrderConfirmationDialog;
    if (!hasSomeSaveCapability) {
      const saved = await addOrUpdateOrder(false, false, null);
      if (saved) {
        closeAndReload();
      }
    } else {
      setSaveCapabilities(loadedSaveCapabilities);
    }
  }

  const partSearch = useCallback(
    async (searchText) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}warehouse/parts/search?q=${searchText}&sellable=true&forActorId=${order.actorId}`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        return response.parts;
      }
    },
    [api, order]
  );

  const companySearch = useCallback(async (searchText) => {
    const foundCompanies = await actorSearch(
      searchText,
      actorCompanyTypes,
      ["ActorId", "ActorName", "ExternalDataId"],
      150
    );
    if (foundCompanies) {
      return foundCompanies.map((company) => ({
        actorId: company.actorId,
        externalDataId: company.externalDataId,
        defaultCustomerOrderNo: company.defaultCustomerOrderNo,
        observationNote: company.observationNote,
        actorName: company.actorName,
        orgNo: company.orgNo,
        email: company.email,
        addressStreet: company.postalAddress?.actorAddressStreet2,
        addressZipCode: company.postalAddress?.actorAddressZipCode,
        addressCity: company.postalAddress?.actorAddressCity,
      }));
    }
  }, []);

  async function handlePartSelected(orderLineId, partId) {
    if (useAdvancedOrderForm) {
      // This is only used by OrderFormSimple
      throw new Error();
    }

    const part = parts.find((p) => p.partId === partId);
    const index = orderLines.findIndex((ol) => ol.orderLineId === orderLineId);
    if (index !== -1 && !!part) {
      const updatedOrderLine = await updateOrderLinePrice(
        { ...orderLines[index], partId: partId, part },
        company?.actorId ?? 0
      );
      setOrderLines(orderLines.with(index, updatedOrderLine));
      setHasOrderChanged(true);
    }
  }

  async function handleQtyChanged(orderLineId, qty) {
    if (useAdvancedOrderForm) {
      // This is only used by OrderFormSimple
      throw new Error();
    }

    const index = orderLines.findIndex((ol) => ol.orderLineId === orderLineId);
    if (index !== -1) {
      const updatedOrderLine = await updateOrderLinePrice(
        { ...orderLines[index], qty: qty },
        company?.actorId ?? 0
      );
      setOrderLines(orderLines.with(index, updatedOrderLine));
      setHasOrderChanged(true);
    }
  }

  function getPart(partId) {
    return parts.find((p) => p.partId === partId);
  }

  function getLearningType(partId) {
    let part = getPart(partId);
    if (part.eLearningType != null) {
      return part.eLearningType;
    }

    if (part.partType === 23) {
      return 1000;
    }

    return null;
  }

  function isELearningLicense(partId) {
    if (!partId) {
      return false;
    }

    let part = getPart(partId);
    if (part == null) {
      return false;
    }

    if (part.eLearningCode != null || part.partType === 23) {
      return true;
    }

    return false;
  }

  function getAvailableLicenseCount(partId) {
    const eLearningType = getLearningType(partId);
    return eLearningType ? availableLicenseCounts[eLearningType] : null;
  }

  function isExternalDataIdRequired() {
    return orderLines.some((ol) => isELearningLicense(ol.partId));
  }

  function getSelectedDeliveryAddressForPrint() {
    if (order.deliveryAddressId === -1) {
      return {
        street1: order.tempAddress?.street1,
        street2: order.tempAddress?.street2,
        zipCode: order.tempAddress?.cityCode,
        city: order.tempAddress?.city,
        country: order.tempAddress?.country,
      };
    } else {
      const selectedAddress = (
        useSeparateReceiverCompany ? receiverCompanyAddresses : addresses
      )?.find((adr) => adr.actorAddressId === order.deliveryAddressId);
      return selectedAddress
        ? {
            street1: selectedAddress.actorAddressStreet1,
            street2: selectedAddress.actorAddressStreet2,
            zipCode: selectedAddress.actorAddressZipCode,
            city: selectedAddress.actorAddressCity,
            country: selectedAddress.actorAddressCountry,
          }
        : {};
    }
  }

  function getInitialOrderConfirmationRecipients() {
    let recipients = [];

    const prevRecipients = orderCommunications
      .filter(
        (oc) =>
          oc.documentType === documentType.orderConfirmation &&
          oc.media === mediaType.email
      )
      .map((oc) => ({
        actorId: oc.actorId,
        name: oc.actorName,
        email: oc.actorEmail,
        profilePictureUrl: oc.profilePictureUrl,
        sentCommunication: { sendDate: oc.sendDate, bounced: oc.bounced },
      }));

    const actorRefIndex =
      order.actorRefId &&
      !prevRecipients.some((pr) => pr.actorId === order.actorRefId) &&
      contacts &&
      contacts.length > 0
        ? contacts.findIndex((c) => c.actorId === order.actorRefId)
        : -1;
    if (actorRefIndex !== -1) {
      const contact = contacts[actorRefIndex];
      const contactRecipient = {
        actorId: contact.actorId,
        name: contact.actorName,
        email: contact.email,
        profilePictureUrl: contact.profilePictureUrl,
      };
      recipients.push(contactRecipient);
    }

    recipients = [...recipients, ...prevRecipients];
    const recipientsGrouped = [
      {
        recpientGroupName: Translate.get("EmailReceivers"), // rubrik ovanför grupp
        recipients: recipients,
      },
    ];

    return recipientsGrouped;
  }

  function getOrderConfirmationDefaultOptions() {
    const recipients = [];

    const invoiceRefIndex =
      useSeparateInvoiceReference &&
      order.invoiceActorRefId &&
      contacts &&
      contacts.length > 0
        ? contacts.findIndex((c) => c.actorId === order.invoiceActorRefId)
        : -1;
    if (invoiceRefIndex !== -1) {
      recipients.push({ ...contacts[invoiceRefIndex] });
    }

    return recipients.map((a) => ({
      actorId: a.actorId,
      name: a.actorName,
      email: a.email,
      profilePictureUrl: a.profilePictureUrl,
    }));
  }

  // console.log("ORDER:", order);
  // console.log("LINES:", orderLines);

  return (
    <>
      {isLoading && <Preloader />}
      {showTempAddressDialog && (
        <AddressDialog
          addressTypeId={5}
          street1={order.tempAddress?.street1}
          street2={order.tempAddress?.street2}
          zipCode={order.tempAddress?.cityCode}
          city={order.tempAddress?.city}
          country={order.tempAddress?.country}
          onCancel={() => setShowTempAddressDialog(false)}
          onSave={(updatedAddress) => {
            handleTempAddressChanged({
              ...updatedAddress,
              cityCode: updatedAddress.zipCode,
            });
            setShowTempAddressDialog(false);
          }}
        />
      )}
      {editOrderLineId && (
        <OrderLineDialog
          orderLine={orderLines.find(
            (ol) => ol.orderLineId === editOrderLineId
          )}
          onCancel={() => {
            const isTempOrderLine = orderLines.find(
              (ol) => ol.orderLineId === editOrderLineId
            ).isTempOrderLine;
            if (isTempOrderLine) {
              handleRemoveOrderLine(editOrderLineId);
            }
            setEditOrderLineId(null);
          }}
          onSave={async (orderLine) => {
            if (editOrderLineId !== orderLine.orderLineId) {
              throw new Error("OrderLineId has changed");
            }
            await handleSaveOrderLine({ ...orderLine, isTempOrderLine: false });
            setEditOrderLineId(null);
          }}
          partSearch={partSearch}
          getPrice={getPrice}
          getStUnits={getStUnits}
          companyActorId={company?.actorId}
          currency={order.currency}
        />
      )}
      {showFreightDialog && (
        <FreightDialog
          isFirstTime={
            !orderLines.some((ol) => isFreightPartType(ol.part?.partType))
          }
          weight={order.weight}
          fixedPrice={order.freightFixedPrice}
          onCancel={() => setShowFreightDialog(false)}
          onSave={handleFreightSelected}
        />
      )}
      {showEmailOrderConfirmationDialog && (
        <GroupEmailDialog
          title={`${Translate.get("Send")} ${Translate.get(
            "OrderConfirmation"
          ).toLowerCase()}`}
          displayAddRecipient={true}
          recipientsGrouped={getInitialOrderConfirmationRecipients()}
          defaultOptions={getOrderConfirmationDefaultOptions()}
          newActorParentActorId={company.actorId}
          getRecipientEmailPreview={getOrderConfirmationEmailPreview}
          sendRecipientEmail={sendOrderConfirmationEmail}
          onClose={() => {
            loadOrderCommunications(order.orderId);
            setShowEmailOrderConfirmationDialog(false);
          }}
        />
      )}
      <Box className="printView" ref={deliveryAddressPrintRef}>
        <AddressPrintView {...getSelectedDeliveryAddressForPrint()} />
      </Box>
      {useAdvancedOrderForm ? (
        <OrderFormAdvanced
          order={order}
          orderLines={orderLines}
          orderCommunications={orderCommunications}
          company={company}
          receiverCompany={receiverCompany}
          contacts={contacts}
          receiverContacts={receiverContacts}
          addresses={addresses}
          receiverCompanyAddresses={receiverCompanyAddresses}
          deliveryAgreements={deliveryAgreements}
          paymentAgreements={paymentAgreements}
          modesOfTransport={modesOfTransport}
          currencies={currencies}
          companySearch={companySearch}
          handleCompanySelected={handleCompanySelected}
          handleReceiverCompanySelected={handleReceiverCompanySelected}
          handleResetReceiverCompany={handleResetReceiverCompany}
          handleResetInvoiceReference={handleResetInvoiceReference}
          canChangeCompany={!companyActorId}
          isExternalDataIdRequired={isExternalDataIdRequired}
          handleContactSelected={handleContactSelected}
          isServiceAgreement={isServiceAgreement}
          isEPortLicense={isEPortLicense}
          handleAddOrderLine={handleAddOrderLine}
          handleEditOrderLine={handleEditOrderLine}
          handleRemoveOrderLine={handleRemoveOrderLine}
          handleAddOrEditFreight={handleAddOrEditFreight}
          handleOrderFieldChanged={handleOrderFieldChanged}
          handleSimpleOrderLineFieldChanged={handleSimpleOrderLineFieldChanged}
          handleDeliveryAddressSelected={handleDeliveryAddressSelected}
          downloadOrderConfirmation={downloadOrderConfirmation}
          downloadDeliveryNotification={downloadDeliveryNotification}
          downloadDeliveryNote={downloadDeliveryNote}
          handleEmailOrderConfirmation={handleEmailOrderConfirmation}
          handlePrintDeliveryAddress={handlePrintDeliveryAddress}
          useSeparateReceiverCompany={useSeparateReceiverCompany}
          setUseSeparateReceiverCompany={setUseSeparateReceiverCompany}
          useSeparateInvoiceReference={useSeparateInvoiceReference}
          setUseSeparateInvoiceReference={setUseSeparateInvoiceReference}
          useTempReference={useTempReference}
          handleSetUseTempReference={handleSetUseTempReference}
          handleSaveOrSendOrder={handleSaveOrSendOrder}
          handleClose={onClose ?? closeAndReload}
          hasOrderChanged={hasOrderChanged}
        />
      ) : (
        <OrderFormSimple
          order={order}
          orderLines={orderLines}
          company={company}
          contacts={contacts}
          parts={parts}
          companySearch={companySearch}
          handleCompanySelected={handleCompanySelected}
          canChangeCompany={!companyActorId}
          isExternalDataIdRequired={isExternalDataIdRequired}
          handleContactSelected={handleContactSelected}
          isServiceAgreement={isServiceAgreement}
          isEPortLicense={isEPortLicense}
          handlePartSelected={handlePartSelected}
          handleQtyChanged={handleQtyChanged}
          isELearningLicense={isELearningLicense}
          getAvailableLicenseCount={getAvailableLicenseCount}
          getLearningType={getLearningType}
          handleAvailableLicenseCountChanged={
            handleAvailableLicenseCountChanged
          }
          handleOrderFieldChanged={handleOrderFieldChanged}
          handleSaveOrSendOrder={handleSaveOrSendOrder}
          handleClose={onClose ?? closeAndReload}
          isBig={isBig}
        />
      )}
      <YesOrNoDialog
        open={showNeedToSaveOrderQuestion}
        title={Translate.get("Save")}
        text={Translate.get("NeedToSaveOrder")}
        onNo={() => setShowNeedToSaveOrderQuestion(false)}
        onYes={async () => {
          const saved = await addOrUpdateOrder(false, false);
          if (saved) {
            setShowNeedToSaveOrderQuestion(false);
            setShowEmailOrderConfirmationDialog(true);
          }
        }}
        noText={Translate.get("Cancel")}
        yesText={Translate.get("Save")}
      />
      {saveCapabilities && (
        <OrderSaveQuestionDialog
          company={company}
          saveCapabilities={saveCapabilities}
          addOrUpdateOrder={addOrUpdateOrder}
          onSaved={closeAndReload}
          onSaveFailed={() => setSaveCapabilities(null)}
          onCancelSave={() => setSaveCapabilities(null)}
          getInitialOrderConfirmationRecipients={
            getInitialOrderConfirmationRecipients
          }
          getOrderConfirmationDefaultOptions={
            getOrderConfirmationDefaultOptions
          }
          getOrderConfirmationEmailPreview={getOrderConfirmationEmailPreview}
        />
      )}
      <AlertDialog
        titleText={Translate.get("SomethingFailed")}
        bodyText={Translate.get(longErrorMessage)}
        buttonText={Translate.get("Ok")}
        open={!!longErrorMessage}
        onClose={() => closeAndReload()}
      />
    </>
  );
}
