import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import Logo from '../../components/Logo';
import Help from '../../pages/Help'

// ----------------------------------------------------------------------
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 1),
  [theme.breakpoints.up('lg')]: {
   // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  justifyContent:"space-between",
  [theme.breakpoints.up('sm')]: {
    minHeight: APPBAR_DESKTOP,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    //padding: theme.spacing(0, 5),
    justifyContent:"space-between",
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const isKs = window.global.isKs;
  return (
    <RootStyle>
      <ToolbarStyle>
          <IconButton sx={{padding:0}} className={isKs ? "toolBarBurgerKs" : "toolBarBurger"} onClick={onOpenSidebar} >
            <Icon icon={menu2Fill} />
          </IconButton>
          <Logo sx={{ flexGrow: 1 }} navigateTo="dashboard/app" />
        <Stack className='toolBarStack' direction="row" alignItems="center">
          {/* <LanguagePopover isPreLogin={false} /> */}
          <NotificationsPopover />
          <Help />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
