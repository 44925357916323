// material
import { useState } from "react";
import { Grid, Container, Button } from "@mui/material";
// components
import Page from "../components/Page";
import Translate from "../utils/Translate";
import CircularCourses from "../components/_dashboard/app/CircularCourses";
import CoursesOverTime from "../components/_dashboard/app/CoursesOverTime";
import PasswordBalance from "../components/_dashboard/app/PasswordBalance";
import UsersMonth from "../components/_dashboard/app/UsersMonth";
import OverallStats from "../components/_dashboard/app/OverallStats";
import Order from "../components/_dashboard/app/Order";
import Download from "../components/_dashboard/app/Download";
import BuyLic from "../components/_dashboard/app/BuyLic";
import { useApi } from "../utils/Api";
import Preloader from "../components/Preloader";
import SchoolIcon from "@mui/icons-material/School";
import PeopleIcon from "@mui/icons-material/People";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SpeedMenu from "../components/SpeedMenu";
import AddIcon from "@mui/icons-material/Add";
import CourseDialog from "../components/courses/CourseDialog";
import DomainIcon from "@mui/icons-material/Domain";
import { accessKeys, hasSomeAccess } from "../utils/userAccess";

import NoticeBox from "../components/_dashboard/app/NoticeBox";
const getSpeedMenu = (props) => {
  //console.log(props)
  const menudata = [
    {
      icon: <AddIcon />,
      text: Translate.get("AddCourse"),
      route: false,
      dialogHeader: Translate.get("AddCourse"),
      customClass: "bigCourseDialog",
      component: <CourseDialog {...props} />,
      //disabled: props.freeRoll,
    },
    /*  {
       icon: <AddIcon />,
       text: "Testa e-kurs",
       route: false,
       dialogHeader: "Testa e-kurs",
       customClass: "smallCourseDialog",
       component: <TryOutCourse />,
       hide: !props.freeRoll,
 
     }, */
  ];
  return menudata;
};

// ----------------------------------------------------------------------
//Mock data start

const fixStats = (data) => {
  //Check if the user have no courses and no licenses, if so, show different dashboard 
  ////console.log('data.actorStatistics.totalNumberOfCourses', data.actorStatistics.totalNumberOfCourses)
  ////console.log('data.eLearningLicenseBalance.length', data.eLearningLicenseBalance)
  let freeRoll = false;
  if (data.actorStatistics.totalNumberOfCourses === 0 && data.eLearningLicenseBalance.filter((x) => x.numberOfFreeLicenses > 0).length === 0) {
    freeRoll = true;
  }
  //-----
  //data.courseDistribution=data.courseDistribution.slice(0,10);
  //data.courseParticipantDistribution=data.courseParticipantDistribution.slice(0,10);
  const loopGraph = (data) => {
    const series = [];
    const finalSeries = [];
    let dateLabel = "";

    for (const row of data) {
      dateLabel = `${row.month.toString().padStart(2, "0")}/10/${row.year}`;
      if (!series[row.description]) {
        series[row.description] = {
          name: row.description,
          color: row.webColor,
          data: [],
        };
      }
      series[row.description].data.push({
        x: dateLabel,
        y: row.participants ?? row.count,
      });
    }

    for (const [, row] of Object.entries(series)) {
      finalSeries.push({
        name: row.name,
        color: row.color,
        data: row.data,
      });
    }
    return finalSeries;
  };
  let passWordBalance = [];
  const overAllStats = {
    courseParticipantsTotal: data.actorStatistics.totalNumberOfStudents,
    coursesTotal: data.actorStatistics.totalNumberOfCourses,
    numberOfCompanies: data.actorStatistics.numberOfCompanies,
  };

  for (const row of data.eLearningLicenseBalance) {
    passWordBalance.push({
      name: Translate.getELearningType(row.eLearningType),
      value: row.numberOfFreeLicenses,
      color: row.webColor,
    });
  }

  const coursesOverTime = {};
  coursesOverTime.labels = [];
  coursesOverTime.series = [];
  const userMonth = {};
  userMonth.labels = [];
  userMonth.series = [];

  coursesOverTime.series = loopGraph(data.courseDistribution);
  userMonth.series = loopGraph(data.courseParticipantDistribution);

  return {
    passWordBalance: passWordBalance,
    coursesOverTime: coursesOverTime,
    userMonth: userMonth,
    overAllStats: overAllStats,
    freeRoll: freeRoll,
  };
};

let startIndex = 0;
let endIndex = 0;
export default function DashboardApp() {
  const [courseInfo, setCourseInfo] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [init, setInit] = useState(false);
  const [lockFw, setLockFw] = useState(true);
  const [lockBw, setLockBw] = useState(false);
  const [baseStats, setBaseStats] = useState(false);
  // nyheter visas inte just nu
  //const [newsFeed, setNewsFeed] = useState(null);
  const [elements, setElements] = useState(null);
  const [window, setWindow] = useState(null);
  const api = useApi();

  const getBaseStats = () => {
    api.fetch(`${process.env.REACT_APP_MAIN_URL}dashboard/`, false, "GET").then(
      (data) => {
        /*  setDataFetched(true) */
        if (data) {
          setBaseStats(fixStats(data));
        } else {
          setBaseStats({
            passWordBlance: [],
            coursesOverTime: [],
            userMonth: [],
            overAllStats: {
              courseParticipantsTotal: "0",
              coursesTotal: "0",
              totalNumberOfLicenses: "0",
            },
          });
        }
      }
    );
  };
  const FetchBookings = (getMostCurrentPage = false) => {
    setLockBw(false);
    setLockFw(false);
    setDataFetched(false);
    const req = {
      GetMostCurrentWindow: getMostCurrentPage,
      Window: { StartIndex: startIndex, EndIndex: endIndex },
    };
    api.fetch(
      `${process.env.REACT_APP_MAIN_URL}dashboard/current-courses/`,
      req
    ).then((data) => {
      if (data.isSuccessful) {
        setWindow(data.window)
        setElements(data.elements);
        startIndex = data.window.startIndex;
        endIndex = data.window.endIndex;
        if (startIndex - 1 < 0) {
          setLockBw(true);
        }
        if (endIndex + 1 >= data.elements) {
          setLockFw(true);
        }
        setCourseInfo(data.courses);
        setDataFetched(true);
      }
    });
  };

  //nyheter visas inte nu
  // const fetchNews = () => {
  //   api.fetch(`${process.env.REACT_APP_MAIN_URL}dashboard/news/`).then(
  //     (data) => {
  //       if (data && data.length) {
  //         setNewsFeed(data.slice(0, 5));
  //       }
  //     }
  //   );
  // };

  if (!init) {
    //Hämta all annan data, tre anrop kurser, nyheter + övrig stats
    //setBaseStats(mockStats()); //Mockdata
    getBaseStats(); //Riktiga, fungerande men API datan underlig
    //nyheter visas inte nu
    //fetchNews();
    FetchBookings(true); //Hämta bookings,  lägg till data för progress cirklar,  ev ändra endpoint
    setInit(true);
  }
  const loadMore = (direction) => {
    if (direction === "prev") {
      //Hämta tre tidigare
      startIndex -= 3;
      endIndex = startIndex + 2;
      if (startIndex < 0) {
        endIndex = startIndex + 2
        startIndex = 0;
      };
      FetchBookings();
    } else {
      //Hämta tre senare
      startIndex = window.endIndex + 1;
      endIndex = startIndex + 2;
      if (endIndex > elements) {
        endIndex = elements;
      }
      FetchBookings();
    }
  };
  if (!init || !baseStats) {
    return <Preloader invisible={true} />;
  }

  return (
    <>
      <Page>
        {!dataFetched && <Preloader invisible={true} />}
        <Container
          //maxWidth="xl"
          maxWidth={false}
          className="dashboard"
          disableGutters={true}
        >
          {/* <Box sx={{ pb: 5 }}>
          <Typography variant="h4">{Translate.get('WelcomeBack')}</Typography>
        </Box> */}
           {/* <NoticeBox  link='/dashboard/myprofilesettings/' text={Translate.get("BankIdTips")} buttonText={Translate.get("Open")}/> */}
          <Grid container spacing={3} sx={{ alignItems: "flex-start" }}>
            {!baseStats.freeRoll && (
              <>
                <Grid container item spacing={3} xs={12} lg={9}>
                  <Grid sx={{ height: "62px" }} item xs={12}>
                    <h2>
                      {Translate.get("Courses")}
                      {/* <IconButton href={`/dashboard/courses/`} aria-label="link">
                    <DoubleArrowIcon />
                  </IconButton> */}
                      <Button
                        sx={{ marginLeft: "16px" }}
                        href={`/dashboard/courses/`}
                      >
                        {Translate.get("ShowAll")}
                      </Button>
                    </h2>
                  </Grid>
                  <Grid className="hideEmpty" item xs={12} sm={6} md={6} xl={4}>
                    <CircularCourses data={courseInfo[0]} />
                  </Grid>
                  <Grid className="hideEmpty" item xs={12} sm={6} md={6} xl={4}>
                    <CircularCourses data={courseInfo[1]} />
                  </Grid>
                  <Grid className="hideEmpty" item xs={12} sm={6} md={6} xl={4}>
                    <CircularCourses data={courseInfo[2]} />
                  </Grid>
                  <Grid item xs={12} className="stdFlexRow gridNoTopSpace">
                    <Button
                      disabled={lockBw}
                      startIcon={<NavigateBeforeIcon />}
                      onClick={() => loadMore("prev")}
                    >
                      {Translate.get("EarlierCourseInstances")}
                    </Button>
                    <Button
                      disabled={lockFw}
                      endIcon={<NavigateNextIcon />}
                      onClick={() => loadMore("next")}
                    >
                      {Translate.get("LaterCourseInstances")}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <h2>{Translate.get("Statistics")}</h2>
                  </Grid>
                 {/*  <Grid item xs={12} sm={12} md={12} xl={4}>
                    <PasswordBalance data={baseStats.passWordBalance} />
                  </Grid> */}
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <CoursesOverTime data={baseStats.coursesOverTime} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={12}>
                    <UsersMonth data={baseStats.userMonth} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} xl={4}>
                    <OverallStats
                      icon={<PeopleIcon />}
                      value={baseStats.overAllStats.courseParticipantsTotal}
                      text={Translate.get("CourseParticipantsTotal")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} xl={4}>
                    <OverallStats
                      icon={<SchoolIcon />}
                      value={baseStats.overAllStats.coursesTotal}
                      text={Translate.get("CoursesTotal")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} xl={4}>
                    <OverallStats
                      icon={<DomainIcon />}
                      value={baseStats.overAllStats.numberOfCompanies}
                      text={Translate.get("CompaniesTotal")}
                    //text={Translate.get('TotalNumberOfLicenses')}
                    />
                  </Grid>
                </Grid>
              </>)}
            {baseStats.freeRoll && (
              <>
                <Grid container item spacing={3} xs={12} lg={9} >
                  <Grid sx={{ height: "62px" }} item xs={12}>
                    <h2>{Translate.get("OngoingCourse")}</h2>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="dashedBoxCenter">

                      <img src="/static/icons/no-data-e-course.svg" />
                      <h2>{Translate.get("NoCoursesAvailiable")}</h2>
                      {Translate.get("FreeTryStateText")}
                      {/*  Skapa din första e-kurs genom att trycka på plustecknet nere till höger.<br />
                      Vi bjuder på det första lösenordet! */}
                    </div>
                  </Grid>
                  <Grid sx={{ height: "62px" }} item xs={12}>
                    <h2>{Translate.get("Statistics")}</h2>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="dashedBoxCenter">

                      <img src="/static/icons/no-statistics.svg" />
                      <h2>{Translate.get("NoStatsNow")}</h2>
                      {Translate.get("EmptyStats")} 
                    </div>
                  </Grid>

                </Grid>
              </>)}
            <Grid container item spacing={3} xs={12} lg={3} direction="column">
              <Grid sx={{ height: "62px" }} item>
                <h2>{Translate.get("InstructorServices")}</h2>
              </Grid>
              {!hasSomeAccess(accessKeys.standardPackage, accessKeys.advancedPackage) && (
                <Grid item>
                  <Order />
                </Grid>
              )}

              <Grid item>
                <Download />
              </Grid>

              <Grid item>
                <BuyLic />
              </Grid>
           {/*    {!baseStats.freeRoll && (
                <>
                  <Grid item>
                    <h2>{Translate.get("NewsFeed")}</h2>
                  </Grid>
                  <Grid item>
                    <News data={newsFeed} />
                  </Grid>
                </>
              )} */}
                <>
                  <Grid item>
                    <h2>Resurser</h2>
                  </Grid>
                  <Grid item>
                    <PasswordBalance data={baseStats.passWordBalance} />
                  </Grid>
                </>
            </Grid>
          </Grid>
        </Container>
      </Page>
      <SpeedMenu
        content={getSpeedMenu({
          id: 0,
          freeRoll: baseStats.freeRoll,

        })}
      />
    </>
  );
}
